import { useMutation } from "@apollo/client";
import React, { FC } from "react";
import { useHistory } from "react-router";
import { UPDATE_CATEGORY } from "../client/categories/mutation";
import { GET_CATEGORY, GET_CATEGORIES } from "../client/categories/queries";
import { CategoriesEditComponent } from "../components/categories-edit/categories-edit.component";
import { ICategories } from "../models/categories.model";

export const CategoriesEditContent: FC<{ category: ICategories }> = ({
  category,
}): JSX.Element => {
  const history = useHistory();
  const [updateCategory] = useMutation(UPDATE_CATEGORY);

  const handleUpdate = async (categoryEdited: ICategories) => {
    updateCategory({
      variables: { ...categoryEdited },
      update: async (cache, { data }) => {
        if (data && data.updateCategory) {
          const existing: any = cache.readQuery({ query: GET_CATEGORIES });
          if (existing && existing.getCategories) {
            const arrayCategories: ICategories[] =
              existing.getCategories.categories;
            if (arrayCategories) {
              await cache.writeQuery({
                query: GET_CATEGORY,
                variables: {
                  id: categoryEdited.id,
                },
                data: {
                  getCategory: {
                    status: true,
                    error: null,
                    category: categoryEdited,
                  },
                },
              });
              await cache.writeQuery({
                query: GET_CATEGORIES,
                data: {
                  getCategories: {
                    status: true,
                    error: null,
                    categories: arrayCategories.map((category: ICategories) =>
                      category.id === categoryEdited.id
                        ? (category = categoryEdited)
                        : category
                    ),
                  },
                },
              });
              history.push("/categories");
            }
          }
        }
      },
    });
  };

  return (
    <CategoriesEditComponent handleUpdate={handleUpdate} category={category} />
  );
};
