import React, { FC, useState, useEffect, useContext } from "react";

import {
  Grid,
  Paper,
  InputLabel,
  FormControlLabel,
  FormControl,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  TextField,
  FormGroup,
  Checkbox,
  Radio,
  RadioGroup,
  FormLabel,
  OutlinedInput,
  InputAdornment,
  Dialog,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Select,
  MenuItem,
  Typography,
} from "@material-ui/core";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import RemoveIcon from "@material-ui/icons/Remove";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import SaveIcon from "@material-ui/icons/Save";
import PhoneIcon from "@material-ui/icons/Phone";
import HomeIcon from "@material-ui/icons/Home";
import DescriptionIcon from "@material-ui/icons/Description";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import InfoIcon from "@material-ui/icons/Info";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { format, getUnixTime, startOfDay, endOfDay } from "date-fns";
import { useQuery, useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";

import { useStyles } from "./sale-create.styles";
import { Category } from "../../models/category.model";
import { Product } from "../../models/product.model";
import { Client } from "../../models/client.model";
import { Order } from "../../models/order.model";
import { PaymentMethod } from "../../models/payment-method.model";
import { GET_PRODUCTS } from "../../client/products/queries";
import { GET_CATEGORIES } from "../../client/categories/queries";
import { CREATE_SALE } from "../../client/sales/mutation";
import { GET_CLIENTS } from "../../client/clients/queries";
import { GET_SALES } from "../../client/sales/queries";
import { Sale } from "../../models/sale.model";
import { Promo } from "../../models/promo.model";
import { clientSchema } from "../../forms/client.form";
import { CREATE_CLIENT, UPDATE_CLIENT } from "../../client/clients/mutation";
import { GET_LOCATIONS } from "../../client/locations/queries";
import { ILocation } from "../../models/location";
import { SessionContext } from "../../context/session.context";
import { can } from "../../utils/authorized.util";
import { ENV } from "../../environments/index";

export const SaleCreateComponent: FC = (): JSX.Element => {
  const today: Date = new Date();

  const history = useHistory();

  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const session = useContext(SessionContext);

  const clientInitialState: Client = {
    id: "",
    name: "",
    rfc: "",
    address: "",
    city: "",
    phone: "",
    commission: 0,
    commissionType: "money",
  };

  const [createSale] = useMutation(CREATE_SALE);
  const [createClient] = useMutation(CREATE_CLIENT);
  const [updateClient] = useMutation(UPDATE_CLIENT);

  const [delivery, setDelivery] = useState<boolean>(true);
  const [invoice, setInvoice] = useState<boolean>(false);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>("cash");
  const [paymentCash, setPaymentCash] = useState<string>("0");
  const [currentCategory, setCurrentCategory] = useState<Category>();
  const [currentProduct, setCurrentProduct] = useState<Product>();
  const [mixtures, setMixtures] = useState<Product[][]>([]);
  const [taxes, setTaxes] = useState<number>(0);
  const [modalClient, setModalClient] = useState<boolean>(false);
  const [modalEditClient, setModalEditClient] = useState<boolean>(false);
  const [table, setTable] = useState<string>("");
  const [client, setClient] = useState<Client>(clientInitialState);
  const [location, setLocation] = useState<ILocation>();
  const [isExternal, setIsExternal] = useState<boolean>(false);
  const [deliveryCost, setDeliveryCost] = useState<number>(ENV.DELIVERY_COST);
  const [orderInfo, setOrderInfo] = useState<{ orders: Order[] }>({
    orders: [],
  });
  const [deliveryTime, setDeliveryTime] = useState<string>("");

  const productsItems = useQuery(GET_PRODUCTS, {
    variables: {
      filters:
        (currentCategory && `{'category': '${currentCategory.id}'}`) || "",
    },
  });

  const categoriesItems = useQuery(GET_CATEGORIES);

  const allProductsItems = useQuery(GET_PRODUCTS, {
    variables: {
      filters: "",
    },
  });

  const clientsItems = useQuery(GET_CLIENTS, {
    variables: {
      filters: "",
    },
  });

  const locationsItems = useQuery(GET_LOCATIONS, {
    variables: {
      filters: "",
    },
  });

  useEffect(() => {
    if (session.location) {
      setLocation(session.location);
    }
  }, [session]);

  const handleAddProduct = (product: Product): void => {
    const newItem: Order = {
      product,
      quantity: 1,
      ingredients:
        product.mixture || product.promoItems
          ? mixtures.map((mixtures: Product[]) =>
              mixtures.map((mixture: Product) => mixture.name)
            )
          : [[]],
    };
    setOrderInfo({
      orders: [...orderInfo.orders, newItem],
    });
    setCurrentCategory(undefined);
    setCurrentProduct(undefined);
  };

  const handleQuantity = (
    index: number,
    type: "+" | "-",
    price: number
  ): void => {
    setOrderInfo({
      orders: orderInfo.orders.map((item: Order, idx: number) =>
        idx === index
          ? {
              ...item,
              quantity: type === "+" ? item.quantity + 1 : item.quantity - 1,
            }
          : item
      ),
    });
  };

  const handleChangeCategory = (category: Category | null): void => {
    setCurrentCategory(category || currentCategory);
    setMixtures([[]]);
    setCurrentProduct(undefined);
  };

  const handleChangeProduct = (product: Product | null): void => {
    setCurrentProduct(product || currentProduct);
    setMixtures([[]]);
  };

  const handleDeleteProduct = (
    product: Product,
    quantity: number,
    index: number
  ): void => {
    const filtered: Order[] = orderInfo.orders.filter(
      (item: Order, idx: number) => idx !== index
    );
    setOrderInfo({
      orders: filtered,
    });
  };

  const handleDelivery = (): void => {
    if (delivery) {
      setDeliveryCost(0);
    } else {
      setDeliveryCost(ENV.DELIVERY_COST);
    }
    setDelivery(!delivery);
    setClient(clientInitialState);
  };

  const getTotalOfProducts = (): number => {
    return (
      (orderInfo.orders.length > 0 &&
        orderInfo.orders
          .map((order) => order.product.price * order.quantity)
          .reduce((total, quantity) => total + quantity)) ||
      0
    );
  };

  const handleInvoice = (): void => {
    const totalProducts: number = getTotalOfProducts();
    const taxesPrice = totalProducts * 0.16;
    setTaxes(invoice ? 0 : taxesPrice);
    setInvoice(!invoice);
  };

  const handleMixture = (products: Product[]): void => {
    setMixtures([products]);
  };

  const clientDiscount = (client: Client, currentTotal): number => {
    const fee: number =
      client.commissionType === "money"
        ? client.commission
        : currentTotal * (client.commission / 100);
    return fee;
  };

  const handleChangeLocation = (location: ILocation): void => {
    setLocation(location as ILocation);
    if (session.location && location.id !== session.location.id) {
      setIsExternal(true);
    } else {
      setIsExternal(false);
    }
  };

  const handleChangeClient = (event: any): void => {
    event.preventDefault();
    const { name, phone, rfc, address, city, commission, commissionType } =
      event.target.elements;
    const client: Client = {
      name: name.value,
      phone: phone.value,
      rfc: rfc.value,
      address: address.value,
      city: city.value,
      commission: +commission.value,
      commissionType: !commissionType.value ? "" : commissionType.value,
    };
    clientSchema
      .validate(client)
      .then((values) => {
        createClient({
          variables: client,
          optimisticResponse: true,
          update: (cache, { data }) => {
            const existing: any = cache.readQuery({
              query: GET_CLIENTS,
              variables: {
                filters: "",
              },
            });
            if (data.createClient) {
              if (existing && existing.getClients) {
                const createItem: Client = data.createClient.client;
                cache.writeQuery({
                  query: GET_CLIENTS,
                  variables: {
                    filters: "",
                  },
                  data: {
                    getClients: {
                      status: true,
                      clients: [createItem, ...existing.getClients.clients],
                      error: null,
                    },
                  },
                });
                setClient(createItem);
              }
            }
          },
        });
        enqueueSnackbar(`${client.name} añadido`, {
          variant: "success",
          resumeHideDuration: 3000,
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
        });
        setModalClient(false);
      })
      .catch((err) => {
        enqueueSnackbar("Algunos campos contienen errores", {
          variant: "error",
          resumeHideDuration: 4000,
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
        });
      });
  };

  const handleChangeClientEdit = (event: any): void => {
    event.preventDefault();
    const { name, phone, rfc, address, city, commission, commissionType } =
      event.target.elements;
    const clientEdit: Client = {
      id: client.id,
      name: name.value,
      phone: phone.value,
      rfc: rfc.value,
      address: address.value,
      city: city.value,
      commission: +commission.value,
      commissionType: !commissionType.value ? "" : commissionType.value,
    };
    clientSchema
      .validate(clientEdit)
      .then((values) => {
        updateClient({
          variables: clientEdit,
          optimisticResponse: true,
          update: (cache, { data }) => {
            const existing: any = cache.readQuery({
              query: GET_CLIENTS,
              variables: {
                filters: "",
              },
            });
            if (data.updateClient) {
              if (existing && existing.getClients) {
                const updateItem: Client = data.updateClient.client;
                cache.writeQuery({
                  query: GET_CLIENTS,
                  variables: {
                    filters: "",
                  },
                  data: {
                    getClients: {
                      status: true,
                      clients: existing.getClients.clients.map(
                        (client: Client) =>
                          client.id === updateItem.id ? updateItem : client
                      ),
                      error: null,
                    },
                  },
                });
                setClient(updateItem);
              }
            }
          },
        });
        enqueueSnackbar(`${client.name} editado`, {
          variant: "success",
          resumeHideDuration: 3000,
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
        });
        setModalEditClient(false);
      })
      .catch(() => {
        enqueueSnackbar("Algunos campos contienen errores", {
          variant: "error",
          resumeHideDuration: 4000,
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
        });
      });
  };

  const handleMixturesOnPromo = (products: Product[], index: number) => {
    if (index >= mixtures.length) {
      setMixtures([...mixtures, products]);
    } else {
      setMixtures(
        mixtures.map((pdrs: Product[], idx: number) =>
          index === idx ? products : pdrs
        )
      );
    }
  };

  const goToTicket = (saleId: string, cash: number, change: number): void => {
    window.open(
      `${window.location.origin}/ticket?sale=${saleId}&cash=${cash}&change=${change}`,
      "_blank"
    );
  };

  const handleSelectclient = (selectedClient: Client) => {
    setOrderInfo({
      ...orderInfo,
    });
    setClient(selectedClient);
  };

  const calculateClientDiscount = (currentTotal: number): number => {
    const discount: number = clientDiscount(client, currentTotal);
    const totalWithCommision: number = currentTotal - discount;
    return totalWithCommision > 0 ? totalWithCommision : 0;
  };

  const getSaleTotal = (): number => {
    const totalWithoutDiscount = getTotalOfProducts() + taxes + deliveryCost;
    return client.commission > 0
      ? calculateClientDiscount(totalWithoutDiscount)
      : totalWithoutDiscount;
  };

  const save = (status: "on_progress" | "completed"): void => {
    createSale({
      variables: {
        type: (delivery && "delivery") || "local",
        paymentMethod: paymentMethod,
        orders: orderInfo.orders.map(
          ({ product, quantity, ingredients }: Order) => ({
            product: product.id,
            quantity,
            ingredients,
          })
        ),
        total: getSaleTotal(),
        date: getUnixTime(today),
        location: location?.id,
        taxes: +taxes,
        client: client.id,
        status:
          delivery && session.location?.id !== location?.id
            ? "on_progress"
            : status,
        table: table,
        isExternal,
        externalOrigen:
          delivery && session.location?.id !== location?.id
            ? session.location?.id
            : "",
        deliveryTime,
      },
      optimisticResponse: true,
      update: (cache, { data }) => {
        const existing: any = cache.readQuery({
          query: GET_SALES,
          variables: {
            filters: JSON.stringify({
              location: location?.id,
              from: getUnixTime(startOfDay(today)),
              to: getUnixTime(endOfDay(today)),
            }),
          },
        });
        if (data.createSale) {
          if (existing && existing.getSales) {
            const newItem: Sale = data.createSale.sale;
            cache.writeQuery({
              query: GET_SALES,
              variables: {
                filters: JSON.stringify({
                  location: location?.id,
                  from: getUnixTime(startOfDay(today)),
                  to: getUnixTime(endOfDay(today)),
                }),
              },
              data: {
                getSales: {
                  status: true,
                  error: null,
                  sales: [newItem, ...existing.getSales.sales],
                },
              },
            });
            if (newItem.status === "completed") {
              goToTicket(
                newItem.id as string,
                (paymentCash && +paymentCash) || 0,
                (paymentCash &&
                  +paymentCash > getSaleTotal() &&
                  +paymentCash - getSaleTotal()) ||
                  0
              );
            }
          }
        }
      },
    });
    history.push("/sales");
  };

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12} lg={8}>
        <Paper elevation={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                className={classes.textCenter}
                component="h3"
                variant="h3"
              >
                Nueva venta
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <p className={classes.dates}>
                <CalendarTodayIcon />
                <Typography className={classes.dateValue} variant="body1">
                  {format(today, "dd/MM/yyyy H:mm")}
                </Typography>
              </p>
            </Grid>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Grid item xs={11} md={3}>
                <Autocomplete
                  id="categories"
                  loading={categoriesItems.loading}
                  loadingText="Cargando..."
                  blurOnSelect={true}
                  options={
                    (categoriesItems.data &&
                      categoriesItems.data.getCategories.categories
                        .slice()
                        .sort((a: Category, b: Category) =>
                          b.name > a.name ? -1 : 1
                        )) ||
                    []
                  }
                  getOptionLabel={(option: Category) => option.name}
                  onChange={(event, value) => handleChangeCategory(value)}
                  disableClearable={true}
                  fullWidth
                  renderInput={(params: any) => {
                    const value = currentCategory;
                    params.inputProps.value =
                      (currentCategory && currentCategory.name) || "";
                    return (
                      <TextField
                        {...params}
                        value={value}
                        label="Categoria"
                        variant="outlined"
                      />
                    );
                  }}
                />
              </Grid>
              {!productsItems.loading && currentCategory && (
                <Grid item xs={11} md={5}>
                  <Autocomplete
                    id="products"
                    options={
                      productsItems.data.getProducts.products
                        .slice()
                        .sort((a: Product, b: Product) =>
                          a.name > b.name ? 1 : -1
                        ) || []
                    }
                    getOptionLabel={(option: Product) => option.name}
                    onChange={(event, value) => handleChangeProduct(value)}
                    disableClearable={true}
                    fullWidth
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        label="Productos"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              )}
              {currentProduct?.mixture && (
                <Grid item xs={11} md={5}>
                  <Autocomplete
                    multiple
                    disableClearable={true}
                    options={
                      productsItems.data.getProducts.products
                        .filter((product: Product) => !product.mixture)
                        .slice()
                        .sort((a: Product, b: Product) =>
                          a.name > b.name ? 1 : -1
                        ) || []
                    }
                    getOptionLabel={(option: Product) => option.name}
                    onChange={(event, value) => handleMixture(value)}
                    getOptionDisabled={() =>
                      mixtures[0] && mixtures[0].length === 2
                    }
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Ingredientes"
                        placeholder="Selecciona los ingredientes"
                      />
                    )}
                  />
                </Grid>
              )}
              {currentProduct?.promoItems && (
                <Grid item xs={11}>
                  <Grid container spacing={2}>
                    {currentProduct.promoItems.map(
                      (promo: Promo, index: number) => (
                        <Grid item key={index} xs={12}>
                          {promo.mixture ? (
                            <Autocomplete
                              disableClearable={true}
                              multiple
                              options={
                                (allProductsItems.data &&
                                  allProductsItems.data.getProducts.products.filter(
                                    (product: Product) =>
                                      product.category.id === promo.category.id
                                  )) ||
                                []
                              }
                              getOptionLabel={(option: Product) => option.name}
                              onChange={(event, value) =>
                                handleMixturesOnPromo(value, index)
                              }
                              filterSelectedOptions
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label={promo.category.name}
                                  placeholder="Selecciona los ingredientes"
                                />
                              )}
                            />
                          ) : (
                            <Autocomplete
                              options={
                                (allProductsItems.data &&
                                  allProductsItems.data.getProducts.products.filter(
                                    (product: Product) =>
                                      product.category.id === promo.category.id
                                  )) ||
                                []
                              }
                              getOptionLabel={(option: Product) => option.name}
                              onChange={(event, value) =>
                                handleMixturesOnPromo([value as Product], index)
                              }
                              fullWidth
                              disableClearable={true}
                              renderInput={(params: any) => (
                                <TextField
                                  {...params}
                                  label={promo.category.name}
                                  variant="outlined"
                                />
                              )}
                            />
                          )}
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              )}
              {currentCategory && (
                <Grid item xs={11} md={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<AddIcon />}
                    disabled={!currentProduct}
                    onClick={() => handleAddProduct(currentProduct as Product)}
                  >
                    Agregar
                  </Button>
                </Grid>
              )}
            </Grid>
            {orderInfo.orders.length > 0 && (
              <Grid item xs={12}>
                <Table aria-label="order">
                  <TableHead>
                    <TableRow>
                      <TableCell>Producto</TableCell>
                      <TableCell align="right">Cantidad</TableCell>
                      <TableCell align="right">Precio</TableCell>
                      <TableCell align="right">Acción</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderInfo.orders.map((row: Order, index: number) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {`${row.product.category.name} - ${row.product.name}`}
                          <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                          >
                            {(row.ingredients.flat().length > 0 &&
                              row.ingredients
                                .map(
                                  (mixtures: string[], idx: number) =>
                                    `${idx + 1}. ${mixtures.join(", ")}`
                                )
                                .join(" - ")) ||
                              ""}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            aria-label="minus"
                            color="primary"
                            disabled={row.quantity === 1}
                            onClick={() =>
                              handleQuantity(index, "-", row.product.price)
                            }
                          >
                            <RemoveIcon />
                          </IconButton>
                          <span>{row.quantity}</span>
                          <IconButton
                            aria-label="add"
                            color="primary"
                            onClick={() =>
                              handleQuantity(index, "+", row.product.price)
                            }
                          >
                            <AddIcon />
                          </IconButton>
                        </TableCell>
                        <TableCell align="right">
                          ${row.product.price}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            aria-label="delete"
                            size="small"
                            color="secondary"
                            onClick={() =>
                              handleDeleteProduct(
                                row.product,
                                row.quantity,
                                index
                              )
                            }
                          >
                            <DeleteIcon fontSize="inherit" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell colSpan={4} align="right">
                        Importe: ${getTotalOfProducts()}
                      </TableCell>
                    </TableRow>
                    {delivery && (
                      <TableRow>
                        <TableCell colSpan={4} align="right">
                          Cargo por envío domicilio: ${25}
                        </TableCell>
                      </TableRow>
                    )}
                    {taxes > 0 && (
                      <TableRow>
                        <TableCell colSpan={4} align="right">
                          IVA: ${taxes}
                        </TableCell>
                      </TableRow>
                    )}
                    {client.commission > 0 && (
                      <TableRow>
                        <TableCell colSpan={4} align="right">
                          Descuento: $
                          {clientDiscount(
                            client,
                            getTotalOfProducts() + taxes + deliveryCost
                          )}
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell colSpan={4} align="right">
                        Total: ${getSaleTotal()}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} lg={4}>
        <Paper>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <Typography
                className={classes.textCenter}
                component="h5"
                variant="h5"
              >
                Datos restaurante
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Autocomplete
                id="location"
                options={
                  (locationsItems.data &&
                    locationsItems.data.getLocations.locations) ||
                  []
                }
                getOptionLabel={(option: ILocation) => option.name}
                onChange={(event, value) => handleChangeLocation(value)}
                defaultValue={session.location}
                fullWidth
                disableClearable={true}
                disabled={!delivery || !can("CLIENTS::LIST", "CLIENTS::*")}
                renderInput={(params) => (
                  <TextField {...params} label="Sucursal" variant="outlined" />
                )}
              />
            </Grid>
            {!delivery && (
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  id="table"
                  label="Mesa"
                  variant="outlined"
                  onChange={(event) => setTable(event.currentTarget.value)}
                />
              </Grid>
            )}
            <Grid item xs={11}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Método de pago</FormLabel>
                <RadioGroup
                  row
                  aria-label="payment"
                  name="payment"
                  value={paymentMethod}
                  onChange={(event, value) =>
                    setPaymentMethod(value as PaymentMethod)
                  }
                >
                  <FormControlLabel
                    value="cash"
                    control={<Radio />}
                    label="Efectivo"
                  />
                  <FormControlLabel
                    value="card"
                    control={<Radio />}
                    label="Tarjeta"
                  />
                  <FormControlLabel
                    value="credit"
                    control={<Radio />}
                    label="Crédito"
                  />
                  <FormControlLabel
                    value="free"
                    control={<Radio />}
                    label="Cortesía"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={delivery}
                    onChange={() => handleDelivery()}
                    name="delivery"
                  />
                }
                label="Envío domicilio"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={invoice}
                    onChange={handleInvoice}
                    name="invoice"
                  />
                }
                label="Factura"
              />
            </FormGroup>
            {delivery && (
              <Grid item xs={12}>
                <Typography
                  className={classes.textCenter}
                  variant="body1"
                  gutterBottom
                >
                  Datos del Cliente
                </Typography>
              </Grid>
            )}
            <Grid item xs={11}>
              {!clientsItems.loading && delivery && (
                <Autocomplete
                  id="client"
                  options={
                    (clientsItems.data &&
                      clientsItems.data.getClients.clients) ||
                    []
                  }
                  getOptionLabel={(option: Client) =>
                    `${option.name} (${option.phone})`
                  }
                  onChange={(event, value) =>
                    handleSelectclient(value as Client)
                  }
                  value={client as Client}
                  fullWidth
                  disableClearable={true}
                  renderInput={(params) => (
                    <TextField {...params} label="Cliente" variant="outlined" />
                  )}
                />
              )}
            </Grid>
            {client.name !== "" && delivery && (
              <Grid item xs={12}>
                <List dense={true}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <InfoIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        client.commission > 0
                          ? `Mayorista (${
                              client.commissionType === "money" ? "$" : "%"
                            }${client.commission})`
                          : "Minorista"
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <PhoneIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={client.phone} />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <DescriptionIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={client.rfc || "RFC no disponible"} />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <HomeIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={client.address} />
                  </ListItem>
                  {client.alternativeAddress && (
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <HomeIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={client.alternativeAddress} />
                    </ListItem>
                  )}
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <LocationCityIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={client.city} />
                  </ListItem>
                </List>
              </Grid>
            )}
            {delivery && (
              <Grid item xs={12}>
                <Grid container justifyContent="center" spacing={2}>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      startIcon={<AddIcon />}
                      onClick={() => setModalClient(true)}
                      disabled={!can("CLIENTS::CREATE", "CLIENTS::*")}
                    >
                      Agregar cliente
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      size="small"
                      startIcon={<EditIcon />}
                      onClick={() => setModalEditClient(true)}
                      disabled={
                        client.name === "" ||
                        !can("CLIENTS::UPDATE", "CLIENTS::*")
                      }
                    >
                      Editar cliente
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item xs={11}>
              <Typography variant="subtitle1">
                Total: ${getSaleTotal()}
              </Typography>
            </Grid>
            {paymentMethod === "cash" && orderInfo.orders.length > 0 && (
              <Grid item xs={11}>
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item xs={8}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-amount">
                        Paga con
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-amount"
                        value={paymentCash}
                        onChange={(event) => setPaymentCash(event.target.value)}
                        startAdornment={
                          <InputAdornment position="start">$</InputAdornment>
                        }
                        labelWidth={70}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className={classes.textCenter} variant="body1">
                      Cambio: $
                      {(paymentCash &&
                        +paymentCash > getSaleTotal() &&
                        +paymentCash - getSaleTotal()) ||
                        0}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {delivery && (
              <Grid item xs={11}>
                <TextField
                  variant="outlined"
                  label="Tiempo estimado de entrega"
                  id="deliveryTime"
                  name="deliveryTime"
                  onChange={(event) =>
                    setDeliveryTime(event.currentTarget.value)
                  }
                  fullWidth
                />
              </Grid>
            )}
            <Grid item xs={11}>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => history.push("/sales")}
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<SaveIcon />}
                    disabled={
                      orderInfo.orders.length === 0 ||
                      table === "" ||
                      delivery ||
                      !can(
                        "SALES::CREATE",
                        `LOCATIONS::${session.location?.id}`
                      )
                    }
                    onClick={() => save("on_progress")}
                  >
                    Guardar Orden
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<CheckCircleIcon />}
                    disabled={
                      orderInfo.orders.length === 0 ||
                      (paymentMethod === "cash" &&
                        +paymentCash < getSaleTotal()) ||
                      !can(
                        "SALES::CREATE",
                        `LOCATIONS::${session.location?.id}`
                      )
                    }
                    onClick={() => save("completed")}
                  >
                    Terminar Orden
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Dialog
        onClose={() => setModalClient(false)}
        aria-labelledby="client"
        open={modalClient}
      >
        <form onSubmit={handleChangeClient}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Typography variant="h4">Añadir Cliente</Typography>
            <Grid item xs={10}>
              <TextField
                fullWidth
                name="name"
                label="Nombre"
                variant="outlined"
                required
                helperText="Obligatorio"
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                type="tel"
                name="phone"
                label="Teléfono"
                variant="outlined"
                required
                helperText="Obligatorio, al menos 7 dígitos"
                inputProps={{ maxLength: 10 }}
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                name="rfc"
                label="RFC"
                variant="outlined"
                inputProps={{ maxLength: 13 }}
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                name="address"
                label="Dirección"
                variant="outlined"
                required
                helperText="Obligatorio"
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                name="alternativeAddress"
                label="Dirección alternativa"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                name="city"
                label="Ciudad"
                variant="outlined"
                required
                helperText="Obligatorio"
              />
            </Grid>
            <Grid item xs={10}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={8}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">
                      Tipo de comisión
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      name="commissionType"
                      label="Tipo de comisión"
                      defaultValue=""
                    >
                      <MenuItem value="" disabled>
                        Omitir si no tiene comisión
                      </MenuItem>
                      <MenuItem value="percentage">Porcentaje (%)</MenuItem>
                      <MenuItem value="money">Dinero ($)</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    name="commission"
                    label="Comisión"
                    variant="outlined"
                    defaultValue={0}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={10}>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                  <Button
                    variant="contained"
                    size="small"
                    type="button"
                    onClick={() => setModalClient(false)}
                  >
                    Regresar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<CheckCircleIcon />}
                    type="submit"
                  >
                    Añadir cliente
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Dialog>
      <Dialog
        onClose={() => setModalEditClient(false)}
        aria-labelledby="client"
        open={modalEditClient}
      >
        <form onSubmit={handleChangeClientEdit}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Typography variant="h4">Editar Cliente {client.name}</Typography>
            <Grid item xs={10}>
              <TextField
                fullWidth
                name="name"
                label="Nombre"
                variant="outlined"
                defaultValue={client.name}
                required
                helperText="Obligatorio"
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                type="tel"
                name="phone"
                label="Teléfono"
                variant="outlined"
                required
                defaultValue={client.phone}
                helperText="Obligatorio, al menos 7 dígitos"
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                name="rfc"
                label="RFC"
                variant="outlined"
                defaultValue={client.rfc}
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                name="address"
                label="Dirección"
                variant="outlined"
                required
                defaultValue={client.address}
                helperText="Obligatorio"
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                name="alternativeAddress"
                label="Dirección alternativa"
                variant="outlined"
                required
                defaultValue={client.alternativeAddress}
                helperText="Obligatorio"
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                name="city"
                label="Ciudad"
                variant="outlined"
                required
                defaultValue={client.city}
                helperText="Obligatorio"
              />
            </Grid>
            <Grid item xs={10}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={8}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">
                      Tipo de comisión
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      name="commissionType"
                      label="Tipo de comisión"
                      defaultValue={client.commissionType}
                    >
                      <MenuItem value="" disabled>
                        Omitir si no tiene comisión
                      </MenuItem>
                      <MenuItem value="percentage">Porcentaje (%)</MenuItem>
                      <MenuItem value="money">Dinero ($)</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    name="commission"
                    label="Comisión"
                    variant="outlined"
                    defaultValue={client.commission}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={10}>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                  <Button
                    variant="contained"
                    size="small"
                    type="button"
                    onClick={() => setModalEditClient(false)}
                  >
                    Regresar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<CheckCircleIcon />}
                    type="submit"
                  >
                    Editar cliente
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Dialog>
    </Grid>
  );
};
