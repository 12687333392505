import React, { FC, useState } from "react";

import { useHistory } from "react-router-dom";
import { DataGrid, GridColDef } from "@material-ui/data-grid";
import { Grid, Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import AddCircleIcon from "@material-ui/icons/AddCircle";

import EditIcon from "@material-ui/icons/Edit";
import { ILocations } from "../../models/components/locations.model";
import { Dialog, Typography } from "@material-ui/core";
import { ILocation } from "../../models/location";
import { can } from "../../utils/authorized.util";

export const LocationsComponent: FC<ILocations> = ({
  locations,
  handleDelete,
}): JSX.Element => {
  const history = useHistory();
  const [modal, setModal] = useState<boolean>(false);
  const [locationSelected, SetLocationSelected] = useState<ILocation>({
    id: "",
    name: "",
    address: "",
    enable: false,
    phone: "",
  });
  const handleCloseModal = (): void => {
    setModal(false);
  };
  const handleOpenModal = (id: string) => {
    SetLocationSelected(
      locations.find((element) => element.id === id) as ILocation
    );
    setModal(true);
  };
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 90, hide: true },
    {
      field: "name",
      headerName: " Nombre de la sucursal",
      width: 250,
      editable: false,
    },
    {
      field: "address",
      headerName: "Direccion",
      width: 150,
      editable: false,
    },
    {
      field: "enable",
      headerName: "Activa",
      type: "boolean",
      width: 150,

      editable: false,
    },
    {
      field: "phone",
      headerName: "Número telefónico",
      sortable: false,
      width: 160,

      editable: false,
    },
    {
      field: "actions",
      headerName: "Acciones",
      width: 200,

      editable: false,
      renderCell: (params) => {
        return (
          <div className="d-flex justify-content-between align-items-center">
            <IconButton
              onClick={() => history.push(`/locations/${params.row.id}`)}
              aria-label="ver"
              disabled={
                !can("LOCATIONS::DETAILS", `LOCATIONS::${params.row.id}`)
              }
            >
              <VisibilityIcon />
            </IconButton>
            <IconButton
              onClick={() => history.push(`/locations/${params.row.id}/edit`)}
              aria-label="editar"
              disabled={
                !can("LOCATIONS::UPDATE", `LOCATIONS::${params.row.id}`)
              }
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => handleOpenModal(params.row.id)}
              aria-label="delete"
              color="secondary"
              disabled={!can("LOCATIONS::DELETE", "LOCATIONS::*")}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        );
      },
    },
  ];

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={8}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<AddCircleIcon />}
              onClick={() => history.push("/locations/new")}
              disabled={!can("LOCATIONS::CREATE", "LOCATIONS::*")}
            >
              Agregar sucursal
            </Button>
          </Grid>
          <Dialog
            open={modal}
            onClose={handleCloseModal}
            aria-labelledby="Eliminar sucursal"
            aria-describedby="Eliminar sucursal"
          >
            <Grid container spacing={2} style={{ padding: "2rem" }}>
              <Grid item>
                <Typography variant="h3">Eliminar Sucursal</Typography>
                <h3>Deseas borrar esta sucursal {locationSelected.name} ?</h3>
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="flex-end" spacing={2}>
                  <Grid item>
                    <Button variant="contained" onClick={handleCloseModal}>
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        handleDelete(locationSelected.id);
                        handleCloseModal();
                      }}
                    >
                      Eliminar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Dialog>
          <Grid item>
            <div style={{ height: (locations.length + 1) * 70 }}>
              <DataGrid
                rows={locations}
                columns={columns}
                pageSize={10}
                checkboxSelection={false}
                disableSelectionOnClick
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
