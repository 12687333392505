import React, { FC } from "react";

import { CategoriesComponent } from "../components/categories/categories.component";
import { ICategoriesContent } from "../models/categories-content.model";

export const CategoriesContent: FC<ICategoriesContent> = ({
  categories,
}): JSX.Element => {
  return <CategoriesComponent categories={categories} />;
};
