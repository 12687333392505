import { gql } from "@apollo/client";

export const GET_EXPENSES = gql`
  query getExpenses($filters: String!) {
    getExpenses(filters: $filters) {
      status
      expenses {
        location {
          id
          name
          phone
          address
        }
        date
        product
        price
        quantity
        description
        canceled
        cancelReason
      }
      error {
        code
        message
      }
    }
  }
`;

export const GET_EXPENSE = gql`
  query getExpense($location: String!, $date: Int!) {
    getExpense(location: $location, date: $date) {
      status
      expense {
        location {
          id
          name
          address
          phone
        }
        date
        product
        price
        quantity
        description
        canceled
        cancelReason
      }
      error {
        code
        message
      }
    }
  }
`;
