import { createTheme, Theme } from "@material-ui/core/styles";
import { yellow } from "@material-ui/core/colors";
import { baseSizes, largeSizes, extraLargeSizes } from "./font-sizes";

export const theme: Theme = createTheme({
  palette: {
    primary: {
      main: yellow[700],
    },
  },
});

export const getTheme = (
  fontSize: "medium" | "large" | "extraLarge"
): Theme => {
  const theme: Theme = createTheme({
    palette: {
      primary: {
        main: yellow[700],
      },
    },
    typography:
      fontSize === "medium"
        ? baseSizes
        : fontSize === "large"
        ? largeSizes
        : extraLargeSizes,
  });
  return theme;
};
