import { Auth } from "aws-amplify";

import { Session } from "../models/session.model";
import { User } from "../models/user.model";
import { cleanPolicies } from "./authorized.util";

export const Logout = async (): Promise<Session> => {
  await Auth.signOut({ global: true });
  cleanPolicies();
  return {
    logged: false,
    userId: "",
    user: {} as User,
    userObject: {},
  };
};
