import React, { FC } from "react";

import { useQuery, useMutation } from "@apollo/client";

import { ClientsComponent } from "../components/clients/clients.component";
import { GET_CLIENTS } from "../client/clients/queries";
import { DELETE_CLIENT } from "../client/clients/mutation";
import { Client } from "../models/client.model";
import { BackdropComponent } from "../components/commons/backdrop/brackdrop.component";

export const ClientsContent: FC = (): JSX.Element => {
  const [deleteClient] = useMutation(DELETE_CLIENT);

  const { loading, data } = useQuery(GET_CLIENTS, {
    variables: {
      filters: "",
    },
  });

  const handleDelete = (clientId: string): void => {
    deleteClient({
      variables: {
        id: clientId,
      },
      optimisticResponse: true,
      update: (cache, { data }) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const existing: any = cache.readQuery({
          query: GET_CLIENTS,
          variables: {
            filters: "",
          },
        });
        if (data.deleteClient) {
          if (existing && existing.getClients) {
            const deleteItem: string = data.deleteClient.message;
            cache.writeQuery({
              query: GET_CLIENTS,
              variables: {
                filters: "",
              },
              data: {
                getClients: {
                  status: true,
                  error: null,
                  clients: existing.getClients.clients.filter(
                    (client: Client) => client.id !== deleteItem
                  ),
                },
              },
            });
          }
        }
      },
    });
  };

  return loading ? (
    <BackdropComponent />
  ) : (
    <ClientsComponent
      clients={(data && data.getClients.clients) || []}
      handleDelete={handleDelete}
    />
  );
};
