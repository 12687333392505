import React, { FC, useState } from "react";

import { JsonEditor as Editor } from "jsoneditor-react";
import { Grid, Button, Typography } from "@material-ui/core";
import { useHistory } from "react-router";
import "jsoneditor-react/es/editor.min.css";

import { Policy } from "../../models/policy.model";
import { IPolicyCreate } from "../../models/components/policy-create.model";
import { can } from "../../utils/authorized.util";

export const PolicyNewComponent: FC<IPolicyCreate> = ({
  createPolicy,
}): JSX.Element => {
  const history = useHistory();

  const [policyState, setPolicyState] = useState<Policy>({
    id: "",
    actions: [] as string[],
    resources: [] as string[],
    effect: "",
    name: "",
  });

  const handleChange = (event: Policy) => {
    setPolicyState({
      ...policyState,
      actions: event.actions,
      resources: event.resources,
      effect: event.effect,
      name: event.name,
    });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    createPolicy(policyState);
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={8}>
        <Grid container direction="column" spacing={2}>
          <form onSubmit={handleSubmit}>
            <Typography variant="h3">Crea una nueva politica</Typography>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Editor onChange={handleChange} value={policyState} />
              </Grid>
              <Grid item>
                <Grid container justifyContent="flex-end" spacing={2}>
                  <Grid item>
                    <Button
                      variant="contained"
                      type="button"
                      onClick={() => history.goBack()}
                    >
                      Regresar
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={!can("POLICIES::CREATE", "POLICIES::*")}
                    >
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Grid>
  );
};
