import React, { FC } from "react";

import { Grid, Paper, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import ReceiptIcon from "@material-ui/icons/Receipt";
import PeopleIcon from "@material-ui/icons/People";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import SecurityIcon from "@material-ui/icons/Security";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import PersonIcon from "@material-ui/icons/Person";

import { useStyles } from "./main.styles";
import { has } from "../../utils/authorized.util";

export const MainComponent: FC = (): JSX.Element => {
  const classes = useStyles();

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={10} lg={8}>
        <Grid container justifyContent="center" spacing={2}>
          {has("SALES::LIST") && (
            <Grid item xs={12} md={6} lg={3}>
              <Link to="/sales" className={classes.link}>
                <Paper elevation={2} square>
                  <Grid container direction="column" alignItems="center">
                    <Grid item>
                      <MonetizationOnIcon className={classes.iconSize} />
                    </Grid>
                    <Grid item>
                      <Typography variant="h4" align="center">
                        Ventas
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Link>
            </Grid>
          )}
          {has("CUTOFF::CREATE") && (
            <Grid item xs={12} md={6} lg={3}>
              <Link to="/sales-cuts" className={classes.link}>
                <Paper elevation={2} square>
                  <Grid container direction="column" alignItems="center">
                    <Grid item>
                      <ReceiptIcon className={classes.iconSize} />
                    </Grid>
                    <Grid item>
                      <Typography variant="h4" align="center">
                        Corte
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Link>
            </Grid>
          )}
          {has("CLIENTS::LIST") && (
            <Grid item xs={12} md={6} lg={3}>
              <Link to="/clients" className={classes.link}>
                <Paper elevation={2} square>
                  <Grid container direction="column" alignItems="center">
                    <Grid item>
                      <PeopleIcon className={classes.iconSize} />
                    </Grid>
                    <Grid item>
                      <Typography variant="h4" align="center">
                        Clientes
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Link>
            </Grid>
          )}
          {has("LOCATIONS::LIST") && (
            <Grid item xs={12} md={6} lg={3}>
              <Link to="/locations" className={classes.link}>
                <Paper elevation={2} square>
                  <Grid container direction="column" alignItems="center">
                    <Grid item>
                      <LocationCityIcon className={classes.iconSize} />
                    </Grid>
                    <Grid item>
                      <Typography variant="h4" align="center">
                        Sucursales
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Link>
            </Grid>
          )}
          {has("EXPENSES::LIST") && (
            <Grid item xs={12} md={6} lg={3}>
              <Link to="/expenses" className={classes.link}>
                <Paper elevation={2} square>
                  <Grid container direction="column" alignItems="center">
                    <Grid item>
                      <AccountBalanceWalletIcon className={classes.iconSize} />
                    </Grid>
                    <Grid item>
                      <Typography variant="h4" align="center">
                        Gastos
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Link>
            </Grid>
          )}
          {has("POLICIES::LIST") && (
            <Grid item xs={12} md={6} lg={3}>
              <Link to="/policies" className={classes.link}>
                <Paper elevation={2} square>
                  <Grid container direction="column" alignItems="center">
                    <Grid item>
                      <SecurityIcon className={classes.iconSize} />
                    </Grid>
                    <Grid item>
                      <Typography variant="h4" align="center">
                        Políticas
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Link>
            </Grid>
          )}
          {has("ROLES::LIST") && (
            <Grid item xs={12} md={6} lg={3}>
              <Link to="/roles" className={classes.link}>
                <Paper elevation={2} square>
                  <Grid container direction="column" alignItems="center">
                    <Grid item>
                      <AssignmentIndIcon className={classes.iconSize} />
                    </Grid>
                    <Grid item>
                      <Typography variant="h4" align="center">
                        Roles
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Link>
            </Grid>
          )}
          <Grid item xs={12} md={6} lg={3}>
            <Link to="/profile" className={classes.link}>
              <Paper elevation={2} square>
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <PersonIcon className={classes.iconSize} />
                  </Grid>
                  <Grid item>
                    <Typography variant="h4" align="center">
                      Perfil
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
