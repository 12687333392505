import { gql } from "@apollo/client";

export const GET_CLIENTS = gql`
  query getClients($filters: String!) {
    getClients(filters: $filters) {
      status
      clients {
        id
        name
        rfc
        address
        city
        phone
        registerDate
        commission
        commissionType
        alternativeAddress
      }
      error {
        code
        message
      }
    }
  }
`;

export const GET_CLIENT = gql`
  query getClient($id: String!) {
    getClient(id: $id) {
      status
      client {
        id
        name
        rfc
        address
        city
        phone
        registerDate
        commission
        commissionType
        alternativeAddress
      }
      error {
        code
        message
      }
    }
  }
`;
