import { TypographyOptions } from "@material-ui/core/styles/createTypography";

export const baseSizes: TypographyOptions = {
  htmlFontSize: 16,
  fontSize: 14,
  h1: {
    fontSize: "6rem",
  },
  h2: {
    fontSize: "3.75rem",
  },
  h3: {
    fontSize: "3rem",
  },
  h4: {
    fontSize: "2.125rem",
  },
  h5: {
    fontSize: "1.5rem",
  },
  h6: {
    fontSize: "1.25rem",
  },
  subtitle1: {
    fontSize: "1rem",
  },
  subtitle2: {
    fontSize: "0.875rem",
  },
  body1: {
    fontSize: "1rem",
  },
  body2: {
    fontSize: "0.875rem",
  },
  button: {
    fontSize: "0.875rem",
  },
  caption: {
    fontSize: "0.75rem",
  },
  overline: {
    fontSize: "0.75rem",
  },
};

export const largeSizes: TypographyOptions = {
  htmlFontSize: 16,
  fontSize: 14,
  h1: {
    fontSize: "7rem",
  },
  h2: {
    fontSize: "4rem",
  },
  h3: {
    fontSize: "3.5rem",
  },
  h4: {
    fontSize: "2.5rem",
  },
  h5: {
    fontSize: "2rem",
  },
  h6: {
    fontSize: "1.5rem",
  },
  subtitle1: {
    fontSize: "1.25rem",
  },
  subtitle2: {
    fontSize: "1rem",
  },
  body1: {
    fontSize: "1.25rem",
  },
  body2: {
    fontSize: "1rem",
  },
  button: {
    fontSize: "1rem",
  },
  caption: {
    fontSize: "0.875rem",
  },
  overline: {
    fontSize: "0.875rem",
  },
};

export const extraLargeSizes: TypographyOptions = {
  htmlFontSize: 20,
  fontSize: 18,
  h1: {
    fontSize: "7rem",
  },
  h2: {
    fontSize: "5rem",
  },
  h3: {
    fontSize: "4rem",
  },
  h4: {
    fontSize: "3rem",
  },
  h5: {
    fontSize: "2.25rem",
  },
  h6: {
    fontSize: "2rem",
  },
  subtitle1: {
    fontSize: "1.5rem",
  },
  subtitle2: {
    fontSize: "1.25rem",
  },
  body1: {
    fontSize: "1.5rem",
  },
  body2: {
    fontSize: "1.25rem",
  },
  button: {
    fontSize: "1.25rem",
  },
  caption: {
    fontSize: "1rem",
  },
  overline: {
    fontSize: "1rem",
  },
};
