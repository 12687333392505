import { gql } from "@apollo/client";

export const CREATE_EXPENSE = gql`
  mutation createExpense(
    $location: String!
    $product: String!
    $price: Int!
    $quantity: Int!
    $description: String
  ) {
    createExpense(
      location: $location
      product: $product
      price: $price
      quantity: $quantity
      description: $description
    ) {
      status
      expense {
        location {
          id
          name
          phone
          address
        }
        date
        product
        price
        quantity
        description
        canceled
        cancelReason
      }
      error {
        code
        message
      }
    }
  }
`;

export const UPDATE_EXPENSE = gql`
  mutation updateExpense(
    $location: String!
    $date: Int!
    $product: String!
    $price: Int!
    $quantity: Int!
    $description: String
  ) {
    updateExpense(
      location: $location
      date: $date
      product: $product
      price: $price
      quantity: $quantity
      description: $description
    ) {
      status
      expense {
        location {
          id
          name
          phone
          address
        }
        date
        product
        price
        quantity
        description
        canceled
        cancelReason
      }
      error {
        code
        message
      }
    }
  }
`;

export const CANCEL_EXPENSE = gql`
  mutation cancelExpense(
    $location: String!
    $date: Int!
    $cancelReason: String!
  ) {
    cancelExpense(
      location: $location
      date: $date
      cancelReason: $cancelReason
    ) {
      status
      message
      error {
        code
        message
      }
    }
  }
`;
