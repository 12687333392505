import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  centerText: {
    textAlign: "center",
  },
  iconSize: {
    fontSize: 60,
  },
  link: {
    textDecoration: "none",
  },
});
