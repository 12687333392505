import React, { FC } from "react";

import { ExpenseEditComponent } from "../components/expense-edit/expense-edit.component";
import { Expense } from "../models/expense.model";
interface IExpenseEditContent {
  expense: Expense;
}

export const ExpenseEditContent: FC<IExpenseEditContent> = ({
  expense,
}): JSX.Element => {
  return <ExpenseEditComponent expense={expense} />;
};
