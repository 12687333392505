import { object, string, number } from "yup";

export const clientSchema = object({
  id: string().uuid(),
  name: string().required().default(""),
  rfc: string().max(13).default(""),
  address: string().required().default(""),
  alternativeAddress: string().default(""),
  city: string().required().default(""),
  phone: string()
    .matches(/[0-9]{7,10}/g)
    .required(),
  registerDate: number().positive(),
  commission: number().default(0),
  commissionType: string().default(""),
});
