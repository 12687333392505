import { gql } from "@apollo/client";

export const UPDATE_POLICY = gql`
  mutation UpdatePolicy(
    $id: String!
    $effect: String!
    $actions: [String]
    $resources: [String]
    $name: String!
  ) {
    updatePolicy(
      id: $id
      actions: $actions
      resources: $resources
      name: $name
      effect: $effect
    ) {
      status
      policy {
        actions
        name
        resources
        effect
      }
      error {
        message
        code
      }
    }
  }
`;
export const DELTE_POLICY = gql`
  mutation deletePolicy($id: String!) {
    deletePolicy(id: $id) {
      status
      message
    }
  }
`;
export const CREATE_POLICY = gql`
  mutation createPolicy(
    $effect: String!
    $actions: [String]
    $resources: [String]
    $name: String!
  ) {
    createPolicy(
      actions: $actions
      resources: $resources
      name: $name
      effect: $effect
    ) {
      status
      policy {
        actions
        name
        resources
        effect
        id
      }
      error {
        message
        code
      }
    }
  }
`;

export const ADD_POLICY_TO_ROLE = gql`
  mutation addPolicyToRole($policy: String!, $role: String!) {
    addPolicyToRole(role: $role, policy: $policy) {
      status
      policyRole {
        role {
          id
          name
          description
        }
      }
      error {
        message
        code
      }
    }
  }
`;
export const DELETE_POLICY_FROM_ROLE = gql`
  mutation deletePolicyFromRole($policy: String!, $role: String!) {
    deletePolicyFromRole(role: $role, policy: $policy) {
      status
      message
      error {
        message
        code
      }
    }
  }
`;
