import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  dates: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "5px 0",
  },
  dateValue: {
    marginLeft: "5px",
  },
  scroll: {
    overflowY: "scroll",
    display: "inline-block",
    whiteSpace: "nowrap",
    width: "100%",
    height: "60px",
  },
  chip: {
    margin: "0 5px",
  },
  textCenter: {
    textAlign: "center",
  },
  dialogPrint: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    margin: "-30px",
  },
});
