import { useMutation } from "@apollo/client";
import React, { FC } from "react";
import { useHistory } from "react-router";
import { DELETE_LOCATION } from "../client/locations/mutation";
import { GET_LOCATIONS } from "../client/locations/queries";
import { LocationsComponent } from "../components/locations/locations.component";
import { ILocation } from "../models/location";

export const LocationsContext: FC<{ locations: ILocation[] }> = ({
  locations,
}): JSX.Element => {
  const history = useHistory();
  const [deleteLocation] = useMutation(DELETE_LOCATION);

  const handleDelete = async (id: string) => {
    deleteLocation({
      variables: { id },
      update: async (cache, { data }) => {
        if (data && data.deleteLocation) {
          const existing: any = cache.readQuery({ query: GET_LOCATIONS });
          if (existing && existing.getLocations) {
            const arrayLocations: ILocation[] = existing.getLocations.locations;
            if (arrayLocations) {
              await cache.writeQuery({
                query: GET_LOCATIONS,
                data: {
                  getLocations: {
                    status: true,
                    error: null,
                    locations: arrayLocations.filter(
                      (location: ILocation) => location.id !== id
                    ),
                  },
                },
              });
              history.push("/locations");
            }
          }
        }
      },
    });
  };
  return (
    <LocationsComponent locations={locations} handleDelete={handleDelete} />
  );
};
