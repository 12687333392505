import { gql } from "@apollo/client";

export const CREATE_ROLE = gql`
  mutation createRole($name: String!, $description: String) {
    createRole(name: $name, description: $description) {
      status
      role {
        id
        name
        description
      }
    }
  }
`;

export const UPDATE_ROLE = gql`
  mutation updateRole($id: String!, $name: String!, $description: String) {
    updateRole(id: $id, name: $name, description: $description) {
      status
      role {
        id
        name
        description
      }
      error {
        code
        message
      }
    }
  }
`;

export const DELETE_ROLE = gql`
  mutation deleteRole($id: String!) {
    deleteRole(id: $id) {
      status
      message
      error {
        code
        message
      }
    }
  }
`;

export const ADD_USER_TO_ROLE = gql`
  mutation addUserToRole($user: String!, $role: String!) {
    addUserToRole(user: $user, role: $role) {
      status
      user {
        id
        username
        name
        email
      }
      error {
        code
        message
      }
    }
  }
`;

export const DELETE_USER_FROM_ROLE = gql`
  mutation deleteUserFromRole($user: String!, $role: String!) {
    deleteUserFromRole(user: $user, role: $role) {
      status
      message
      error {
        code
        message
      }
    }
  }
`;

export const ADD_POLICY_TO_ROLE = gql`
  mutation addPolicyToRole($policy: String!, $role: String!) {
    addPolicyToRole(policy: $policy, role: $role) {
      status
      policyRole {
        policy {
          id
          name
          effect
          resources
          actions
        }
        role {
          id
          name
          description
        }
      }
      error {
        code
        message
      }
    }
  }
`;

export const DELETE_POLICY_FROM_ROLE = gql`
  mutation deletePolicyFromRole($policy: String!, $role: String!) {
    deletePolicyFromRole(policy: $policy, role: $role) {
      status
      message
      error {
        code
        message
      }
    }
  }
`;
