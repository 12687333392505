import { makeStyles } from "@material-ui/styles";
import {
  red,
  pink,
  purple,
  blue,
  green,
  lime,
  yellow,
  amber,
  orange,
} from "@material-ui/core/colors";

export const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",

    height: "85vh",
    width: "100vw",
    backgroundColor: "rgb(235, 235, 235)",
  },
  form: {
    textAlign: "center",
    alignItems: "center",

    display: "flex",
    flexDirection: "column",
    backgroundColor: "rgb(255, 255, 255)",
    borderRadius: "25px",
  },
  textField: {
    width: "75vw",
    margin: "20px !important",
  },
  btnContainer: {
    width: "15vw",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    margin: "20px !important",
    marginBottom: "20px !important",
    background: "none",
    color: "inherit",
    border: "none",
    padding: 0,
    font: "inherit",
    cursor: "pointer",
    outline: "inherit",
    borderColor: "none",
  },
  redColor: {
    color: red[500],
  },
  pinkColor: {
    color: pink[500],
  },
  purpleColor: {
    color: purple[500],
  },
  blueColor: {
    color: blue[500],
  },
  greenColor: {
    color: green[500],
  },
  limeColor: {
    color: lime[500],
  },
  yellowColor: {
    color: yellow[500],
  },
  amberColor: {
    color: amber[500],
  },
  orangeColor: {
    color: orange[500],
  },
});
