import React, { ChangeEvent, FC, useState, useEffect, useContext } from "react";

import {
  Grid,
  Paper,
  Avatar,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import EmailIcon from "@material-ui/icons/Email";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";

import { UPDATE_USER } from "../../client/users/mutation";
import { useStyles } from "./profile.styles";
import { BackdropComponent } from "../../components/commons/backdrop/brackdrop.component";
import { SessionContext } from "../../context/session.context";
import { Profile } from "../../models/profile.model";
import { GET_USER } from "../../client/users/queries";
import { User } from "../../models/user.model";
import { avatarUtil } from "../../utils/avatar.util";
import LOGO from "../../assets/logo.png";

export const ProfileComponent: FC = (): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const session = useContext(SessionContext);

  const history = useHistory();

  const [updateUser] = useMutation(UPDATE_USER);

  const [user, setUser] = useState<Profile>({
    name: "",
    username: "",
    email: "",
    phone: "",
    defaultLocation: "",
    textSize: "medium",
  });

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (session.logged) {
      setUser({
        name: session.user.name,
        username: session.user.username,
        email: session.user.email,
        phone: session.user.phone,
        defaultLocation: session?.user?.defaultLocation?.id || "",
        textSize:
          (session.user.textSize as "medium" | "large" | "extraLarge") ||
          "medium",
      });
      setLoading(false);
    }
  }, []);

  const handleChangeText = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ): void => {
    const value: "medium" | "large" | "extraLarge" = event.target.value as
      | "medium"
      | "large"
      | "extraLarge";
    setUser({
      ...user,
      textSize: value,
    });
    session.handleFontSize(value);
  };

  const handleChangePhone = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ): void => {
    const value: string = event.target.value as string;
    setUser({
      ...user,
      phone: value,
    });
  };

  const handleSave = async () => {
    await updateUser({
      variables: {
        id: session.userId,
        name: user.name,
        defaultLocation: user.defaultLocation,
        textSize: user.textSize,
        phone: user.phone,
      },
      optimisticResponse: true,
      update: (cache, { data }) => {
        const existing: any = cache.readQuery({
          query: GET_USER,
          variables: {
            id: session.userId,
          },
        });
        if (existing && existing.getUser && data) {
          const updateItem: User = data.updateUser.user;
          cache.writeQuery({
            query: GET_USER,
            variables: {
              id: session.userId,
            },
            data: {
              getUser: {
                user: updateItem,
                error: null,
                status: true,
              },
            },
          });
          session.handleUser(updateItem);
        }
      },
    });
    enqueueSnackbar("Perfil actualizado", {
      variant: "success",
      resumeHideDuration: 3000,
      anchorOrigin: { horizontal: "right", vertical: "bottom" },
    });
    history.push("/main");
  };

  return loading ? (
    <BackdropComponent />
  ) : (
    <Grid container direction="row" justifyContent="center">
      <Grid item xs={12} md={8} lg={6}>
        <Paper elevation={3}>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={10}>
              <Grid container justifyContent="center">
                <Avatar src={LOGO} alt="Guero" className={classes.avatar}>
                  {avatarUtil(user.name)}
                </Avatar>
              </Grid>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="h3" align="center">
                {user.name}
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <AccountCircleIcon />
                  </ListItemIcon>
                  <ListItemText primary={user.username} />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemIcon>
                    <EmailIcon />
                  </ListItemIcon>
                  <ListItemText primary={user.email} />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                id="phone"
                name="phone"
                fullWidth
                label="Teléfono"
                variant="outlined"
                defaultValue={user.phone}
                onChange={handleChangePhone}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="text-label">Tamaño de texto</InputLabel>
                <Select
                  fullWidth
                  labelId="text-label"
                  id="text"
                  label="Selecciona el tamaño de letra"
                  onChange={handleChangeText}
                  defaultValue={user.textSize}
                >
                  <MenuItem value="medium">Mediano</MenuItem>
                  <MenuItem value="large">Grande</MenuItem>
                  <MenuItem value="extra-large">Muy grande</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={10}>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Button
                    onClick={handleSave}
                    variant="contained"
                    color="primary"
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
