export const salesStatus = {
  on_progress: "En progreso",
  completed: "Completada",
  canceled: "Cancelada",
};

export const salesPayment = {
  cash: "Efectivo",
  card: "Tarjeta",
  credit: "Crédito",
  free: "Cortesia",
};
