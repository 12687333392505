import React, { createContext, FC, useState } from "react";
import { ISessionContext } from "../models/context/session.model";
import { ILocation } from "../models/location";
import { Session } from "../models/session.model";
import { User } from "../models/user.model";

export const SessionContext = createContext<ISessionContext>({
  logged: false,
  userId: "",
  user: {} as User,
  userObject: {},
  fontSize: "medium",
  location: {} as ILocation,
  handleSession: () => null,
  handleLocation: () => null,
  handleFontSize: (fontSize: "medium" | "large" | "extraLarge") => null,
  handleUser: (user: User) => null,
});

export const SessionContextProvider: FC = ({ children }): JSX.Element => {
  const [session, setSession] = useState<ISessionContext>({
    logged: false,
    userId: "",
    user: {} as User,
    userObject: {},
    location: {} as ILocation,
    fontSize: "medium",
    handleSession: () => null,
    handleLocation: () => null,
    handleFontSize: (fontSize: "medium" | "large" | "extraLarge") => null,
    handleUser: (user: User) => null,
  });

  const handleSession = (session_: Session): void => {
    const { logged, userId, user, userObject } = session_;
    setSession({
      ...session,
      logged,
      userId,
      user,
      userObject,
    });
  };

  const handleLocation = (location: ILocation): void => {
    setSession({
      ...session,
      location,
    });
  };

  const handleFontSize = (
    fontSize: "medium" | "large" | "extraLarge"
  ): void => {
    setSession({
      ...session,
      fontSize,
    });
  };

  const handleUser = (user: User) => {
    setSession({
      ...session,
      user,
    });
  };

  const { logged, userId, user, userObject, location, fontSize } = session;

  return (
    <SessionContext.Provider
      value={{
        logged,
        userId,
        user,
        userObject,
        location,
        fontSize,
        handleSession,
        handleLocation,
        handleFontSize,
        handleUser,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
