export const env = {
  AWS: {
    cognito: {
      aws_user_pools_web_client_id: "4jkfhq63cf6akt6ovtem9tg90t",
      aws_project_region: "us-west-2",
      aws_cognito_region: "us-west-2",
      aws_user_pools_id: "us-west-2_TvhrkK0uU",
      aws_cognito_identity_pool_id:
        "us-west-2:a1e80702-2444-4d41-8a11-2d97aec24090",
    },
  },
  ENDPOINT: "https://api.quesadillaselguero.com/PROD/graphql",
  DELIVERY_COST: 25,
};
