import React, { FC, useMemo, useState } from "react";

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
import { useForm } from "react-yup";
import { useSnackbar } from "notistack";
import { format, fromUnixTime } from "date-fns";

import { Client } from "../../models/client.model";
import { clientSchema } from "../../forms/client.form";
import { IClientEdit } from "../../models/components/client-edit.model";
import { can } from "../../utils/authorized.util";

export const ClientEditComponent: FC<IClientEdit> = ({
  client,
  handleEdit,
}): JSX.Element => {
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const [havecommission, setHavecommission] = useState<boolean>(
    (client.commission && client.commission > 0 && true) || false
  );

  const { touched, errors, field, createSubmitHandler, setValue } = useForm({
    validationSchema: clientSchema,
    defaultValues: {
      ...client,
    },
  });

  const handlecommission = (): void => {
    setHavecommission(!havecommission);
    setValue("commission", 0);
  };

  const handleSubmit = useMemo(() => {
    return createSubmitHandler(
      (values) => {
        const { id, registerDate } = client;
        const {
          name,
          rfc,
          address,
          city,
          phone,
          commission,
          commissionType,
          alternativeAddress,
        } = values;
        const updatedClient: Client = {
          id,
          registerDate,
          name: name as string,
          rfc: rfc as string,
          address: address as string,
          city: city as string,
          phone: phone as string,
          commission: commission as number,
          commissionType: commissionType as "money" | "percentage",
          alternativeAddress,
        };
        handleEdit(updatedClient);
        enqueueSnackbar(`${name} ha sido modificado`, {
          variant: "success",
          resumeHideDuration: 3000,
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
        });
        history.push("/clients");
      },
      () => {
        enqueueSnackbar("Algunos campos contienen errores", {
          variant: "error",
          resumeHideDuration: 6000,
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
        });
      }
    );
  }, [createSubmitHandler, enqueueSnackbar, history, client, handleEdit]);

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} md={10} lg={8}>
        <Paper elevation={2}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Typography variant="h4">Editar Cliente {client.name}</Typography>
              <Grid item xs={10}>
                <Typography variant="body1">
                  Usuario creado el{" "}
                  {format(fromUnixTime(client.registerDate || 0), "dd/MM/yyyy")}
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  name="name"
                  label="Nombre"
                  variant="outlined"
                  required
                  defaultValue={client.name}
                  {...field}
                  helperText={
                    (errors.name && touched.name && "Obligatorio") || ""
                  }
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  type="tel"
                  name="phone"
                  label="Teléfono"
                  variant="outlined"
                  defaultValue={client.phone}
                  required
                  {...field}
                  helperText={
                    (errors.phone &&
                      touched.phone &&
                      "Obligatorio, debe contener de 7 a 10 dígitos") ||
                    ""
                  }
                  inputProps={{ maxLength: 10 }}
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  name="rfc"
                  label="RFC"
                  variant="outlined"
                  defaultValue={client.rfc}
                  {...field}
                  helperText={
                    (errors.rfc &&
                      touched.rfc &&
                      "RFC debe contener al menos 12 letras") ||
                    ""
                  }
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  name="address"
                  label="Dirección"
                  variant="outlined"
                  defaultValue={client.address}
                  required
                  {...field}
                  helperText={
                    (errors.address && touched.address && "Obligatorio") || ""
                  }
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  name="alternativeAddress"
                  label="Dirección alternativa"
                  variant="outlined"
                  defaultValue={client.alternativeAddress}
                  {...field}
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  name="city"
                  label="Ciudad"
                  variant="outlined"
                  required
                  defaultValue={client.city}
                  {...field}
                  helperText={
                    (errors.city && touched.city && "Obligatorio") || ""
                  }
                />
              </Grid>
              <Grid item xs={10}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item xs={12} md={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={havecommission}
                          onChange={() => handlecommission()}
                          name="iscommission"
                          color="primary"
                        />
                      }
                      label="Aplicar comisión"
                    />
                  </Grid>
                  {havecommission && (
                    <Grid item xs={12} md={6}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="demo-simple-select-outlined-label">
                          Tipo de comisión
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          name="commissionType"
                          label="Tipo de comisión"
                          onChange={field.onChange as any}
                          defaultValue={client.commissionType}
                        >
                          <MenuItem value="percentage">Porcentaje (%)</MenuItem>
                          <MenuItem value="money">Dinero ($)</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  {havecommission && (
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        name="commission"
                        label="Comisión"
                        variant="outlined"
                        defaultValue={client.commission}
                        {...field}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={10}>
                <Grid container justifyContent="flex-end" spacing={2}>
                  <Grid item>
                    <Button
                      variant="contained"
                      size="small"
                      startIcon={<ArrowBackIcon />}
                      type="button"
                      onClick={() => history.push("/clients")}
                    >
                      Regresar
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      startIcon={<CheckCircleIcon />}
                      type="submit"
                      disabled={
                        !can("CLIENTS::UPDATE", `CLIENTS::*${client.id}`)
                      }
                    >
                      Editar cliente
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};
