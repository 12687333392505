import React, { FC, useContext, useState } from "react";

import {
  Grid,
  Paper,
  InputLabel,
  FormControlLabel,
  FormControl,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  TextField,
  FormGroup,
  Checkbox,
  Radio,
  RadioGroup,
  FormLabel,
  OutlinedInput,
  InputAdornment,
} from "@material-ui/core";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import RemoveIcon from "@material-ui/icons/Remove";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import SaveIcon from "@material-ui/icons/Save";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  format,
  getUnixTime,
  startOfDay,
  endOfDay,
  fromUnixTime,
} from "date-fns";
import { useQuery, useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";

import { useStyles } from "./sale-edit.styles";
import { Category } from "../../models/category.model";
import { Product } from "../../models/product.model";
import { Typography } from "@material-ui/core";
import { Order } from "../../models/order.model";
import { PaymentMethod } from "../../models/payment-method.model";
import { GET_PRODUCTS } from "../../client/products/queries";
import { GET_CATEGORIES } from "../../client/categories/queries";
import { UPDATE_SALE } from "../../client/sales/mutation";
import { GET_SALE, GET_SALES } from "../../client/sales/queries";
import { Sale } from "../../models/sale.model";
import { Promo } from "../../models/promo.model";
import { ISaleEdit } from "../../models/components/sale-edit.model";
import { can } from "../../utils/authorized.util";
import { SessionContext } from "../../context/session.context";

export const SaleEditComponent: FC<ISaleEdit> = ({ saleEdit }): JSX.Element => {
  const today: Date = fromUnixTime(saleEdit.date);

  const history = useHistory();

  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const session = useContext(SessionContext);

  const [updateSale] = useMutation(UPDATE_SALE);

  const [invoice, setInvoice] = useState<boolean>(
    (saleEdit.taxes > 0 && true) || false
  );
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>("cash");
  const [paymentCash, setPaymentCash] = useState<string>("0");
  const [currentCategory, setCurrentCategory] = useState<Category>();
  const [currentProduct, setCurrentProduct] = useState<Product>();
  const [mixtures, setMixtures] = useState<Product[][]>([]);
  const [taxes, setTaxes] = useState<number>(saleEdit.taxes || 0);
  const [table, setTable] = useState<string>(saleEdit.table);
  const [deliveryTime, setDeliveryTime] = useState<string>(
    saleEdit.deliveryTime || ""
  );

  const [orderInfo, setOrderInfo] = useState<{
    orders: Order[];
    total: number;
  }>({
    orders: saleEdit.orders,
    total: saleEdit.total,
  });

  const productsItems = useQuery(GET_PRODUCTS, {
    variables: {
      filters:
        (currentCategory && `{'category': '${currentCategory.id}'}`) || "",
    },
  });

  const categoriesItems = useQuery(GET_CATEGORIES);

  const allProductsItems = useQuery(GET_PRODUCTS, {
    variables: {
      filters: "",
    },
  });

  const handleAddProduct = (product: Product): void => {
    const newItem: Order = {
      product,
      quantity: 1,
      ingredients:
        product.mixture || product.promoItems
          ? mixtures.map((mixtures: Product[]) =>
              mixtures.map((mixture: Product) => mixture.name)
            )
          : [[]],
    };
    setOrderInfo({
      total: orderInfo.total + product.price,
      orders: [...orderInfo.orders, newItem],
    });
    setCurrentCategory(undefined);
    setCurrentProduct(undefined);
  };

  const handleChangeCategory = (category: Category | null): void => {
    setCurrentCategory(category || currentCategory);
    setCurrentProduct(undefined);
    setMixtures([[]]);
  };

  const handleChangeProduct = (product: Product | null): void => {
    setCurrentProduct(product || currentProduct);
    setMixtures([[]]);
  };

  const handleQuantity = (
    index: number,
    type: "+" | "-",
    price: number
  ): void => {
    setOrderInfo({
      orders: orderInfo.orders.map((item: Order, idx: number) =>
        idx === index
          ? {
              ...item,
              quantity: type === "+" ? item.quantity + 1 : item.quantity - 1,
            }
          : item
      ),
      total: orderInfo.total + (type === "+" ? +price : -price),
    });
  };

  const handleDeleteProduct = (
    product: Product,
    quantity: number,
    index: number
  ): void => {
    const filtered: Order[] = orderInfo.orders.filter(
      (item: Order, idx: number) => idx !== index
    );
    setOrderInfo({
      total: orderInfo.total - product.price * quantity,
      orders: filtered,
    });
  };

  const handleInvoice = (): void => {
    setTaxes(invoice ? 0 : orderInfo.total * 0.16);
    setInvoice(!invoice);
  };

  const handleMixture = (products: Product[]): void => {
    setMixtures([products]);
  };

  const handleMixturesOnPromo = (products: Product[], index: number) => {
    if (index >= mixtures.length) {
      setMixtures([...mixtures, products]);
    } else {
      setMixtures(
        mixtures.map((pdrs: Product[], idx: number) =>
          index === idx ? products : pdrs
        )
      );
    }
  };

  const goToTicket = (saleId: string, cash: number, change: number): void => {
    window.open(
      `${window.location.origin}/ticket?sale=${saleId}&cash=${cash}&change=${change}`,
      "_blank"
    );
  };

  const save = async (status: "on_progress" | "completed") => {
    await updateSale({
      variables: {
        id: saleEdit.id,
        type: saleEdit.type,
        paymentMethod: paymentMethod,
        orders: orderInfo.orders.map(
          ({ product, quantity, ingredients }: Order) => ({
            product: product.id,
            quantity,
            ingredients,
          })
        ),
        total: orderInfo.total,
        date: getUnixTime(new Date()),
        location: saleEdit.location.id,
        taxes: +taxes,
        client: saleEdit.client?.id || "",
        status: (saleEdit.isExternal && "completed") || status,
        table: table,
        deliveryTime,
      },
      optimisticResponse: true,
      update: (cache, { data }) => {
        const existing: any = cache.readQuery({
          query: GET_SALES,
          variables: {
            filters: JSON.stringify({
              location: saleEdit.location,
              from: getUnixTime(startOfDay(today)),
              to: getUnixTime(endOfDay(today)),
            }),
          },
        });
        if (data.updateSale) {
          if (existing && existing.getSales) {
            const updateItem: Sale = data.updateSale.sale;
            cache.writeQuery({
              query: GET_SALES,
              variables: {
                filters: JSON.stringify({
                  location: saleEdit.location,
                  from: getUnixTime(startOfDay(today)),
                  to: getUnixTime(endOfDay(today)),
                }),
              },
              data: {
                getSales: {
                  status: true,
                  error: null,
                  sales: existing.getSales.sales.map((sale: Sale) =>
                    sale.id === updateItem.id ? updateItem : sale
                  ),
                },
              },
            });
            cache.writeQuery({
              query: GET_SALE,
              variables: {
                id: saleEdit.id,
              },
              data: {
                getSale: {
                  status: true,
                  error: null,
                  sale: updateItem,
                },
              },
            });
            if (updateItem.status === "completed") {
              goToTicket(
                updateItem.id as string,
                +paymentCash || 0,
                (+paymentCash > orderInfo.total &&
                  +paymentCash - orderInfo.total) ||
                  0
              );
            }
          }
        }
      },
    });
    enqueueSnackbar("Venta editada", {
      variant: "success",
      resumeHideDuration: 3000,
      anchorOrigin: { horizontal: "right", vertical: "bottom" },
    });
    history.push("/sales");
  };

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12} lg={8}>
        <Paper elevation={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                className={classes.textCenter}
                component="h3"
                variant="h3"
              >
                Editar venta
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <p className={classes.dates}>
                <CalendarTodayIcon />
                <Typography className={classes.dateValue} variant="body1">
                  {format(today, "dd/MM/yyyy H:mm")}
                </Typography>
              </p>
            </Grid>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              {!categoriesItems.loading && (
                <Grid item xs={11} md={3}>
                  <Autocomplete
                    id="categories"
                    loading={categoriesItems.loading}
                    loadingText="Cargando..."
                    options={
                      categoriesItems.data.getCategories.categories || []
                    }
                    getOptionLabel={(option: Category) => option.name}
                    onChange={(event, value) => handleChangeCategory(value)}
                    disableClearable={true}
                    fullWidth
                    renderInput={(params: any) => {
                      const value = currentCategory;
                      params.inputProps.value =
                        (currentCategory && currentCategory.name) || "";
                      return (
                        <TextField
                          {...params}
                          value={value}
                          label="Categoria"
                          variant="outlined"
                        />
                      );
                    }}
                    disabled={saleEdit.isExternal}
                  />
                </Grid>
              )}
              {!productsItems.loading && currentCategory && (
                <Grid item xs={11} md={5}>
                  <Autocomplete
                    id="products"
                    options={productsItems.data.getProducts.products || []}
                    getOptionLabel={(option: Product) => option.name}
                    onChange={(event, value) => handleChangeProduct(value)}
                    disableClearable={true}
                    fullWidth
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        label="Productos"
                        variant="outlined"
                      />
                    )}
                    disabled={saleEdit.isExternal}
                  />
                </Grid>
              )}
              {currentProduct?.mixture && (
                <Grid item xs={11} md={5}>
                  <Autocomplete
                    multiple
                    options={
                      productsItems.data.getProducts.products.filter(
                        (product: Product) => !product.mixture
                      ) || []
                    }
                    getOptionLabel={(option: Product) => option.name}
                    onChange={(event, value) => handleMixture(value)}
                    getOptionDisabled={() =>
                      mixtures[0] && mixtures[0].length === 2
                    }
                    disableClearable={true}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Ingredientes"
                        placeholder="Selecciona los ingredientes"
                      />
                    )}
                  />
                </Grid>
              )}
              {currentProduct?.promoItems && (
                <Grid item xs={11}>
                  <Grid container spacing={2}>
                    {currentProduct.promoItems.map(
                      (promo: Promo, index: number) => (
                        <Grid item key={index} xs={12}>
                          {promo.mixture ? (
                            <Autocomplete
                              multiple
                              options={
                                (allProductsItems.data &&
                                  allProductsItems.data.getProducts.products.filter(
                                    (product: Product) =>
                                      product.category.id === promo.category.id
                                  )) ||
                                []
                              }
                              getOptionLabel={(option: Product) => option.name}
                              onChange={(event, value) =>
                                handleMixturesOnPromo(value, index)
                              }
                              filterSelectedOptions
                              disableClearable={true}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label={promo.category.name}
                                  placeholder="Selecciona los ingredientes"
                                />
                              )}
                            />
                          ) : (
                            <Autocomplete
                              options={
                                (allProductsItems.data &&
                                  allProductsItems.data.getProducts.products.filter(
                                    (product: Product) =>
                                      product.category.id === promo.category.id
                                  )) ||
                                []
                              }
                              getOptionLabel={(option: Product) => option.name}
                              onChange={(event, value) =>
                                handleMixturesOnPromo([value as Product], index)
                              }
                              fullWidth
                              disableClearable={true}
                              renderInput={(params: any) => (
                                <TextField
                                  {...params}
                                  label={promo.category.name}
                                  variant="outlined"
                                />
                              )}
                            />
                          )}
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              )}
              {currentCategory && (
                <Grid item xs={11} md={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<AddIcon />}
                    disabled={!currentProduct}
                    onClick={() => handleAddProduct(currentProduct as Product)}
                  >
                    Agregar
                  </Button>
                </Grid>
              )}
            </Grid>
            {orderInfo.orders.length > 0 && (
              <Grid item xs={12}>
                <Table aria-label="order">
                  <TableHead>
                    <TableRow>
                      <TableCell>Producto</TableCell>
                      <TableCell align="right">Cantidad</TableCell>
                      <TableCell align="right">Precio</TableCell>
                      <TableCell align="right">Acción</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderInfo.orders.map((row: Order, index: number) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {`${row.product.category.name} - ${row.product.name}`}
                          <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                          >
                            {(row.ingredients.flat().length > 0 &&
                              row.ingredients
                                .map(
                                  (mixtures: string[], idx: number) =>
                                    `${idx + 1}. ${mixtures.join(", ")}`
                                )
                                .join(" - ")) ||
                              ""}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            aria-label="minus"
                            color="primary"
                            disabled={row.quantity === 1 || saleEdit.isExternal}
                            onClick={() =>
                              handleQuantity(index, "-", row.product.price)
                            }
                          >
                            <RemoveIcon />
                          </IconButton>
                          <span>{row.quantity}</span>
                          <IconButton
                            aria-label="add"
                            color="primary"
                            onClick={() =>
                              handleQuantity(index, "+", row.product.price)
                            }
                            disabled={saleEdit.isExternal}
                          >
                            <AddIcon />
                          </IconButton>
                        </TableCell>
                        <TableCell align="right">
                          ${row.product.price}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            aria-label="delete"
                            size="small"
                            color="secondary"
                            onClick={() =>
                              handleDeleteProduct(
                                row.product,
                                row.quantity,
                                index
                              )
                            }
                            disabled={saleEdit.isExternal}
                          >
                            <DeleteIcon fontSize="inherit" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell colSpan={4} align="right">
                        Total: ${orderInfo.total}
                      </TableCell>
                    </TableRow>
                    {taxes > 0 && (
                      <TableRow>
                        <TableCell colSpan={4} align="right">
                          IVA: ${taxes}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} lg={4}>
        <Paper>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <Typography
                className={classes.textCenter}
                component="h5"
                variant="h5"
              >
                Datos restaurante
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                id="table"
                label="Mesa"
                variant="outlined"
                defaultValue={table}
                onChange={(event) => setTable(event.currentTarget.value)}
                disabled={saleEdit.isExternal}
              />
            </Grid>
            {!saleEdit.isExternal && (
              <Grid item xs={11}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Método de pago</FormLabel>
                  <RadioGroup
                    row
                    aria-label="payment"
                    name="payment"
                    value={paymentMethod}
                    onChange={(event, value) =>
                      setPaymentMethod(value as PaymentMethod)
                    }
                  >
                    <FormControlLabel
                      value="cash"
                      control={<Radio />}
                      label="Efectivo"
                    />
                    <FormControlLabel
                      value="card"
                      control={<Radio />}
                      label="Tarjeta"
                    />
                    <FormControlLabel
                      value="credit"
                      control={<Radio />}
                      label="Crédito"
                    />
                    <FormControlLabel
                      value="free"
                      control={<Radio />}
                      label="Cortesía"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            )}
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={invoice}
                    onChange={handleInvoice}
                    name="invoice"
                  />
                }
                label="Factura"
                disabled={orderInfo.orders.length === 0 || saleEdit.isExternal}
              />
            </FormGroup>
            <Grid item xs={11}>
              <Typography variant="subtitle1">
                Total: ${orderInfo.total + taxes}
              </Typography>
            </Grid>
            {paymentMethod === "cash" && orderInfo.orders.length > 0 && (
              <Grid item xs={11}>
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item xs={8}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-amount">
                        Paga con
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-amount"
                        value={paymentCash}
                        onChange={(event) => setPaymentCash(event.target.value)}
                        startAdornment={
                          <InputAdornment position="start">$</InputAdornment>
                        }
                        labelWidth={70}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className={classes.textCenter} variant="body1">
                      Cambio: $
                      {(+paymentCash > orderInfo.total &&
                        +paymentCash - orderInfo.total) ||
                        0}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {saleEdit.isExternal && (
              <Grid item xs={11}>
                <TextField
                  variant="outlined"
                  label="Tiempo estimado de entrega"
                  id="deliveryTime"
                  name="deliveryTime"
                  onChange={(event) =>
                    setDeliveryTime(event.currentTarget.value)
                  }
                  defaultValue={deliveryTime}
                  fullWidth
                />
              </Grid>
            )}
            <Grid item xs={11}>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => history.push("/sales")}
                  >
                    Regresar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<SaveIcon />}
                    disabled={
                      orderInfo.orders.length === 0 ||
                      (!saleEdit.isExternal && table === "") ||
                      saleEdit.isExternal ||
                      +paymentCash > 0 ||
                      !can(
                        "SALES::UPDATE",
                        `LOCATIONS::${session.location?.id}`
                      )
                    }
                    onClick={() => save("on_progress")}
                  >
                    Guardar Orden
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<CheckCircleIcon />}
                    disabled={
                      orderInfo.orders.length === 0 ||
                      (!saleEdit.isExternal && table === "") ||
                      (paymentMethod === "cash" &&
                        +paymentCash < orderInfo.total) ||
                      !can(
                        "SALES::UPDATE",
                        `LOCATIONS::${session.location?.id}`
                      )
                    }
                    onClick={() => save("completed")}
                  >
                    Terminar Orden
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
