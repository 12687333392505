import { gql } from "@apollo/client";

export const CREATE_CATEGORY = gql`
  mutation createCategory($name: String!, $description: String) {
    createCategory(name: $name, description: $description) {
      status
      category {
        id
        name
        description
      }
      error {
        code
        message
      }
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation updateCategory($id: String!, $name: String!, $description: String) {
    updateCategory(id: $id, name: $name, description: $description) {
      status
      category {
        id
        description
        name
      }
      error {
        code
        message
      }
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation deleteCategory($id: String!) {
    deleteCategory(id: $id) {
      status
      message
      error {
        code
        message
      }
    }
  }
`;
