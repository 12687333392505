import React, { FC } from "react";

import { useParams } from "react-router";

import { ClientEditContent } from "../content/client-edit.content";
import { IdParams } from "../models/router/id.model";

export const ClientEditRoute: FC = (): JSX.Element => {
  const params: IdParams = useParams();
  return <ClientEditContent id={params.id} />;
};
