import React, { FC, useState, ChangeEvent } from "react";

import { Grid, Paper, TextField, Typography, Button } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";

import { ICategories } from "../../models/categories.model";
import { ICategoriesEdit } from "../../models/categories-edit.model";

export const CategoriesEditComponent: FC<ICategoriesEdit> = ({
  category,
  handleUpdate,
}): JSX.Element => {
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();
  const [categoryState, setCategoryState] = useState<ICategories>(category);
  const { name, description } = categoryState;

  const handleChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setCategoryState({
      ...categoryState,
      [event.target.name]: event.target.value,
    });
  };

  const handleClick = () => {
    handleUpdate(categoryState);
    enqueueSnackbar("Categoría modificada", {
      variant: "success",
      resumeHideDuration: 3000,
      anchorOrigin: { horizontal: "right", vertical: "bottom" },
    });
    history.push("/categories");
  };

  const goBack = () => {
    history.push("/categories");
  };

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} md={10} lg={8}>
        <Paper elevation={2}>
          <form>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Typography variant="h4">Editar Categoría</Typography>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  name="name"
                  id="name"
                  defaultValue={name}
                  label="Nombre"
                  variant="outlined"
                  required
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  type="text"
                  id="description"
                  name="description"
                  defaultValue={description}
                  label="Descripción"
                  variant="outlined"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={10}>
                <Grid container justifyContent="flex-end" spacing={2}>
                  <Grid item>
                    <Button
                      variant="contained"
                      size="small"
                      startIcon={<ArrowBackIcon />}
                      type="button"
                      onClick={() => goBack()}
                    >
                      Regresar
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      id="editCategory"
                      size="small"
                      startIcon={<CheckCircleIcon />}
                      type="submit"
                      onClick={handleClick}
                    >
                      Editar categoría
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};
