import React, { FC, useContext, useEffect, useState } from "react";

import {
  Grid,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  FormControlLabel,
  Checkbox,
  TextField,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  TableContainer,
  Tooltip,
  ListSubheader,
  ListItemIcon,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import RoomServiceIcon from "@material-ui/icons/RoomService";
import BlockIcon from "@material-ui/icons/Block";
import EditIcon from "@material-ui/icons/Edit";
import InfoIcon from "@material-ui/icons/Info";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PhoneIcon from "@material-ui/icons/Phone";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import HomeIcon from "@material-ui/icons/Home";
import ReceiptIcon from "@material-ui/icons/Receipt";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { useHistory } from "react-router";
import { useMutation, useQuery } from "@apollo/client";
import {
  fromUnixTime,
  format,
  startOfDay,
  endOfDay,
  getUnixTime,
} from "date-fns";

import { CANCEL_SALE } from "../../client/sales/mutation";
import { salesStatus, salesPayment } from "./sales.types";
import { GET_SALES } from "../../client/sales/queries";
import { Sale } from "../../models/sale.model";
import { GET_LOCATIONS } from "../../client/locations/queries";
import { SessionContext } from "../../context/session.context";
import { ILocation } from "../../models/location";
import { useStyles } from "./sales.styles";
import { Order } from "../../models/order.model";
import { can } from "../../utils/authorized.util";

export const SalesComponent: FC = (): JSX.Element => {
  const history = useHistory();

  const { location } = useContext(SessionContext);

  const [selectedDate, setDate] = useState<Date>(new Date());

  const [cancelSale] = useMutation(CANCEL_SALE);

  const classes = useStyles();

  const [modal, setModal] = useState<boolean>(false);
  const [modalSale, setModalSale] = useState<boolean>(false);
  const [selectedSale, setSelectedSale] = useState<Sale>();
  const [completedSales, setCompletedSales] = useState<boolean>(false);
  const [selectedLocation, setSelectedLocation] = useState<ILocation>();
  const [cancelReason, setCancelReason] = useState<string>("");

  const { data, loading } = useQuery(GET_SALES, {
    variables: {
      filters: JSON.stringify({
        location: selectedLocation?.id,
        from: getUnixTime(startOfDay(selectedDate)),
        to: getUnixTime(endOfDay(selectedDate)),
      }),
    },
    pollInterval: 1000 * 60,
    fetchPolicy: "network-only",
  });

  const locationsItems = useQuery(GET_LOCATIONS);

  const toltipLocations: JSX.Element = (
    <List
      subheader={
        <ListSubheader component="div" id="location-subheader" color="primary">
          Referencia de colores de sucursal
        </ListSubheader>
      }
    >
      {!locationsItems.loading &&
        locationsItems.data &&
        locationsItems.data.getLocations.locations.map(
          (location: ILocation) => (
            <ListItem key={location.id}>
              <ListItemIcon>
                <FiberManualRecordIcon
                  className={classes[`${location.color}Color`]}
                />
              </ListItemIcon>
              <ListItemText primary={location.name} />
            </ListItem>
          )
        )}
    </List>
  );

  useEffect(() => {
    const userLocation: ILocation =
      location &&
      locationsItems.data &&
      locationsItems.data.getLocations.locations.find(
        ({ id }: ILocation) => id === location?.id
      );
    setSelectedLocation(userLocation);
  }, []);

  const salesToDisplay: Sale[] =
    data && !loading
      ? completedSales
        ? data && data.getSales.sales
        : (data.getSales.sales &&
            data.getSales.sales.length > 0 &&
            data.getSales.sales.filter(
              (sale: Sale) => sale.status === "on_progress"
            )) ||
          []
      : [];

  const handleDelete = (id: string): void => {
    cancelSale({
      variables: {
        id,
        cancelDescription: cancelReason,
      },
      optimisticResponse: true,
      update: (cache, { data }) => {
        const existing: any = cache.readQuery({
          query: GET_SALES,
          variables: {
            filters: JSON.stringify({
              location: selectedLocation?.id,
              from: getUnixTime(startOfDay(selectedDate)),
              to: getUnixTime(endOfDay(selectedDate)),
            }),
          },
        });
        if (data) {
          if (existing && existing.getSales) {
            cache.writeQuery({
              query: GET_SALES,
              variables: {
                filters: JSON.stringify({
                  location: selectedLocation?.id,
                  from: getUnixTime(startOfDay(selectedDate)),
                  to: getUnixTime(endOfDay(selectedDate)),
                }),
              },
              data: {
                getSales: {
                  status: true,
                  error: null,
                  sales: existing.getSales.sales.map((sale: Sale) =>
                    sale.id === id
                      ? {
                          ...sale,
                          cancelDescription: cancelReason,
                          status: "canceled",
                        }
                      : sale
                  ),
                },
              },
            });
          }
        }
      },
    });
    setModal(false);
  };

  const handleLocationChange = (location: ILocation): void => {
    setSelectedLocation(location);
  };

  const handleWatchSale = (sale: Sale): void => {
    setSelectedSale(sale);
    setModalSale(true);
  };

  const handleChangeDate = (date: Date): void => {
    setDate(date);
  };

  const goToTicket = (saleId: string): void => {
    window.open(`${window.location.origin}/ticket?sale=${saleId}`, "_blank");
  };

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12} md={10} lg={8}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<RoomServiceIcon />}
            onClick={() => history.push("/sales/new")}
            disabled={!can("SALES::CREATE", `LOCATIONS::${location?.id}`)}
          >
            Nueva venta
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} md={10} lg={8}>
        <Paper elevation={0}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={12} lg={3}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      fullWidth
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date"
                      label="Fecha"
                      value={selectedDate}
                      onChange={(date) => handleChangeDate(date as Date)}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Autocomplete
                    id="locations"
                    loading={locationsItems.loading}
                    disableClearable={true}
                    options={
                      (locationsItems.data &&
                        locationsItems.data.getLocations.locations) ||
                      []
                    }
                    getOptionLabel={(option: ILocation) => option.name}
                    onChange={(event, value) =>
                      handleLocationChange(value as ILocation)
                    }
                    defaultValue={location}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Sucursal"
                        variant="outlined"
                      />
                    )}
                    disabled={!can("SALES::LIST", "LOCATIONS::*")}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={completedSales}
                        onChange={() => setCompletedSales(!completedSales)}
                        name="completed"
                        color="primary"
                      />
                    }
                    label="Mostrar ventas completadas"
                  />
                </Grid>
                <Grid item xs={12} lg={1}>
                  <Tooltip title={toltipLocations}>
                    <IconButton aria-label="info">
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              <Table aria-label="order" component={Paper}>
                <TableHead>
                  <TableRow>
                    <TableCell>Mesa</TableCell>
                    <TableCell align="right">Fecha</TableCell>
                    <TableCell align="right">Cantidad</TableCell>
                    <TableCell align="right">Estatus</TableCell>
                    <TableCell align="right">Acción</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading && (
                    <Typography variant="body1">Cargando...</Typography>
                  )}
                  {data &&
                    salesToDisplay?.map((sale: Sale, index: number) => (
                      <TableRow
                        key={index}
                        className={
                          (sale.isExternal &&
                            classes[
                              `${sale.externalOrigen?.color}Background`
                            ]) ||
                          ""
                        }
                      >
                        <TableCell component="th" scope="row">
                          {sale.table}
                        </TableCell>
                        <TableCell align="right">
                          {format(fromUnixTime(sale.date), "dd/MM/yyyy H:mm")}
                        </TableCell>
                        <TableCell align="right">${sale.total}</TableCell>
                        <TableCell align="right">
                          <Typography
                            color={
                              (sale.isExternal &&
                                sale.status === "on_progress" &&
                                "secondary") ||
                              (sale.isExternal &&
                                sale.status === "completed" &&
                                "primary") ||
                              "inherit"
                            }
                          >
                            {
                              salesStatus[
                                sale.status as keyof typeof salesStatus
                              ]
                            }
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Grid container justifyContent="flex-end" spacing={1}>
                            <Grid item>
                              <IconButton
                                aria-label="watch"
                                onClick={() => handleWatchSale(sale)}
                                disabled={
                                  !can(
                                    "SALES::DETAILS",
                                    `LOCATIONS::${location?.id}`
                                  )
                                }
                              >
                                <InfoIcon />
                              </IconButton>
                            </Grid>
                            {sale.status === "on_progress" && (
                              <Grid item>
                                <IconButton
                                  aria-label="edit"
                                  onClick={() =>
                                    history.push(`/sales/${sale.id}/edit`)
                                  }
                                  disabled={
                                    !can(
                                      "SALES::UPDATE",
                                      `LOCATIONS::${location?.id}`
                                    )
                                  }
                                >
                                  <EditIcon />
                                </IconButton>
                              </Grid>
                            )}
                            <Grid item>
                              <IconButton
                                aria-label="delete"
                                onClick={() => {
                                  setSelectedSale(sale);
                                  setModal(true);
                                }}
                                disabled={
                                  !can(
                                    "SALES::DELETE",
                                    `LOCATIONS::${location?.id}`
                                  ) || sale.status !== "on_progress"
                                }
                              >
                                <BlockIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ))}
                  {data && salesToDisplay?.length === 0 && (
                    <Typography variant="body1" align="center" paragraph>
                      No hay elementos
                    </Typography>
                  )}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Dialog
        open={modal}
        onClose={() => setModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Eliminar venta</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Realmente deseas eliminar esta venta? Una vez eliminado no podrás
            recuperarlo
          </DialogContentText>
          <TextField
            type="text"
            variant="outlined"
            label="Razón de cancelación"
            placeholder="Escribe aquí la razón de la cancelación"
            fullWidth
            multiline
            minRows="3"
            onChange={(event) => setCancelReason(event.currentTarget.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModal(false)}>Cancelar</Button>
          <Button
            onClick={() =>
              handleDelete((selectedSale && (selectedSale.id as string)) || "")
            }
            color="secondary"
            autoFocus
            disabled={!cancelReason}
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={modalSale}
        onClose={() => setModalSale(false)}
        aria-labelledby="sale-modal-title"
        aria-describedby="sale-modal-description"
      >
        <DialogTitle id="sale-modal-title">Venta</DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12}>
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <Typography variant="body1">
                    Fecha:{" "}
                    {selectedSale &&
                      `${format(
                        fromUnixTime(selectedSale.date),
                        "dd/MM/yyyy H:mm"
                      )}`}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1">
                    Estatus:{" "}
                    {selectedSale &&
                      `${
                        salesStatus[
                          selectedSale.status as keyof typeof salesStatus
                        ]
                      }`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {selectedSale?.client && (
              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Información del cliente</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <List>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <AccountCircleIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={selectedSale?.client?.name} />
                      </ListItem>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <PhoneIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={selectedSale?.client?.phone} />
                      </ListItem>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <HomeIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={selectedSale?.client?.address} />
                      </ListItem>
                      {selectedSale?.client?.alternativeAddress && (
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>
                              <HomeIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={selectedSale?.client?.alternativeAddress}
                          />
                        </ListItem>
                      )}
                    </List>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            )}
            <Grid item>
              <Grid container spacing={2}>
                <Grid item>
                  <Typography variant="body1" align="center">
                    {`Método de pago: ${
                      salesPayment[
                        selectedSale?.paymentMethod as keyof typeof salesPayment
                      ]
                    }`}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" align="center">
                    {`Mesa: ${selectedSale?.table}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {selectedSale?.isExternal && (
              <Grid item>
                <Typography variant="body1" align="center" color="secondary">
                  {`Venta enviada desde la sucursal ${selectedSale.externalOrigen?.name}`}
                </Typography>
              </Grid>
            )}
            <Grid item>
              <Typography variant="body1" align="center">
                Total: ${selectedSale?.total}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                startIcon={<ReceiptIcon />}
                onClick={() => goToTicket(selectedSale?.id as string)}
                disabled={selectedSale?.status !== "completed"}
              >
                Ticket
              </Button>
            </Grid>
            <Grid item>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="order-panel-content"
                  id="order-panel-header"
                >
                  <Typography>Productos de la venta</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer>
                    <Table aria-label="Productos">
                      <TableHead>
                        <TableRow>
                          <TableCell>Nombre</TableCell>
                          <TableCell>Precio</TableCell>
                          <TableCell>Cantidad</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedSale?.orders.map(
                          (order: Order, index: number) => (
                            <TableRow key={index}>
                              <TableCell component="th" scope="row">
                                {`${order.product.category.name} - ${order.product.name}`}
                              </TableCell>
                              <TableCell align="right">
                                ${order.product.price}
                              </TableCell>
                              <TableCell align="right">
                                {order.quantity}
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalSale(false)}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
