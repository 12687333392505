import { PolicyNewRoute } from "./pages/policy-new.route";
import { PoliciesRoute } from "./pages/policies.route";

import { LocationEditRoute } from "./pages/location-edit.route";
import { LocationWatchRoute } from "./pages/location-watch.route";
import { IRoute } from "./models/route.model";
import { LocationsRoute } from "./pages/locations.route";
import { LoginRoute } from "./pages/login.route";
import { MainRoute } from "./pages/main.route";
import { PolicyEditRoute } from "./pages/policy-edit.route";
import { SalesRoute } from "./pages/sales.route";
import { SaleEditRoute } from "./pages/sale-edit.route";
import { SaleCreateRoute } from "./pages/sale-create.route";
import { ClientsRoute } from "./pages/clients.route";
import { ClientEditRoute } from "./pages/client-edit.route";
import { ClientCreateRoute } from "./pages/client-create.route";
import { SaleCutCreateRoute } from "./pages/sale-cut-create.route";
import { RolesRoute } from "./pages/roles.route";
import { RoleCreateRoute } from "./pages/role-create.route";
import { RoleEditRoute } from "./pages/role-edit.route";
import { LocationCreateRoute } from "./pages/location-create.route";
import { SalesCutsRoute } from "./pages/sales-cuts.route";
import { TicketRoute } from "./pages/ticket.route";
import { ResetPasswordRoute } from "./pages/reset-password.route";
import { ProfileRoute } from "./pages/profile.route";
import { ExpensesRoute } from "./pages/expenses.route";
import { ExpenseCreateRoute } from "./pages/expense-create.route";
import { ExpenseEditRoute } from "./pages/expense-edit.route";

//Categories Routes
import { CategoriesRoute } from "./pages/categories.route";
import { CategoriesCreateRoute } from "./pages/categories-create.route";
import { CategoriesEditRoute } from "./pages/categories-edit.route";

export const ROUTES: IRoute[] = [
  {
    path: "/",
    exact: true,
    key: "LOGIN",
    component: LoginRoute,
  },
  {
    path: "/main",
    exact: false,
    key: "MAIN",
    component: MainRoute,
  },
  {
    path: "/locations",
    exact: true,
    key: "LOCATIONS",
    component: LocationsRoute,
    policy: "LOCATIONS::LIST",
  },
  {
    path: "/locations/new",
    exact: false,
    key: "LOCATIONS_CREATE",
    component: LocationCreateRoute,
  },
  {
    path: "/locations/:id",
    exact: true,
    key: "LOCATIONS:WATCH",
    component: LocationWatchRoute,
    policy: "LOCATIONS::DETAILS",
  },
  {
    path: "/locations/:id/edit",
    exact: false,
    key: "LOCATIONS:EDIT",
    component: LocationEditRoute,
    policy: "LOCATIONS::UPDATE",
  },
  {
    path: "/policies",
    exact: true,
    key: "POLICIES",
    component: PoliciesRoute,
    policy: "POLICIES::LIST",
  },
  {
    path: "/policies/new",
    exact: false,
    key: "POLICIES",
    component: PolicyNewRoute,
    policy: "POLICIES::CREATE",
  },
  {
    path: "/policies/:id/edit",
    exact: false,
    key: "POLICIES",
    component: PolicyEditRoute,
    policy: "POLICIES::UPDATE",
  },
  {
    path: "/sales",
    exact: true,
    key: "SALES",
    component: SalesRoute,
    policy: "SALES::LIST",
  },
  {
    path: "/sales/:id/edit",
    exact: true,
    key: "SALES_EDIT",
    component: SaleEditRoute,
    policy: "SALES::UPDATE",
  },
  {
    path: "/sales/new",
    exact: false,
    key: "SALES_NEW",
    component: SaleCreateRoute,
    policy: "SALES::CREATE",
  },
  {
    path: "/clients",
    exact: true,
    key: "CLIENTS",
    component: ClientsRoute,
    policy: "CLIENTS::LIST",
  },
  {
    path: "/clients/:id/edit",
    exact: false,
    key: "CLIENT_EDIT",
    component: ClientEditRoute,
    policy: "CLIENTS::UPDATE",
  },
  {
    path: "/clients/new",
    exact: false,
    key: "CLIENTS_NEW",
    component: ClientCreateRoute,
    policy: "CLIENTS::CREATE",
  },
  {
    path: "/roles",
    exact: true,
    key: "ROLES",
    component: RolesRoute,
    policy: "ROLES::LIST",
  },
  {
    path: "/roles/new",
    exact: false,
    key: "ROLES_NEW",
    component: RoleCreateRoute,
    policy: "ROLES::CREATE",
  },
  {
    path: "/roles/:id/edit",
    exact: false,
    key: "ROLES_EDIT",
    component: RoleEditRoute,
    policy: "ROLES::UPDATE",
  },
  {
    path: "/sales-cuts",
    exact: true,
    key: "SALES_CUT",
    component: SalesCutsRoute,
    policy: "CUTOFF::LIST",
  },
  {
    path: "/sales-cuts/new",
    exact: false,
    key: "CORTE",
    component: SaleCutCreateRoute,
    policy: "CUTOFF::CREATE",
  },
  {
    path: "/ticket",
    exact: false,
    key: "TICKET",
    component: TicketRoute,
  },
  {
    path: "/reset-password",
    exact: false,
    key: "RESET-PASSWORD",
    component: ResetPasswordRoute,
  },
  {
    path: "/profile",
    exact: false,
    key: "PROFILE",
    component: ProfileRoute,
  },
  {
    path: "/expenses",
    exact: true,
    key: "EXPENSES",
    component: ExpensesRoute,
  },
  {
    path: "/expenses/new",
    exact: false,
    key: "EXPENSES-CREATE",
    component: ExpenseCreateRoute,
  },
  {
    path: "/expenses/:date/edit",
    exact: false,
    key: "EXPENSES-EDIT",
    component: ExpenseEditRoute,
  },
  {
    path: "/categories/new",
    exact: false,
    key: "CATEGORIES-NEW",
    component: CategoriesCreateRoute,
  },
  {
    path: "/categories/:id/edit",
    exact: false,
    key: "CATEGORIES-EDIT",
    component: CategoriesEditRoute,
  },
  {
    path: "/categories",
    exact: false,
    key: "CATEGORIES",
    component: CategoriesRoute,
  },
];
