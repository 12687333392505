import React, { ChangeEvent, FC, useState } from "react";

import {
  Grid,
  Paper,
  TextField,
  Button,
  Typography,
  FormControl,
  InputLabel,
  InputAdornment,
  OutlinedInput,
} from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";
import { getUnixTime, fromUnixTime, endOfDay, startOfDay } from "date-fns";

import { Expense } from "../../models/expense.model";
import { IExpensesEdit } from "../../models/components/expense-edit.model";
import { GET_EXPENSE, GET_EXPENSES } from "../../client/expenses/queries";
import { notNumber } from "../../utils/not-number.util";
import { UPDATE_EXPENSE } from "../../client/expenses/mutations";
import { can } from "../../utils/authorized.util";

export const ExpenseEditComponent: FC<IExpensesEdit> = ({
  expense,
}): JSX.Element => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [updateExpense] = useMutation(UPDATE_EXPENSE);

  const [expenseItem, setExpenseItem] = useState<Expense>(expense);

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const { name, value } = event.currentTarget;
    setExpenseItem({
      ...expenseItem,
      [name]: value,
    });
  };

  const getTotal = (): number => {
    return +expenseItem.quantity * +expenseItem.price;
  };

  const save = () => {
    if (notNumber(expenseItem.price) || notNumber(expenseItem.quantity)) {
      enqueueSnackbar("Precio y cantidad deben ser números", {
        variant: "warning",
        resumeHideDuration: 5000,
        anchorOrigin: { horizontal: "right", vertical: "bottom" },
      });
      return;
    }
    const toSave = {
      ...expenseItem,
      location: expense.location?.id,
      quantity: +expenseItem.quantity,
      price: +expenseItem.price,
    };
    updateExpense({
      variables: {
        ...toSave,
      },
      optimisticResponse: true,
      update: (cache, { data }) => {
        const existing: any = cache.readQuery({
          query: GET_EXPENSE,
          variables: {
            location: expense.location?.id,
            date: expense.date,
          },
        });
        const existingExpenses: any = cache.readQuery({
          query: GET_EXPENSES,
          variables: {
            filters: JSON.stringify({
              location: expense.location?.id,
              from: getUnixTime(
                startOfDay(fromUnixTime(expense.date as number))
              ),
              to: getUnixTime(endOfDay(fromUnixTime(expense.date as number))),
            }),
          },
        });
        if (existing && existing.getExpense && data.updateExpense) {
          const updateItem: Expense = data.updateExpense.expense;
          cache.writeQuery({
            query: GET_EXPENSE,
            variables: {
              location: expense.location?.id,
              date: expense.date,
            },
            data: {
              getExpense: {
                error: null,
                status: true,
                expense: updateItem,
              },
            },
          });
          cache.writeQuery({
            query: GET_EXPENSES,
            variables: {
              filters: JSON.stringify({
                location: expense.location?.id,
                from: getUnixTime(
                  startOfDay(fromUnixTime(expense.date as number))
                ),
                to: getUnixTime(endOfDay(fromUnixTime(expense.date as number))),
              }),
            },
            data: {
              getExpenses: {
                error: null,
                status: true,
                expenses: existingExpenses.getExpenses.expenses.map(
                  (expense: Expense) =>
                    expense?.date === updateItem?.date ? updateItem : expense
                ),
              },
            },
          });
        }
      },
    });
    enqueueSnackbar("Gasto editado", {
      variant: "success",
      resumeHideDuration: 3000,
      anchorOrigin: { horizontal: "right", vertical: "bottom" },
    });
    history.push("/expenses");
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} lg={8}>
        <Paper>
          <Grid container justifyContent="center" spacing={1}>
            <Grid item xs={10}>
              <Typography variant="h3" align="center">
                Editar gasto
              </Typography>
            </Grid>
            <Grid item xs={11} lg={7}>
              <TextField
                id="product"
                name="product"
                label="Producto"
                onChange={handleChange}
                fullWidth
                variant="outlined"
                defaultValue={expenseItem.product}
              />
            </Grid>
            <Grid item xs={11} lg={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="price">Precio</InputLabel>
                <OutlinedInput
                  id="price"
                  name="price"
                  onChange={handleChange}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                  labelWidth={60}
                  defaultValue={expenseItem.price}
                  inputProps={{
                    inputProps: { min: 1 },
                    inputMode: "numeric",
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={11} lg={3}>
              <TextField
                id="quantity"
                name="quantity"
                label="Cantidad"
                onChange={handleChange}
                defaultValue={expenseItem.quantity}
                inputProps={{
                  inputProps: { min: 1 },
                  inputMode: "numeric",
                }}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={11} lg={7}>
              <TextField
                id="description"
                name="description"
                label="Descripción"
                onChange={handleChange}
                minRows={1}
                multiline
                fullWidth
                variant="outlined"
                defaultValue={expenseItem.description}
              />
            </Grid>
            <Grid item xs={11}>
              <Typography variant="h6" align="right">
                Total: ${getTotal()}
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Grid container justifyContent="flex-end" spacing={1}>
                <Grid item>
                  <Button variant="contained" onClick={history.goBack}>
                    Cancelar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={save}
                    disabled={
                      !can(
                        "EXPENSES::EDIT",
                        `LOCATIONS::${expense.location?.id}`
                      )
                    }
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
