import React, { FC } from "react";

import { useQuery } from "@apollo/client";
import { useParams } from "react-router";

import { RoleEditContent } from "../content/role-edit.content";
import { GET_ROLE } from "../client/roles/queries";
import { BackdropComponent } from "../components/commons/backdrop/brackdrop.component";

export const RoleEditRoute: FC = (): JSX.Element => {
  const { id }: { id: string } = useParams();

  const { loading, data } = useQuery(GET_ROLE, {
    variables: {
      id,
    },
  });

  return loading ? (
    <BackdropComponent />
  ) : (
    <RoleEditContent {...data.getRole.role} />
  );
};
