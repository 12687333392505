import React, { FC, useState, useEffect, useContext } from "react";
import { Route, useHistory } from "react-router-dom";

import { has } from "../../../utils/authorized.util";
import { SessionContext } from "../../../context/session.context";
import { retrieveSession } from "../../../utils/retrieve-session.util";
import { Session } from "../../../models/session.model";
import { IProtectedRoute } from "../../../models/components/protected-route.model";
import { BackdropComponent } from "../backdrop/brackdrop.component";

export const ProtectedRoute: FC<IProtectedRoute> = ({
  policy,
  component,
  path,
  exact,
}): JSX.Element => {
  const history = useHistory();

  const [authorized, setAuthorized] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(true);

  const { logged, handleSession, handleLocation } = useContext(SessionContext);

  useEffect(() => {
    const isAuthorized = async (): Promise<void> => {
      const can = await has(policy);
      setAuthorized(can);
      setFetching(false);
    };

    const fetchingSession = async (): Promise<void> => {
      const session: Session = await retrieveSession();
      if (session.logged) {
        handleSession(session);
        session.user.defaultLocation &&
          handleLocation(session.user.defaultLocation);
      }
    };

    if (!logged) {
      fetchingSession().then(() => isAuthorized());
    } else {
      isAuthorized();
    }
  }, [policy]);

  const redirect = (): void => {
    setFetching(true);
    history.push("/");
  };

  return fetching ? (
    <BackdropComponent />
  ) : authorized ? (
    <Route path={path} exact={exact} component={component} />
  ) : (
    // eslint-disable-next-line react/no-unknown-property
    <div onLoad={redirect() as any}>Protected</div>
  );
};
