import React, { useContext, useState, useEffect, ChangeEvent } from "react";

import { useQuery, useLazyQuery } from "@apollo/client";
import {
  Grid,
  Paper,
  Typography,
  TextField,
  TablePagination,
  Button,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useHistory } from "react-router";
import PrintIcon from "@material-ui/icons/Print";
import {
  fromUnixTime,
  getMonth,
  getYear,
  getDate,
  getUnixTime,
} from "date-fns";

import { GET_LOCATIONS } from "../../client/locations/queries";
import { ILocation } from "../../models/location";
import { SessionContext } from "../../context/session.context";
import { useStyles } from "./sales-cuts.styles";
import { GET_TICKETS } from "../../client/sales/queries";
import { BackdropComponent } from "../commons/backdrop/brackdrop.component";
import { MONTHS } from "./sales-cuts.types";
import { Ticket } from "../../models/ticket.model";
import { can } from "../../utils/authorized.util";

export const SalesCutsComponent = () => {
  const session = useContext(SessionContext);

  const classes = useStyles();

  const history = useHistory();

  const [selectedLocation, setSelectedLocation] = useState<ILocation>();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const locationsItems = useQuery(GET_LOCATIONS);

  const [getTickets, ticketsItems] = useLazyQuery(GET_TICKETS);

  useEffect(() => {
    if (session.location) {
      setSelectedLocation(session.location);
      getTickets({
        variables: {
          filters: JSON.stringify({
            location: session.location.id,
            requestedAt: getUnixTime(new Date()),
          }),
        },
      });
    }
  }, []);

  const handleChangeLocation = (location: ILocation): void => {
    setSelectedLocation(location);
    getTickets({
      variables: {
        filters: JSON.stringify({
          location: location.id,
          requestedAt: getUnixTime(new Date()),
        }),
      },
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const obtainTicketHeader = (date: number): string => {
    const fullDate: Date = fromUnixTime(date);
    const month: number = getMonth(fullDate);
    const year: number = getYear(fullDate);
    return `${MONTHS[month as keyof typeof MONTHS]} ${year}`;
  };

  const handleSelectTicket = (ticket: Ticket): void => {
    window.open(
      `${window.location.origin}/ticket?location=${ticket?.location?.id}&date=${ticket.date}`,
      "_blank"
    );
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={8}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<PrintIcon />}
              onClick={() => history.push("sales-cuts/new")}
              disabled={
                !can("CUTOFF::CREATE", `LOCATIONS::${selectedLocation?.id}`)
              }
            >
              Generar corte
            </Button>
          </Grid>
          <Grid item>
            <Paper elevation={2}>
              <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h3" align="center">
                    Cortes de ventas
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Autocomplete
                    id="location"
                    loading={locationsItems.loading}
                    loadingText="Cargando..."
                    options={
                      (locationsItems.data &&
                        locationsItems.data.getLocations.locations) ||
                      []
                    }
                    getOptionLabel={(option: ILocation) => option.name}
                    onChange={(event, value) => handleChangeLocation(value)}
                    defaultValue={selectedLocation || session.location}
                    fullWidth
                    disableClearable={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Sucursal"
                        variant="outlined"
                      />
                    )}
                    disabled={!can("CUTOFF::LIST", "LOCATIONS::*")}
                  />
                </Grid>
                <Grid item xs={10}>
                  {ticketsItems.loading ? (
                    <BackdropComponent />
                  ) : (
                    <div className={classes.gridDates}>
                      {ticketsItems.data &&
                        ticketsItems.data.getTickets.tickets
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((ticket: Ticket, index: number) => (
                            <div
                              className={classes.gridDate}
                              key={index}
                              onClick={() =>
                                can(
                                  "CUTOFF::DETAILS",
                                  `LOCATIONS::${selectedLocation?.id}`
                                ) && handleSelectTicket(ticket)
                              }
                            >
                              <div className={classes.headerDate}>
                                <Typography variant="overline" align="center">
                                  {obtainTicketHeader(ticket.date)}
                                </Typography>
                              </div>
                              <div className={classes.bodyDate}>
                                <Typography variant="h6" align="center">
                                  {getDate(fromUnixTime(ticket.to))}
                                </Typography>
                              </div>
                            </div>
                          ))}
                    </div>
                  )}
                </Grid>
                <Grid item xs={10}>
                  <TablePagination
                    rowsPerPageOptions={[10, 15, 30, 50, 100]}
                    component="div"
                    count={
                      (ticketsItems.data &&
                        ticketsItems.data.getTickets.tickets.length) ||
                      0
                    }
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
