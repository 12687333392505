import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  gridDates: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(70px, 1fr))",
    gap: "10px",
  },
  gridDate: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "5px",
    border: "1px solid #B4B2B2",
    cursor: "pointer",
  },
  headerDate: {
    backgroundColor: "#FF463C",
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  bodyDate: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "50px",
  },
  dialogPrint: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    margin: "-30px",
  },
});
