import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    cursor: "pointer",
  },
  marginBottom: {
    marginBottom: "15px",
  },
  logo: {
    height: "40px",
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  whiteButton: {
    color: "white",
  },
  select: {
    background: "none",
    border: "none",
  },
}));
