import React, { FC, useState } from "react";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Grid, Button, Dialog, Typography } from "@material-ui/core";
import { useHistory } from "react-router";

import { CustomTableComponent } from "../commons/custom-table/custom-table.component";
import { TableHandlers } from "../../models/table-handlers.model";
import { TableHeader } from "../../models/components/table-header.model";
import { IPolicies } from "../../models/components/policies.model";
import { useStyles } from "./policies.styles";
import { can } from "../../utils/authorized.util";

export const PoliciesComponent: FC<IPolicies> = ({
  policies,
  deletePolicy,
}): JSX.Element => {
  const history = useHistory();

  const classes = useStyles();

  const [modal, setModal] = useState<boolean>(false);
  const [selectedPolicy, setSelectedPolicy] = useState<string>();

  const tableHeaders: TableHeader[] = [
    {
      display: "Nombre",
      field: "name",
    },
    {
      display: "Efecto",
      field: "effect",
    },
  ];

  const handleEdit = (id: string) => history.push(`/policies/${id}/edit`);
  const handleWatch = handleEdit;

  const handleDelete = (id: string): void => {
    setSelectedPolicy(id);
    setModal(true);
  };

  const handlers: TableHandlers = {
    delete: (id: string) =>
      can("POLICIES::DELETE", "POLICIES::*") && handleDelete(id),
    edit: (id: string) =>
      can("POLICIES::EDIT", `POLICIES::${id}`) && handleEdit(id),
    watch: (id: string) =>
      can("POLICIES::DETAILS", `POLICIES::${id}`) && handleWatch(id),
  };

  const handleCloseModal = (): void => {
    setModal(false);
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={8}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<AddCircleIcon />}
              onClick={() => history.push("policies/new")}
              disabled={!can("POLICIES::CREATE", "POLICIES::*")}
            >
              Agregar política
            </Button>
          </Grid>
          <Grid item>
            <CustomTableComponent
              data={policies}
              tableHeaders={tableHeaders}
              optionsTable={true}
              handlers={handlers}
            />
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={modal}
        onClose={handleCloseModal}
        aria-labelledby="Eliminar politica"
        aria-describedby="Eliminar politica"
      >
        <Grid container spacing={2} className={classes.dialog}>
          <Grid item>
            <Typography variant="h3">Eliminar política</Typography>
            <p>¿Deseas eliminar la política {selectedPolicy}?</p>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button variant="contained" onClick={handleCloseModal}>
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    deletePolicy(selectedPolicy as string);
                    handleCloseModal();
                  }}
                >
                  Eliminar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </Grid>
  );
};
