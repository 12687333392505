import { makeStyles } from "@material-ui/core";
import {
  red,
  pink,
  purple,
  blue,
  green,
  lime,
  yellow,
  amber,
  orange,
} from "@material-ui/core/colors";

export const useStyles = makeStyles({
  icon: {
    fontSize: "5em",
  },
  cursor: {
    cursor: "pointer",
  },
  external: {
    backgroundColor: yellow[50],
  },
  redColor: {
    color: red[500],
  },
  pinkColor: {
    color: pink[500],
  },
  purpleColor: {
    color: purple[500],
  },
  blueColor: {
    color: blue[500],
  },
  greenColor: {
    color: green[500],
  },
  limeColor: {
    color: lime[500],
  },
  yellowColor: {
    color: yellow[500],
  },
  amberColor: {
    color: amber[500],
  },
  orangeColor: {
    color: orange[500],
  },
  redBackground: {
    backgroundColor: red[50],
  },
  pinkBackground: {
    backgroundColor: pink[50],
  },
  purpleBackground: {
    backgroundColor: purple[50],
  },
  blueBackground: {
    backgroundColor: blue[50],
  },
  greenBackground: {
    backgroundColor: green[50],
  },
  limeBackground: {
    backgroundColor: lime[50],
  },
  yellowBackground: {
    backgroundColor: yellow[50],
  },
  amberBackground: {
    backgroundColor: amber[50],
  },
  orangeBackground: {
    backgroundColor: orange[50],
  },
});
