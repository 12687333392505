import { gql } from "@apollo/client";

export const CREATE_LOCATION = gql`
  mutation createLocation(
    $name: String!
    $phone: String!
    $address: String!
    $letter: String!
    $color: String!
  ) {
    createLocation(
      name: $name
      phone: $phone
      address: $address
      letter: $letter
      color: $color
    ) {
      status
      location {
        id
        name
        phone
        address
        enable
        letter
        color
      }
      error {
        code
        message
      }
    }
  }
`;

export const UPDATE_LOCATION = gql`
  mutation UpdateLocation(
    $id: String!
    $phone: String!
    $name: String!
    $address: String!
    $enable: Boolean!
    $letter: String!
    $color: String!
  ) {
    updateLocation(
      id: $id
      phone: $phone
      name: $name
      address: $address
      enable: $enable
      letter: $letter
      color: $color
    ) {
      status
      location {
        id
        name
        address
        enable
        phone
        letter
        color
      }
      error {
        code
        message
      }
    }
  }
`;

export const DELETE_LOCATION = gql`
  mutation deleteLocation($id: String!) {
    deleteLocation(id: $id) {
      status
      message
      error {
        code
        message
      }
    }
  }
`;
