import { Auth } from "aws-amplify";
import { Session } from "../models/session.model";
import { User } from "../models/user.model";
import { handleCurrentUser } from "./current-user.util";

export const handleAuth = async (
  username: string,
  password: string
): Promise<Session> => {
  try {
    const userObject = await Auth.signIn(username, password);
    const userId: string = userObject.attributes.sub;
    if (!userObject.challengeName) {
      const user: User = await handleCurrentUser(userId);
      return {
        logged: true,
        userId,
        user,
        userObject,
      };
    } else {
      return {
        logged: false,
        userId,
        user: {} as User,
        userObject,
      };
    }
  } catch {
    return {
      logged: false,
      userId: "",
      user: {} as User,
      userObject: {},
    };
  }
};
