import React, { FC } from "react";

import { useQuery, useMutation } from "@apollo/client";

import { ClientEditComponent } from "../components/client-edit/client-edit.component";

import { IdParams } from "../models/router/id.model";
import { GET_CLIENT, GET_CLIENTS } from "../client/clients/queries";
import { Client } from "../models/client.model";
import { UPDATE_CLIENT } from "../client/clients/mutation";
import { BackdropComponent } from "../components/commons/backdrop/brackdrop.component";

export const ClientEditContent: FC<IdParams> = ({ id }): JSX.Element => {
  const [updateClient] = useMutation(UPDATE_CLIENT);

  const { loading, data } = useQuery(GET_CLIENT, {
    variables: {
      id,
    },
  });

  const handleEdit = (client: Client): void => {
    updateClient({
      variables: {
        ...client,
      },
      optimisticResponse: true,
      update: (cache, { data }) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const existing: any = cache.readQuery({
          query: GET_CLIENTS,
          variables: {
            filters: "",
          },
        });
        if (data.updateClient) {
          if (existing && existing.getClients) {
            const updateItem: Client = data.updateClient.client;
            cache.writeQuery({
              query: GET_CLIENTS,
              variables: {
                filters: "",
              },
              data: {
                status: true,
                getClients: existing.getClients.clients.map((client: Client) =>
                  client.id === updateItem.id ? updateItem : client
                ),
                error: null,
              },
            });
            cache.writeQuery({
              query: GET_CLIENT,
              variables: {
                id: updateItem.id,
              },
              data: {
                getClient: {
                  status: true,
                  client: updateItem,
                  error: null,
                },
              },
            });
          }
        }
      },
    });
  };

  return loading ? (
    <BackdropComponent />
  ) : (
    <ClientEditComponent
      client={data.getClient.client}
      handleEdit={(client) => handleEdit(client)}
    />
  );
};
