import React, { FC, useState, ChangeEvent } from "react";

import {
  TextField,
  FormControl,
  Button,
  Paper,
  Grid,
  Typography,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { useHistory } from "react-router";
import { useMutation } from "@apollo/client";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

import { ILocation } from "../../models/location";
import { CREATE_LOCATION } from "../../client/locations/mutation";
import { GET_LOCATIONS } from "../../client/locations/queries";
import { can } from "../../utils/authorized.util";
import { useStyles } from "./location-create.styles";

export const LocationCreateComponent: FC = (): JSX.Element => {
  const history = useHistory();

  const classes = useStyles();

  const [createLocation] = useMutation(CREATE_LOCATION);

  const [locationState, setLocationState] = useState<ILocation>({
    address: "",
    enable: true,
    name: "",
    phone: "",
    id: "",
    letter: "",
    color: "",
  });

  const handleChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setLocationState({
      ...locationState,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const handleChangeColor = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ): void => {
    const { value } = event.target;
    setLocationState({
      ...locationState,
      color: `${value}` as string,
    });
  };

  const handleClick = () => {
    const { name, address, phone, color, letter } = locationState;
    createLocation({
      variables: {
        name,
        address,
        phone,
        color,
        letter,
      },
      optimisticResponse: true,
      update: (cache, { data }) => {
        const existing: any = cache.readQuery({
          query: GET_LOCATIONS,
        });
        if (existing && existing.getLocations && data.createLocation) {
          const createItem: ILocation = data.createLocation.location;
          cache.writeQuery({
            query: GET_LOCATIONS,
            data: {
              getLocations: {
                locations: [createItem, ...existing.getLocations.locations],
              },
            },
          });
        }
      },
    });
    history.push("/locations");
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={8}>
        <Paper elevation={2}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h3" align="center">
                Nueva sucursal
              </Typography>
            </Grid>
            <Grid item>
              <FormControl>
                <Grid container justifyContent="center" spacing={2}>
                  <Grid item xs={10}>
                    <TextField
                      fullWidth
                      name="name"
                      id="outlined-basic"
                      label="Nombre de la sucursal"
                      variant="outlined"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      fullWidth
                      name="address"
                      id="outlined-basic"
                      label="Dirección"
                      variant="outlined"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      fullWidth
                      name="phone"
                      id="outlined-basic"
                      label="Teléfono"
                      variant="outlined"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <Grid container justifyContent="center" spacing={2}>
                      <Grid item xs={12} md={4}>
                        <TextField
                          fullWidth
                          name="letter"
                          id="letter"
                          label="Letra identificadora"
                          variant="outlined"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel id="color-select">Color</InputLabel>
                          <Select
                            labelId="color-select"
                            id="color"
                            onChange={handleChangeColor}
                            label="Color"
                            fullWidth
                          >
                            <MenuItem value="red">
                              <FiberManualRecordIcon
                                className={classes.redColor}
                              />
                              Rojo
                            </MenuItem>
                            <MenuItem value="pink">
                              <FiberManualRecordIcon
                                className={classes.pinkColor}
                              />
                              Rosa
                            </MenuItem>
                            <MenuItem value="purple">
                              <FiberManualRecordIcon
                                className={classes.purpleColor}
                              />
                              Morado
                            </MenuItem>
                            <MenuItem value="blue">
                              <FiberManualRecordIcon
                                className={classes.blueColor}
                              />
                              Azul
                            </MenuItem>
                            <MenuItem value="green">
                              <FiberManualRecordIcon
                                className={classes.greenColor}
                              />
                              Verde
                            </MenuItem>
                            <MenuItem value="lime">
                              <FiberManualRecordIcon
                                className={classes.limeColor}
                              />
                              Lima
                            </MenuItem>
                            <MenuItem value="yellow">
                              <FiberManualRecordIcon
                                className={classes.yellowColor}
                              />
                              Amarillo
                            </MenuItem>
                            <MenuItem value="amber">
                              <FiberManualRecordIcon
                                className={classes.amberColor}
                              />
                              Dorado
                            </MenuItem>
                            <MenuItem value="orange">
                              <FiberManualRecordIcon
                                className={classes.orangeColor}
                              />
                              Naranja
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={10}>
                    <Grid container justifyContent="flex-end" spacing={2}>
                      <Grid item>
                        <Button
                          variant="contained"
                          key="one"
                          onClick={() => history.goBack()}
                        >
                          Regresar
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          key="two"
                          type="button"
                          color="primary"
                          onClick={handleClick}
                          disabled={!can("LOCATIONS::CREATE", "LOCATIONS::*")}
                        >
                          Actualizar
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
