import { useQuery } from "@apollo/client";
import React, { FC } from "react";
import { GET_POLICIES } from "../client/policies/queries";
import { BackdropComponent } from "../components/commons/backdrop/brackdrop.component";
import { PoliciesContent } from "../content/policies.content";

export const PoliciesRoute: FC = (): JSX.Element => {
  const policies = useQuery(GET_POLICIES, {
    variables: { filters: "" },
  });
  return policies.loading ? (
    <BackdropComponent />
  ) : (
    <PoliciesContent policies={policies.data.getPolicies.policies || []} />
  );
};
