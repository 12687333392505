import { Auth } from "aws-amplify";
import { Session } from "../models/session.model";
import { User } from "../models/user.model";
import { handleCurrentUser } from "./current-user.util";

export const retrieveSession = async (): Promise<Session> => {
  try {
    const userObject = await Auth.currentAuthenticatedUser();
    const userId: string = userObject.attributes.sub;
    const user: User = await handleCurrentUser(userId);
    return {
      logged: true,
      user,
      userObject,
      userId,
    };
  } catch {
    return {
      logged: false,
      user: {} as User,
      userId: "",
      userObject: null,
    };
  }
};
