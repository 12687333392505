import { useQuery } from "@apollo/client";
import React, { FC } from "react";
import { useParams } from "react-router";
import { GET_POLICY, GET_ROLES_BY_POLICY } from "../client/policies/queries";
import { BackdropComponent } from "../components/commons/backdrop/brackdrop.component";
import { PolicyEditContent } from "../content/policy-edit.content";

export const PolicyEditRoute: FC = (): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const params: any = useParams();
  const { id } = params;
  const policy = useQuery(GET_POLICY, {
    variables: { id },
  });
  const rolesPolicy = useQuery(GET_ROLES_BY_POLICY, {
    variables: { filters: `{'policy':'${id}'}` },
  });
  const roles = useQuery(GET_ROLES_BY_POLICY, {
    variables: { filters: "" },
  });
  return policy.loading ? (
    <BackdropComponent />
  ) : (
    <PolicyEditContent
      currentRoles={rolesPolicy?.data?.getRoles?.roles || []}
      policy={policy?.data?.getPolicy?.policy}
      roles={roles?.data?.getRoles?.roles || []}
    />
  );
};
