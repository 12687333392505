import { useMutation } from "@apollo/client";
import React, { FC } from "react";
import { useHistory } from "react-router";
import { UPDATE_LOCATION } from "../client/locations/mutation";
import { GET_LOCATION, GET_LOCATIONS } from "../client/locations/queries";
import { LocationEditComponent } from "../components/location-edit/location-edit.component";
import { ILocation } from "../models/location";

export const LocationEditContent: FC<{ location: ILocation }> = ({
  location,
}): JSX.Element => {
  const history = useHistory();
  const [updateLocation] = useMutation(UPDATE_LOCATION);

  const handleUpdate = async (locationEdited: ILocation) => {
    updateLocation({
      variables: { ...locationEdited },
      update: async (cache, { data }) => {
        if (data && data.updateLocation) {
          const existing: any = cache.readQuery({ query: GET_LOCATIONS });
          if (existing && existing.getLocations) {
            const arrayLocations: ILocation[] = existing.getLocations.locations;
            if (arrayLocations) {
              await cache.writeQuery({
                query: GET_LOCATION,
                variables: {
                  id: locationEdited.id,
                },
                data: {
                  getLocation: {
                    status: true,
                    error: null,
                    location: locationEdited,
                  },
                },
              });
              await cache.writeQuery({
                query: GET_LOCATIONS,
                data: {
                  getLocations: {
                    status: true,
                    error: null,
                    locations: arrayLocations.map((location: ILocation) =>
                      location.id === locationEdited.id
                        ? (location = locationEdited)
                        : location
                    ),
                  },
                },
              });
              history.push("/locations");
            }
          }
        }
      },
    });
  };
  return (
    <LocationEditComponent handleUpdate={handleUpdate} location={location} />
  );
};
