import React, { FC } from "react";

import { useMutation } from "@apollo/client";

import { ClientCreateComponent } from "../components/client-create/client-create.component";
import { Client } from "../models/client.model";
import { CREATE_CLIENT } from "../client/clients/mutation";
import { GET_CLIENTS } from "../client/clients/queries";

export const ClientCreateContent: FC = (): JSX.Element => {
  const [createClient] = useMutation(CREATE_CLIENT);

  const handleCreate = (client: Client): void => {
    createClient({
      variables: {
        ...client,
      },
      optimisticResponse: true,
      update: (cache, { data }) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const existing: any = cache.readQuery({
          query: GET_CLIENTS,
          variables: {
            filters: "",
          },
        });
        if (data.createClient) {
          if (existing && existing.getClients) {
            const createItem: Client = data.createClient.client;
            cache.writeQuery({
              query: GET_CLIENTS,
              variables: {
                filters: "",
              },
              data: {
                getClients: {
                  status: true,
                  clients: [createItem, ...existing.getClients.clients],
                  error: null,
                },
              },
            });
          }
        }
      },
    });
  };

  return (
    <ClientCreateComponent handleCreate={(client) => handleCreate(client)} />
  );
};
