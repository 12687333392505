import React, { FC, useState, ChangeEvent } from "react";

import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TablePagination,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import SearchIcon from "@material-ui/icons/Search";
import { useHistory } from "react-router-dom";

import { Client } from "../../models/client.model";
import { IClients } from "../../models/components/clients.model";
import { can } from "../../utils/authorized.util";

export const ClientsComponent: FC<IClients> = ({
  clients,
  handleDelete,
}): JSX.Element => {
  const history = useHistory();

  const [modal, setModal] = useState<boolean>(false);
  const [displayClients, setDisplayClients] = useState<Client[]>(clients);
  const [selectedClient, setSelectedClient] = useState<Client>();

  const deleteClient = (id: string): void => {
    handleDelete(id);
    setModal(false);
  };

  /**
   * Table pagination
   */

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeSearch = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    const searchBy: string = event.currentTarget.value;
    const filtered: Client[] = clients.filter((client: Client) =>
      client.phone.includes(searchBy)
    );
    setDisplayClients(filtered);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={8}>
        <Grid container justifyContent="flex-start" spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<AddCircleIcon />}
              onClick={() => history.push("clients/new")}
              disabled={!can("CLIENTS::CREATE", "CLIENTS::*")}
            >
              Agregar Cliente
            </Button>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-amount">
                Buscar por teléfono
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                labelWidth={140}
                onChange={handleChangeSearch}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table aria-label="table-clients">
                <TableHead>
                  <TableRow>
                    <TableCell>Nombre</TableCell>
                    <TableCell align="right">Celular</TableCell>
                    <TableCell align="right">Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displayClients
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: Client) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {row.name}
                          <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                          >
                            {row.commission && row.commission > 0
                              ? "Mayorista"
                              : ""}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">{row.phone}</TableCell>
                        <TableCell align="right">
                          <IconButton
                            aria-label="edit"
                            onClick={() =>
                              history.push(`/clients/${row.id}/edit`)
                            }
                            disabled={
                              !can("CLIENTS::UPDATE", `CLIENTS::${row.id}`)
                            }
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            aria-label="delete"
                            color="secondary"
                            onClick={() => {
                              setSelectedClient(row);
                              setModal(true);
                            }}
                            disabled={!can("CLIENTS::DELETE", "CLIENTS::*")}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                colSpan={3}
                count={clients.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={modal}
        onClose={() => setModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Eliminar cliente</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Realmente deseas eliminar a {selectedClient?.name}? Una vez
            eliminado no podrás recuperarlo
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModal(false)}>Cancelar</Button>
          <Button
            onClick={() => deleteClient(selectedClient?.id as string)}
            color="secondary"
            autoFocus
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
