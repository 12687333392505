import React, { FC, Fragment } from "react";

import {
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
} from "@material-ui/core";
import { format } from "date-fns";

import logo from "../../../assets/logo.png";
import { useStyles } from "./ticket.styles";
import { Order } from "../../../models/order.model";
import { ITicket } from "../../../models/components/ticket.model";
import { Expense } from "../../../models/expense.model";

export const TicketComponent: FC<ITicket> = ({
  date,
  table,
  startDate,
  endDate,
  cashier,
  order,
  total,
  payment,
  cambio,
  paymentMethod,
  saleId,
  children,
  type = "sale",
  deliveryTime,
  expenses,
}): JSX.Element => {
  const classes = useStyles();

  const getExpensesPrices = (): number[] => {
    return (
      (expenses &&
        expenses.map(
          (expense: Expense) => expense.quantity * expense.price
        )) || [0]
    );
  };

  const getExpensesTotal = (): number => {
    return (
      getExpensesPrices().reduce(
        (total: number, current: number) => total + current,
        0
      ) || 0
    );
  };

  return (
    <div className="ticket-print">
      <div className={classes.ticket}>
        <div className={classes.header}>
          <img src={logo} alt="Quesadillas Guero" />
          <Typography variant="body1" gutterBottom>
            &quot;PIDELAS EN CALIENTE&quot;
          </Typography>
        </div>
        <div className={classes.subHeader}>
          <Typography variant="caption" gutterBottom>
            VENTA A PISO
          </Typography>
          <Typography variant="h6" gutterBottom>
            TEL 61 29891
          </Typography>
        </div>
        <Divider />
        <div className={classes.info}>
          {type === "corte" && (
            <Typography variant="overline" gutterBottom>
              CORTE
            </Typography>
          )}
          {type === "sale" && (
            <Typography variant="caption" gutterBottom>
              FECHA: {format(date, "dd-MM-yyyy")}
            </Typography>
          )}
          {saleId && (
            <Typography variant="h6" gutterBottom>
              VENTA: {saleId}
            </Typography>
          )}
        </div>
        <div className={classes.info}>
          <Grid
            container
            direction={type === "sale" ? "row" : "column"}
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              {table && (
                <Typography variant="caption" gutterBottom>
                  MESA: {table}
                </Typography>
              )}
            </Grid>
            <Grid item>
              <Typography variant="caption" align="center" gutterBottom>
                ENTRA:{" "}
                {format(
                  startDate,
                  type === "sale" ? "H:mm" : "dd/MM/yyyy H:mm"
                )}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption" align="center" gutterBottom>
                SALE:{" "}
                {format(endDate, type === "sale" ? "H:mm" : "dd/MM/yyyy H:mm")}
              </Typography>
            </Grid>
          </Grid>
        </div>
        {cashier && (
          <div className={classes.info}>
            <Typography variant="caption" gutterBottom>
              CAJERO: {cashier}
            </Typography>
          </div>
        )}
        <Divider className={classes.margener} />
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader}>Producto</TableCell>
              <TableCell className={classes.tableCell} align="right">
                Cantidad
              </TableCell>
              <TableCell className={classes.tableHeader} align="right">
                Precio
              </TableCell>
              <TableCell className={classes.tableHeader} align="right">
                Importe
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {order
              .slice()
              .sort((a, b) => b.quantity - a.quantity)
              .map((row: Order) => (
                <TableRow key={row.product.id}>
                  <TableCell
                    className={classes.tableCell}
                    component="th"
                    scope="row"
                  >
                    {`${row.product.category.name} - ${row.product.name}`}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    {row.quantity}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    ${row.product.price}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    ${row.product.price * row.quantity}
                  </TableCell>
                </TableRow>
              ))}
            {expenses && (
              <TableRow>
                <TableCell colSpan={4} component="th" scope="row">
                  Gastos:
                </TableCell>
              </TableRow>
            )}
            {expenses &&
              expenses
                .slice()
                .sort((a, b) => b.quantity * b.price - a.quantity * a.price)
                .map((row: Expense, index: number) => (
                  <TableRow key={index}>
                    <TableCell
                      className={classes.tableCell}
                      component="th"
                      scope="row"
                    >
                      {row.product}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      {row.quantity}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      ${row.price}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      ${row.price * row.quantity}
                    </TableCell>
                  </TableRow>
                ))}
            {expenses && (
              <Fragment>
                <TableRow>
                  <TableCell
                    className={classes.tableCell}
                    colSpan={4}
                    align="right"
                  >
                    Importe ventas: ${total}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    className={classes.tableCell}
                    colSpan={4}
                    align="right"
                  >
                    Gastos: -${getExpensesTotal()}
                  </TableCell>
                </TableRow>
              </Fragment>
            )}
            <TableRow>
              <TableCell
                className={classes.tableFooter}
                colSpan={4}
                align="right"
              >
                Total: ${expenses ? total - getExpensesTotal() : total}
              </TableCell>
            </TableRow>
            {payment ? (
              <TableRow>
                <TableCell
                  className={classes.tableFooter}
                  colSpan={4}
                  align="right"
                >
                  Pago: ${payment}
                </TableCell>
              </TableRow>
            ) : null}
            {paymentMethod === "cash" && cambio ? (
              <TableRow>
                <TableCell
                  className={classes.tableFooter}
                  colSpan={4}
                  align="right"
                >
                  Cambio: ${cambio}
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
        {deliveryTime && (
          <Typography variant="caption" align="center">
            El tiempo estimado de entrega del pedido es: {deliveryTime}
          </Typography>
        )}
        {type === "sale" && (
          <div className={classes.block}>
            <Divider className={classes.margener} />
            <Typography
              className={classes.textCenter}
              variant="caption"
              gutterBottom
            >
              GRACIAS POR SU PREFERENCIA
            </Typography>
            <Typography
              className={classes.textCenter}
              variant="caption"
              gutterBottom
            >
              FUE UN PLACER SERVIRLE COMO USTED SE MERECE
            </Typography>
          </div>
        )}
      </div>
      {children}
    </div>
  );
};
