import React, {
  FC,
  useContext,
  Fragment,
  useEffect,
  useState,
  ChangeEvent,
} from "react";

import {
  AppBar,
  Button,
  Toolbar,
  useMediaQuery,
  ClickAwayListener,
  Tooltip,
  Grid,
  Avatar,
  Typography,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";

import { useStyles } from "./navbar.styles";
import { SessionContext } from "../../../context/session.context";
import { Session } from "../../../models/session.model";
import { retrieveSession } from "../../../utils/retrieve-session.util";
import logoGuero from "../../../assets/logo-guero.png";
import { can, has } from "../../../utils/authorized.util";
import { Logout } from "../../../utils/logout.util";
import { useQuery } from "@apollo/client";
import { GET_LOCATIONS } from "../../../client/locations/queries";
import { ILocation } from "../../../models/location";
import LOGO from "../../../assets/logo.png";

export const NavbarComponent: FC = (): JSX.Element => {
  const { logged, handleSession, user, handleLocation, location } =
    useContext(SessionContext);

  const classes = useStyles();

  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const locationsItem = useQuery(GET_LOCATIONS);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = useState<boolean>(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    const retrieve = async () => {
      const session: Session = await retrieveSession();
      handleSession(session);
    };
    if (!logged) {
      retrieve();
    }
  }, [logged]);

  const redirect = (route: string): void => {
    history.push(route);
  };

  const handleSelectLocation = (
    event: ChangeEvent<HTMLSelectElement>
  ): void => {
    const { value } = event.currentTarget;
    const location: ILocation =
      locationsItem.data &&
      (locationsItem.data.getLocations.locations.find(
        (location: ILocation) => location.id === value
      ) as ILocation);
    handleLocation(location);
  };

  const handleLogout = async () => {
    const session: Session = await Logout();
    handleSession(session);
    enqueueSnackbar("Sesión cerrada correctamente", {
      variant: "success",
      resumeHideDuration: 3000,
      anchorOrigin: { horizontal: "right", vertical: "bottom" },
    });
    history.push("/");
  };

  const profileTooltip: JSX.Element = (
    <Grid container justifyContent="center" alignItems="center" spacing={1}>
      <Grid item xs={4}>
        <Grid container justifyContent="center" alignItems="center">
          <Avatar src={LOGO} alt="Guero" className={classes.avatar}>
            QS
          </Avatar>
        </Grid>
      </Grid>
      <Grid item xs={8}>
        <Grid container>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              spacing={1}
            >
              <Grid item>
                <Button
                  size="small"
                  className={classes.whiteButton}
                  onClick={() => redirect("/profile")}
                >
                  Editar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  size="small"
                  className={classes.whiteButton}
                  onClick={handleLogout}
                >
                  Cerrar sesión
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              align="center"
              display="block"
              gutterBottom
            >
              {user.name}
            </Typography>
            <Typography
              variant="caption"
              align="center"
              display="block"
              gutterBottom
            >
              {user.username}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <AppBar id="navbar" position="static" className={classes.marginBottom}>
      <Toolbar>
        <div className={classes.title} onClick={() => redirect("/main")}>
          <img
            src={logoGuero}
            className={classes.logo}
            alt="Quesadillas El Güero"
          />
        </div>
        {logged ? (
          <Fragment>
            {isMobile ? (
              <Button color="inherit" onClick={() => redirect("/main")}>
                Principal
              </Button>
            ) : (
              <Fragment>
                <select
                  onChange={handleSelectLocation}
                  defaultValue={location?.id}
                  name="location"
                  id="location"
                  className={classes.select}
                  disabled={!can("LOCATIONS::LIST", "LOCATIONS::*")}
                >
                  {locationsItem.data &&
                    locationsItem.data.getLocations.locations.map(
                      (location: ILocation) => (
                        <option key={location.id} value={location.id}>
                          {location.name}
                        </option>
                      )
                    )}
                </select>
                {has("ROLES::LIST") && (
                  <Button color="inherit" onClick={() => redirect("/roles")}>
                    Roles
                  </Button>
                )}
                {has("POLICIES::LIST") && (
                  <Button color="inherit" onClick={() => redirect("/policies")}>
                    Políticas
                  </Button>
                )}
                {has("LOCATIONS::LIST") && (
                  <Button color="inherit" onClick={() => redirect("/expenses")}>
                    Gastos
                  </Button>
                )}
                {has("LOCATIONS::LIST") && (
                  <Button
                    color="inherit"
                    onClick={() => redirect("/locations")}
                  >
                    Sucursales
                  </Button>
                )}
                {has("CLIENTS::LIST") && (
                  <Button color="inherit" onClick={() => redirect("/clients")}>
                    Clientes
                  </Button>
                )}
                {has("CUTOFF::CREATE") && (
                  <Button
                    color="inherit"
                    onClick={() => redirect("/sales-cuts")}
                  >
                    Corte
                  </Button>
                )}
                {has("SALES::LIST") && (
                  <Button color="inherit" onClick={() => redirect("/sales")}>
                    Ventas
                  </Button>
                )}
              </Fragment>
            )}
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <div>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={handleTooltipClose}
                  open={open}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  placement="bottom-end"
                  title={profileTooltip}
                  interactive
                >
                  <Button color="inherit" onClick={handleTooltipOpen}>
                    Perfil
                  </Button>
                </Tooltip>
              </div>
            </ClickAwayListener>
          </Fragment>
        ) : (
          <Button color="inherit" onClick={() => redirect("/")}>
            Ingresar
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};
