import React, { FC, useState, ChangeEvent } from "react";

import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TablePagination,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { Role } from "../../models/role.model";
import { DELETE_ROLE } from "../../client/roles/mutation";
import { GET_ROLES } from "../../client/roles/queries";
import { IRoles } from "../../models/components/roles.model";
import { can } from "../../utils/authorized.util";

export const RolesComponent: FC<IRoles> = ({ roles }): JSX.Element => {
  const history = useHistory();

  const [modal, setModal] = useState<boolean>(false);
  const [selectedRole, setSelectedRole] = useState<Role>();
  const [deleteItem] = useMutation(DELETE_ROLE);

  const handleDelete = (id: string): void => {
    deleteItem({
      variables: {
        id,
      },
      optimisticResponse: true,
      update: (cache, { data }) => {
        const existing: any = cache.readQuery({
          query: GET_ROLES,
          variables: {
            filters: "",
          },
        });
        if (data.deleteRole && existing && existing.getRoles) {
          const deleteItem: string = data.deleteRole.message;
          cache.writeQuery({
            query: GET_ROLES,
            variables: {
              filters: "",
            },
            data: {
              getRoles: {
                status: true,
                error: null,
                roles: existing.getRoles.roles.filter(
                  (role: Role) => role.id !== deleteItem
                ),
              },
            },
          });
        }
      },
    });
  };

  const deleteRole = (id: string): void => {
    handleDelete(id);
    setModal(false);
  };

  /**
   * Table pagination
   */

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={8}>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          spacing={2}
        >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<AddCircleIcon />}
              onClick={() => history.push("/roles/new")}
              disabled={!can("ROLES::CREATE", "ROLES::*")}
            >
              Agregar rol
            </Button>
          </Grid>
          <Grid item>
            <TableContainer component={Paper}>
              <Table aria-label="table-clients">
                <TableHead>
                  <TableRow>
                    <TableCell>Nombre</TableCell>
                    <TableCell align="right">Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {roles
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: Role) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            aria-label="edit"
                            onClick={() =>
                              history.push(`/roles/${row.id}/edit`)
                            }
                            disabled={!can("ROLES::UPDATE", `ROLES::${row.id}`)}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            aria-label="delete"
                            color="secondary"
                            onClick={() => {
                              setSelectedRole(row);
                              setModal(true);
                            }}
                            disabled={!can("ROLES::DELETE", "ROLES::*")}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                colSpan={3}
                count={roles.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={modal}
        onClose={() => setModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Eliminar rol</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Realmente deseas eliminar el rol {selectedRole?.name}? Una vez
            eliminado no podrás recuperarlo
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModal(false)}>Cancelar</Button>
          <Button
            onClick={() => deleteRole(selectedRole?.id as string)}
            color="secondary"
            autoFocus
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
