import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  ticket: {
    display: "flex",
    flexDirection: "column",
    width: "7cm",
    margin: "0.3cm 0.1cm",
  },
  ticketPrint: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "7cm",
    margin: "0.3cm 0.1cm",
  },
  header: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  subHeader: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  info: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  textCenter: {
    textAlign: "center",
  },
  block: {
    display: "flex",
    flexDirection: "column",
  },
  tableCell: {
    padding: 0,
  },
  tableHeader: {
    padding: "0 0.1cm",
  },
  tableFooter: {
    padding: "0 0.1cm",
    borderBottom: 0,
  },
  margener: {
    margin: "0.3cm 0",
  },
});
