import { gql } from "@apollo/client";

export const GET_USERS = gql`
  query getUsers($filters: String!) {
    getUsers(filters: $filters) {
      status
      users {
        id
        name
        email
        username
        phone
        defaultLocation {
          id
          name
          phone
          address
          enable
        }
        textSize
      }
      error {
        code
        message
      }
    }
  }
`;

export const GET_USER = gql`
  query getUser($id: String!) {
    getUser(id: $id) {
      status
      user {
        id
        name
        email
        username
        phone
        defaultLocation {
          id
          name
          phone
          address
          enable
        }
        textSize
      }
      error {
        code
        message
      }
    }
  }
`;
