import React, { FC } from "react";

import { RolesComponent } from "../components/roles/roles.component";
import { Role } from "../models/role.model";

interface IRolesContent {
  roles: Role[];
}

export const RolesContent: FC<IRolesContent> = ({ roles }): JSX.Element => {
  return <RolesComponent roles={roles} />;
};
