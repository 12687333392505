import React, { FC, useMemo } from "react";

import { Grid, Paper, TextField, Typography, Button } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { useForm } from "react-yup";

import { CategorySchema } from "../../forms/category.schema";
import { CREATE_CATEGORY } from "../../client/categories/mutation";
import { ICategories } from "../../models/categories.model";

export const CategoriesCreateComponent: FC = (): JSX.Element => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { field, createSubmitHandler } = useForm({
    validationSchema: CategorySchema,
  });

  const [createCategory] = useMutation(CREATE_CATEGORY);

  const handleCreate = (category: ICategories): void => {
    createCategory({
      variables: category,
      optimisticResponse: true,
    });
  };

  const handleSubmit = useMemo(() => {
    return createSubmitHandler(
      (values) => {
        const { name, description } = values;
        handleCreate({ name, description } as ICategories);
        enqueueSnackbar(`${name} ha sido creada`, {
          variant: "success",
          resumeHideDuration: 3000,
          anchorOrigin: {
            horizontal: "right",
            vertical: "bottom",
          },
        });
        history.push("/categories");
      },
      () => {
        enqueueSnackbar("Algunos campos contienen errores", {
          variant: "error",
          resumeHideDuration: 6000,
          anchorOrigin: {
            horizontal: "right",
            vertical: "bottom",
          },
        });
      }
    );
  }, []);

  const goBack = () => {
    history.push("/categories");
  };

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} md={10} lg={8}>
        <Paper elevation={2}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Typography variant="h4">Añadir Categoría</Typography>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  id="name"
                  name="name"
                  label="Nombre"
                  variant="outlined"
                  required
                  onChange={field.onChange}
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  type="text"
                  id="description"
                  name="description"
                  label="Descripción"
                  variant="outlined"
                  onChange={field.onChange}
                />
              </Grid>
              <Grid item xs={10}>
                <Grid container justifyContent="flex-end" spacing={2}>
                  <Grid item>
                    <Button
                      variant="contained"
                      size="small"
                      startIcon={<ArrowBackIcon />}
                      type="button"
                      onClick={() => goBack()}
                    >
                      Regresar
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      id="addCategory"
                      color="primary"
                      size="small"
                      startIcon={<CheckCircleIcon />}
                      type="submit"
                    >
                      Añadir categoría
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};
