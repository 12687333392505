import React, { FC } from "react";

import { useLocation, useParams } from "react-router";
import { useQuery } from "@apollo/client";
import { parse } from "qs";

import { ExpenseEditContent } from "../content/expense-edit.content";
import { GET_EXPENSE } from "../client/expenses/queries";
import { BackdropComponent } from "../components/commons/backdrop/brackdrop.component";

export const ExpenseEditRoute: FC = (): JSX.Element => {
  const params: any = useParams();
  const queryParams = useLocation();

  const expenseItem = useQuery(GET_EXPENSE, {
    variables: {
      location: parse(queryParams.search)["?location"],
      date: +params.date,
    },
  });

  return expenseItem.loading ? (
    <BackdropComponent />
  ) : (
    <ExpenseEditContent
      expense={expenseItem.data && expenseItem.data.getExpense.expense}
    />
  );
};
