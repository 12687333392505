import React, { ChangeEvent, FC, useState, useEffect } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Grid,
  TablePagination,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import SearchBar from "material-ui-search-bar";

import { ICustomTable } from "../../../models/components/custom-table.model";
import { TableHeader } from "../../../models/components/table-header.model";

export const CustomTableComponent: FC<ICustomTable> = ({
  data,
  tableHeaders,
  optionsTable,
  handlers,
}): JSX.Element => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [rows, setRows] = useState<any[]>(data);
  const [searched, setSearched] = useState<string>("");

  const requestSearch = (searchedVal: string) => {
    const filteredRows = data.filter((row) =>
      row.name.toLowerCase().includes(searchedVal.toLowerCase())
    );
    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  useEffect(() => {
    setRows(data);
  }, [data]);

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <SearchBar
              value={searched}
              onChange={(searchVal) => requestSearch(searchVal)}
              onCancelSearch={() => cancelSearch()}
            />
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table aria-label="table-clients">
                <TableHead>
                  <TableRow>
                    {tableHeaders.map((header: TableHeader, index: number) => (
                      <TableCell align="right" key={index}>
                        {header.display}
                      </TableCell>
                    ))}
                    {optionsTable && (
                      <TableCell align="right">Acciones</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.length > 0 ? (
                    rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((data: any, index: number) => (
                        <TableRow key={index}>
                          {tableHeaders.map(
                            (header: TableHeader, index: number) => (
                              <TableCell align="right" key={index}>
                                {data[header.field]}
                              </TableCell>
                            )
                          )}
                          <TableCell align="right">
                            {optionsTable && (
                              <div>
                                {handlers?.watch && (
                                  <IconButton
                                    aria-label="upload picture"
                                    component="span"
                                    onClick={() => handlers.watch(data.id)}
                                  >
                                    <VisibilityIcon />
                                  </IconButton>
                                )}
                                {handlers?.edit && (
                                  <IconButton
                                    aria-label="upload picture"
                                    component="span"
                                    onClick={() => handlers.edit(data.id)}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                )}
                                {handlers?.delete && (
                                  <IconButton
                                    color="secondary"
                                    aria-label="upload picture"
                                    component="span"
                                    onClick={() => handlers.delete(data.id)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                )}
                              </div>
                            )}
                          </TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <Typography variant="body1">No hay elementos</Typography>
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                colSpan={3}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
