import React from "react";
import ReactDOM from "react-dom";

import Amplify from "aws-amplify";
import { ApolloProvider } from "@apollo/client";

import { ENV } from "./environments/index";
import { App } from "./App";
import { apolloClient } from "./client";
import { SessionContextProvider } from "./context/session.context";

import "./index.css";

/**
 * Initialize aws cognito configuration
 */
Amplify.configure(ENV.AWS.cognito);

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <SessionContextProvider>
        <App />
      </SessionContextProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
