import React, { FC, useState, ChangeEvent } from "react";

import { useHistory } from "react-router";
import { GridColDef } from "@material-ui/data-grid";
import { Button, Grid, Tabs, Tab, Typography } from "@material-ui/core";
import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";

import { Policy } from "../../models/policy.model";
import { MultipleSelectComponent } from "../commons/multiple-select/multiple-select.component";
import { IPolicyEdit } from "../../models/components/policy-edit.model";
import { can } from "../../utils/authorized.util";

export const PolicyEditComponent: FC<IPolicyEdit> = ({
  policy,
  updatePolicy,
  roles,
  currentRoles,
  addPolicyToRole,
  deleteolicyToRole,
}): JSX.Element => {
  const history = useHistory();
  const [policyState, setPolicyState] = useState<Policy>(policy);
  const [tabIndex, setTabIndex] = useState<number>(0);

  const handleChangePolicy = (policy: Policy) => {
    setPolicyState({
      ...policyState,
      actions: policy.actions,
      resources: policy.resources,
      effect: policy.effect,
      name: policy.name,
    });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    updatePolicy(policyState);
  };

  const handleTabChange = (event: ChangeEvent<any>, value: any): void => {
    setTabIndex(value);
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Nombre",
      width: 200,
      editable: false,
    },
  ];

  const handler = (deletedItems: any[], addedItems: any[]): Promise<any> => {
    const deletedPromises: Promise<any>[] = deletedItems.map(
      (deleted: string) => deleteolicyToRole(deleted, policy?.id as string)
    );

    const addedPromises: Promise<any>[] = addedItems.map((added: string) =>
      addPolicyToRole(added, policy?.id as string)
    );

    return Promise.all([...deletedPromises, ...addedPromises]);
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={8}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label="Detalles de la politica" />
              <Tab
                label="Añadir roles"
                disabled={!can("ROLES::LIST", "ROLES::*")}
              />
            </Tabs>
            {tabIndex === 0 && (
              <form onSubmit={handleSubmit}>
                <Typography variant="h3">Política: {policy.name}</Typography>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Editor onChange={handleChangePolicy} value={policyState} />
                  </Grid>
                  <Grid item>
                    <Grid container justifyContent="flex-end" spacing={2}>
                      <Grid item>
                        <Button
                          variant="contained"
                          type="button"
                          onClick={() => history.goBack()}
                        >
                          Regresar
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={
                            !can("POLICIES::UPDATE", `POLICIES::${policy.id}`)
                          }
                        >
                          Actualizar
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
            {tabIndex === 1 && (
              <MultipleSelectComponent
                columns={columns}
                handler={handler}
                rows={roles}
                searchBy="name"
                currentSelection={currentRoles}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
