import { gql } from "@apollo/client";

export const GET_PRODUCTS = gql`
  query getProducts($filters: String!) {
    getProducts(filters: $filters) {
      status
      products {
        id
        name
        category {
          id
          name
        }
        price
        mixture
        promoItems {
          category {
            id
            name
          }
          mixture
        }
      }
      error {
        code
        message
      }
    }
  }
`;

export const GET_PRODUCT = gql`
  query getProduct($id: String!) {
    getProduct(id: $id) {
      status
      product {
        id
        name
        category {
          id
          name
        }
        price
        mixture
        promoItems {
          category {
            id
            name
          }
          mixture
        }
      }
      error {
        code
        message
      }
    }
  }
`;
