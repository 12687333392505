import { gql } from "@apollo/client";

export const GET_CATEGORIES = gql`
  query {
    getCategories {
      status
      categories {
        id
        name
        description
      }
      error {
        code
        message
      }
    }
  }
`;

export const GET_CATEGORY = gql`
  query getCategory($id: String!) {
    getCategory(id: $id) {
      status
      category {
        id
        name
        description
      }
      error {
        code
        message
      }
    }
  }
`;
