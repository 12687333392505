import { gql } from "@apollo/client";

export const GET_LOCATIONS = gql`
  query getLocations {
    getLocations {
      status
      locations {
        id
        name
        address
        enable
        phone
        letter
        color
      }
      error {
        code
        message
      }
    }
  }
`;
export const GET_LOCATION = gql`
  query getLocation($id: String!) {
    getLocation(id: $id) {
      status
      location {
        id
        name
        address
        enable
        phone
        letter
        color
      }
      error {
        code
        message
      }
    }
  }
`;
