import { gql } from "@apollo/client";

export const CREATE_SALE = gql`
  mutation createSale(
    $type: String!
    $paymentMethod: String!
    $orders: [OrderInput]!
    $total: Float!
    $date: Int!
    $location: String!
    $taxes: Float
    $client: String!
    $status: String!
    $table: String!
    $isExternal: Boolean
    $externalOrigen: String
    $deliveryTime: String
  ) {
    createSale(
      type: $type
      paymentMethod: $paymentMethod
      orders: $orders
      total: $total
      date: $date
      location: $location
      taxes: $taxes
      client: $client
      status: $status
      table: $table
      isExternal: $isExternal
      externalOrigen: $externalOrigen
      deliveryTime: $deliveryTime
    ) {
      status
      sale {
        id
        type
        paymentMethod
        orders {
          product {
            id
            name
            price
            mixture
            promoItems {
              category {
                id
                name
              }
              mixture
            }
            category {
              id
              name
            }
          }
          ingredients
          quantity
        }
        total
        date
        location {
          id
          name
          address
          phone
          color
          letter
        }
        taxes
        status
        table
        isExternal
        externalOrigen {
          id
          name
          address
          phone
          color
          letter
        }
        deliveryTime
      }
      error {
        code
        message
      }
    }
  }
`;

export const UPDATE_SALE = gql`
  mutation updateSale(
    $id: String!
    $type: String!
    $paymentMethod: String!
    $orders: [OrderInput]!
    $total: Float!
    $date: Int!
    $location: String!
    $taxes: Float
    $client: String!
    $status: String!
    $table: String!
    $deliveryTime: String
  ) {
    updateSale(
      id: $id
      type: $type
      paymentMethod: $paymentMethod
      orders: $orders
      total: $total
      date: $date
      location: $location
      taxes: $taxes
      client: $client
      status: $status
      table: $table
      deliveryTime: $deliveryTime
    ) {
      status
      sale {
        id
        type
        paymentMethod
        orders {
          product {
            id
            name
            price
            mixture
            category {
              id
              name
            }
            promoItems {
              category {
                id
                name
              }
            }
          }
          ingredients
          quantity
        }
        total
        date
        location {
          id
          name
          phone
          address
          color
          letter
        }
        taxes
        table
        client {
          id
          name
          address
          city
          rfc
          phone
        }
        status
        isExternal
        cancelDescription
        externalOrigen {
          id
          name
          address
          phone
          letter
          color
        }
        deliveryTime
      }
      error {
        code
        message
      }
    }
  }
`;

export const DELETE_SALE = gql`
  mutation deleteSale($id: String!) {
    deleteSale(id: $id) {
      status
      message
      error {
        code
        message
      }
    }
  }
`;

export const CANCEL_SALE = gql`
  mutation cancelSale($id: String!, $cancelDescription: String!) {
    cancelSale(id: $id, cancelDescription: $cancelDescription) {
      status
      sale {
        id
        type
        paymentMethod
        orders {
          product {
            id
            name
            category {
              id
              name
            }
            price
            mixture
            promoItems {
              category {
                id
                name
              }
              mixture
            }
          }
          quantity
          ingredients
        }
        total
        date
        location {
          id
          name
          phone
          address
          enable
          letter
          color
        }
        taxes
        client {
          id
          address
          city
          rfc
          registerDate
          phone
          name
          commission
          commissionType
          alternativeAddress
        }
        status
        table
        isExternal
        cancelDescription
        externalOrigen {
          id
          name
          phone
          address
          enable
          letter
          color
        }
        deliveryTime
      }
      error {
        code
        message
      }
    }
  }
`;
