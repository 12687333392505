import React, { FC, useState, ChangeEvent } from "react";

import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TablePagination,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";

import { Category } from "../../models/category.model";
import { DELETE_CATEGORY } from "../../client/categories/mutation";
import { GET_CATEGORIES } from "../../client/categories/queries";
import { can } from "../../utils/authorized.util";
import { ICategoriesComponent } from "../../models/components/categories-component.model";

export const CategoriesComponent: FC<ICategoriesComponent> = ({
  categories,
}): JSX.Element => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [modal, setModal] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<Category>();
  const [deleteCategory] = useMutation(DELETE_CATEGORY);

  const handleDelete = (id: string): void => {
    deleteCategory({
      variables: {
        id,
      },
      optimisticResponse: true,
      update: (cache, { data }) => {
        const existing: any = cache.readQuery({
          query: GET_CATEGORIES,
          variables: {
            filters: "",
          },
        });
        if (data.deleteCategory && existing && existing.getCategories) {
          const deleteItem: string = data.deleteCategory.message;
          cache.writeQuery({
            query: GET_CATEGORIES,
            variables: {
              filters: "",
            },
            data: {
              getCategories: {
                status: true,
                error: null,
                roles: existing.getCategories.categories.filter(
                  (category: Category) => category.id !== deleteItem
                ),
              },
            },
          });
        }
      },
    });
    setModal(false);
    enqueueSnackbar("Categoría Eliminada", {
      variant: "success",
      resumeHideDuration: 3000,
      anchorOrigin: {
        horizontal: "right",
        vertical: "bottom",
      },
    });
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const singleFunction = (row) => {
    setSelectedCategory(row);
    setModal(true);
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={8}>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          spacing={2}
        >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<AddCircleIcon />}
              onClick={() => history.push("/categories/new")}
              disabled={!can("CATEGORIES::CREATE", "CATEGORIES::*")}
            >
              Agregar categoría
            </Button>
          </Grid>
          <Grid item>
            <TableContainer component={Paper}>
              <Table aria-label="table-clients">
                <TableHead>
                  <TableRow>
                    <TableCell>Nombre</TableCell>
                    <TableCell align="right">Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {categories
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: Category) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            aria-label="edit"
                            onClick={() =>
                              history.push(`/categories/${row.id}/edit`)
                            }
                            disabled={
                              !can(
                                "CATEGORIES::UPDATE",
                                `CATEGORIES::${row.id}`
                              )
                            }
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            aria-label="delete"
                            color="secondary"
                            onClick={() => {
                              singleFunction(row);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                colSpan={3}
                count={categories.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={modal}
        onClose={() => setModal(false)}
        aria-labelledby="delete-category"
        aria-describedby="delete-category"
      >
        <DialogTitle id="delete-category">Eliminar Categoría</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-category">
            ¿Realmente deseas eliminar la categoria {selectedCategory?.name}?
            Una vez eliminada no podrás recuperarla
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModal(false)}>Cancelar</Button>
          <Button
            onClick={() => handleDelete(selectedCategory?.id as string)}
            color="secondary"
            data-cy={"delete"}
            autoFocus
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
