import React, { FC, useState, useEffect, useContext } from "react";

import { Grid, Paper, Typography, Button, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import ReceiptIcon from "@material-ui/icons/Receipt";
import { getUnixTime } from "date-fns";
import { useQuery, useLazyQuery } from "@apollo/client";
import { useHistory } from "react-router";

import { GET_SALES_CUTOFF } from "../../client/sales/queries";
import { ILocation } from "../../models/location";
import { GET_LOCATIONS } from "../../client/locations/queries";
import { SessionContext } from "../../context/session.context";
import { can } from "../../utils/authorized.util";

export const SaleCutCreateComponent: FC = (): JSX.Element => {
  const session = useContext(SessionContext);

  const [location, setLocation] = useState<ILocation>();

  const history = useHistory();

  useEffect(() => {
    if (session.location) {
      setLocation(session.location);
    }
  }, []);

  const handleCompleteTicket = (data: any) => {
    window.open(
      `${window.location.origin}/ticket?location=${data.getSalesCutOff.ticket?.location?.id}&date=${data.getSalesCutOff.ticket.date}`,
      "_blank"
    );
    history.push("/sales-cuts");
  };

  const [getSalesCutOff] = useLazyQuery(GET_SALES_CUTOFF, {
    onCompleted: handleCompleteTicket,
  });

  const locationsItems = useQuery(GET_LOCATIONS);

  const generateTicket = async () => {
    await getSalesCutOff({
      variables: {
        filters: JSON.stringify({
          location: location?.id,
          user: session.userId,
          requestedAt: getUnixTime(new Date()),
        }),
      },
    });
  };

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12} md={10} lg={8}>
        <Paper>
          <Grid container justifyContent="center" spacing={2}>
            <Typography variant="h3">Corte de venta</Typography>
            <Grid item xs={10}>
              <Autocomplete
                id="locations"
                loading={locationsItems.loading}
                disableClearable={true}
                options={
                  (locationsItems.data &&
                    locationsItems.data.getLocations.locations) ||
                  []
                }
                getOptionLabel={(option: ILocation) => option.name}
                onChange={(event, value) => setLocation(value as ILocation)}
                defaultValue={session.location}
                disabled={!can("CUTOFF::CREATE", "LOCATIONS::*")}
                renderInput={(params) => (
                  <TextField {...params} label="Sucursal" variant="outlined" />
                )}
              />
            </Grid>
            <Grid item xs={10}>
              <Grid container justifyContent="center">
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  startIcon={<ReceiptIcon />}
                  onClick={generateTicket}
                  disabled={
                    !can("CUTOFF::CREATE", `LOCATIONS::${location?.id}`)
                  }
                >
                  Generar Corte
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
