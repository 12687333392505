import React, { FC } from "react";

import { useQuery } from "@apollo/client";

import { GET_ROLES } from "../client/roles/queries";
import { RolesContent } from "../content/roles.content";
import { BackdropComponent } from "../components/commons/backdrop/brackdrop.component";

export const RolesRoute: FC = (): JSX.Element => {
  const { data, loading } = useQuery(GET_ROLES, {
    variables: {
      filters: "",
    },
  });

  return loading ? (
    <BackdropComponent />
  ) : (
    <RolesContent roles={data.getRoles.roles} />
  );
};
