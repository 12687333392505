import React, { FC } from "react";

import {
  TextField,
  Button,
  Paper,
  Grid,
  Typography,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { useHistory } from "react-router";

import { ILocationWatch } from "../../models/components/location-watch.model";
import { can } from "../../utils/authorized.util";

export const LocationWatchComponent: FC<ILocationWatch> = ({
  location,
}): JSX.Element => {
  const history = useHistory();

  const { name, address, enable, phone, id } = location;

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={8}>
        <Paper elevation={2}>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item>
              <Typography variant="h3">
                Detalles de la sucursal {name}
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <FormControl>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      disabled={true}
                      name="name"
                      value={name}
                      id="outlined-basic"
                      label="Nombre"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      disabled={true}
                      name="address"
                      value={address}
                      id="outlined-basic"
                      label="Dirección"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      disabled={true}
                      name="phone"
                      value={phone}
                      id="outlined-basic"
                      label="Teléfono"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled
                          checked={enable}
                          name="enable"
                          color="primary"
                        />
                      }
                      label="Sucursal activa"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justifyContent="flex-end" spacing={2}>
                      <Grid item>
                        <Button
                          variant="contained"
                          key="one"
                          onClick={() => history.goBack()}
                        >
                          Regresar
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          color="primary"
                          variant="contained"
                          key="two"
                          onClick={() => history.push(`/locations/${id}/edit`)}
                          disabled={
                            !can("LOCATIONS::UPDATE", `LOCATIONS::${id}`)
                          }
                        >
                          Editar
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
