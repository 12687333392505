import React, { FC, useState, ChangeEvent } from "react";

import {
  TextField,
  FormControl,
  Checkbox,
  Button,
  Paper,
  Grid,
  Typography,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { useHistory } from "react-router";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

import { ILocationEdit } from "../../models/components/location-edit.model";
import { ILocation } from "../../models/location";
import { can } from "../../utils/authorized.util";
import { useStyles } from "./location-edit.styles";

export const LocationEditComponent: FC<ILocationEdit> = ({
  location,
  handleUpdate,
}): JSX.Element => {
  const history = useHistory();
  const classes = useStyles();
  const [locationState, setLocationState] = useState<ILocation>(location);
  const { name, address, enable, phone, color, letter } = locationState;

  const handleChange = (event: any) => {
    setLocationState({
      ...locationState,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeColor = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ): void => {
    const { value } = event.target;
    setLocationState({
      ...locationState,
      color: `${value}` as string,
    });
  };

  const handleCheck = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setLocationState({
      ...locationState,
      enable: checked,
    });
  };

  const handleClick = () => handleUpdate(locationState);

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={8}>
        <Paper elevation={2}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h3">
                Edición de la sucursal {name}
              </Typography>
            </Grid>
            <Grid item>
              <FormControl>
                <Grid container justifyContent="center" spacing={2}>
                  <Grid item xs={10}>
                    <TextField
                      fullWidth
                      name="name"
                      value={name}
                      id="outlined-basic"
                      label="Nombre de la sucursal"
                      variant="outlined"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      fullWidth
                      name="address"
                      value={address}
                      id="outlined-basic"
                      label="Dirección"
                      variant="outlined"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      fullWidth
                      name="phone"
                      value={phone}
                      id="outlined-basic"
                      label="Teléfono"
                      variant="outlined"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <Grid container justifyContent="center" spacing={2}>
                      <Grid item xs={12} md={4}>
                        <TextField
                          fullWidth
                          name="letter"
                          id="letter"
                          label="Letra identificadora"
                          variant="outlined"
                          onChange={handleChange}
                          defaultValue={letter}
                        />
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel id="color-select">Color</InputLabel>
                          <Select
                            labelId="color-select"
                            id="color"
                            onChange={handleChangeColor}
                            label="Color"
                            value={color}
                            fullWidth
                          >
                            <MenuItem value="red">
                              <FiberManualRecordIcon
                                className={classes.redColor}
                              />
                              Rojo
                            </MenuItem>
                            <MenuItem value="pink">
                              <FiberManualRecordIcon
                                className={classes.pinkColor}
                              />
                              Rosa
                            </MenuItem>
                            <MenuItem value="purple">
                              <FiberManualRecordIcon
                                className={classes.purpleColor}
                              />
                              Morado
                            </MenuItem>
                            <MenuItem value="blue">
                              <FiberManualRecordIcon
                                className={classes.blueColor}
                              />
                              Azul
                            </MenuItem>
                            <MenuItem value="green">
                              <FiberManualRecordIcon
                                className={classes.greenColor}
                              />
                              Verde
                            </MenuItem>
                            <MenuItem value="lime">
                              <FiberManualRecordIcon
                                className={classes.limeColor}
                              />
                              Lima
                            </MenuItem>
                            <MenuItem value="yellow">
                              <FiberManualRecordIcon
                                className={classes.yellowColor}
                              />
                              Amarillo
                            </MenuItem>
                            <MenuItem value="amber">
                              <FiberManualRecordIcon
                                className={classes.amberColor}
                              />
                              Dorado
                            </MenuItem>
                            <MenuItem value="orange">
                              <FiberManualRecordIcon
                                className={classes.orangeColor}
                              />
                              Naranja
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={10}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={enable}
                          onChange={handleCheck}
                          name="enable"
                          color="primary"
                        />
                      }
                      label="Sucursal activa"
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <Grid container justifyContent="flex-end" spacing={2}>
                      <Grid item>
                        <Button
                          variant="contained"
                          key="one"
                          onClick={() => history.goBack()}
                        >
                          Regresar
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          key="two"
                          type="button"
                          color="primary"
                          onClick={handleClick}
                          disabled={
                            !can(
                              "LOCATIONS::UPDATE",
                              `LOCATIONS::${location.id}`
                            )
                          }
                        >
                          Actualizar
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
