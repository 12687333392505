import React, { FC } from "react";

import { useMutation } from "@apollo/client";
import { useHistory } from "react-router";

import {
  ADD_POLICY_TO_ROLE,
  DELETE_POLICY_FROM_ROLE,
  UPDATE_POLICY,
} from "../client/policies/mutations";
import {
  GET_POLICIES,
  GET_POLICY,
  GET_ROLES_BY_POLICY,
} from "../client/policies/queries";
import { PolicyEditComponent } from "../components/policy-edit/policy-edit.component";
import { Policy } from "../models/policy.model";

export const PolicyEditContent: FC<{
  policy: Policy;
  roles: any[];
  currentRoles: any[];
}> = ({ policy, roles, currentRoles }): JSX.Element => {
  const [addPolicyToRole] = useMutation(ADD_POLICY_TO_ROLE);
  const [deletePolicyToRole] = useMutation(DELETE_POLICY_FROM_ROLE);
  const [updatePolicy] = useMutation(UPDATE_POLICY);
  const history = useHistory();

  const handleUpdatePolicy = (policyUpdate: Policy) => {
    updatePolicy({
      variables: { ...policyUpdate },
      optimisticResponse: true,
      update: async (cache, { data }) => {
        if (data && data.updatePolicy) {
          const existing: any = cache.readQuery({
            query: GET_POLICIES,
            variables: { filters: "" },
          });
          if (existing && existing.getPolicies) {
            const arrayPolicies: Policy[] = existing.getPolicies.policies;
            if (arrayPolicies) {
              await cache.writeQuery({
                query: GET_POLICY,
                variables: { id: policy.id },
                data: {
                  getPolicy: {
                    status: true,
                    error: null,
                    policy: policyUpdate,
                  },
                },
              });
              await cache.writeQuery({
                query: GET_POLICIES,
                variables: { filters: "" },
                data: {
                  getPolicies: {
                    status: true,
                    error: null,
                    policies: arrayPolicies.map((e) =>
                      e.id === policyUpdate.id ? (e = policyUpdate) : e
                    ),
                  },
                },
              });
              history.push("/policies");
            }
          }
        }
      },
    });
  };

  const handleAddPolicyToRole = (
    role: string,
    policy: string
  ): Promise<any> => {
    return addPolicyToRole({
      variables: { role, policy },
      optimisticResponse: true,
      update: async (cache, { data }) => {
        if (data && data.addPolicyToRole) {
          const existing: any = cache.readQuery({
            query: GET_ROLES_BY_POLICY,
            variables: { filters: `{'policy':'${policy}'}` },
          });
          if (existing && existing.getRoles) {
            const arrayRoles: any[] = existing.getRoles.roles;
            if (arrayRoles) {
              await cache.writeQuery({
                query: GET_ROLES_BY_POLICY,
                variables: { filters: `{'policy':'${policy}'}` },
                data: {
                  getRoles: {
                    status: true,
                    error: null,
                    roles: [
                      ...arrayRoles,
                      data.addPolicyToRole.policyRole.role,
                    ],
                  },
                },
              });
            }
          }
        }
      },
    });
  };

  const handleDeletePolicyRole = (
    role: string,
    policy: string
  ): Promise<any> => {
    return deletePolicyToRole({
      variables: { role, policy },
      optimisticResponse: true,
      update: async (cache, { data }) => {
        if (data && data.deletePolicyFromRole) {
          const existing: any = cache.readQuery({
            query: GET_ROLES_BY_POLICY,
            variables: { filters: `{'policy':'${policy}'}` },
          });
          if (existing && existing.getRoles) {
            const arrayRoles: any[] = existing.getRoles.roles;
            if (arrayRoles) {
              await cache.writeQuery({
                query: GET_ROLES_BY_POLICY,
                variables: { filters: `{'policy':'${policy}'}` },
                data: {
                  getRoles: {
                    status: true,
                    error: null,
                    roles: arrayRoles.filter((e) => e.id !== role),
                  },
                },
              });
            }
          }
        }
      },
    });
  };

  return (
    <PolicyEditComponent
      addPolicyToRole={handleAddPolicyToRole}
      deleteolicyToRole={handleDeletePolicyRole}
      currentRoles={currentRoles}
      policy={policy}
      updatePolicy={handleUpdatePolicy}
      roles={roles}
    />
  );
};
