import React, { FC, ChangeEvent, useState, useContext, useEffect } from "react";

import { Grid, Typography, Paper, TextField, Button } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";

import { useStyles } from "./login.styles";
import { Login } from "../../models/login.model";
import { handleAuth } from "../../utils/session.util";
import { SessionContext } from "../../context/session.context";
import { Session } from "../../models/session.model";
import { retrieveSession } from "../../utils/retrieve-session.util";
import { BackdropComponent } from "../commons/backdrop/brackdrop.component";
import { ILocation } from "../../models/location";
import { NetworkStatus } from "../../networking/types";

export const LoginComponent: FC = (): JSX.Element => {
  // Variable for styles
  const classes = useStyles();

  const history = useHistory();

  const { handleSession, handleLocation, logged } = useContext(SessionContext);

  // Variable for show alerts
  const { enqueueSnackbar } = useSnackbar();

  const [loginStatus, setLoginStatus] = useState<NetworkStatus>({
    loading: false,
    success: false,
    error: null,
  });

  // Login state
  const [login, setLogin] = useState<Login>({
    username: "",
    password: "",
  });

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const getSession = async (): Promise<void> => {
      const session: Session = await retrieveSession();
      if (session.logged) {
        handleSession(session);
        session.user.defaultLocation &&
          handleLocation(session.user.defaultLocation);
        history.push("/main");
      }
      setLoading(false);
    };

    if (logged) {
      setLoading(false);
      history.push("/main");
    } else {
      getSession();
    }
  }, [logged]);

  /**
   * Function for receive change events in inputs and set values in state
   * @param event event of input
   */
  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const { name, value } = event.currentTarget;
    setLogin({
      ...login,
      [name]: value,
    });
  };

  /**
   * Function for do login
   */
  const handleLogin = async (): Promise<void> => {
    try {
      setLoginStatus((status) => ({ ...status, loading: true }));
      const session: Session = await handleAuth(login.username, login.password);
      handleSession(session);
      session.user.defaultLocation &&
        handleLocation(session.user.defaultLocation as ILocation);
      setLoginStatus((status) => ({
        ...status,
        loading: false,
        success: true,
      }));

      if (session.userObject.challengeName === "NEW_PASSWORD_REQUIRED") {
        enqueueSnackbar("La contraseña debe ser modificada", {
          variant: "warning",
          resumeHideDuration: 3000,
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
        });
      } else {
        enqueueSnackbar(`Bienvenido ${login.username}`, {
          variant: "success",
          resumeHideDuration: 3000,
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
        });
        history.push("/main");
      }
    } catch (error: any) {
      setLoginStatus((status) => ({
        ...status,
        loading: false,
        success: false,
      }));
      enqueueSnackbar(error.message, {
        variant: "error",
        resumeHideDuration: 6000,
        anchorOrigin: { horizontal: "right", vertical: "bottom" },
      });
    }
  };

  return loading ? (
    <BackdropComponent />
  ) : (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12} md={8} lg={6}>
        <Paper elevation={2}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid className={classes.textCenter} item xs={12}>
              <Typography variant="h3" component="h3">
                Ingresar
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                type="text"
                id="username"
                name="username"
                label="Usuario"
                variant="outlined"
                onChange={(event) => handleChange(event)}
                disabled={loginStatus.loading}
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                id="password"
                type="password"
                name="password"
                label="Contraseña"
                variant="outlined"
                onChange={(event) => handleChange(event)}
                disabled={loginStatus.loading}
              />
            </Grid>
            <Grid item xs={10}>
              <Grid container justifyContent="flex-end">
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={
                    loginStatus.loading || !login.password || !login.username
                  }
                  onClick={handleLogin}
                >
                  {loginStatus.loading ? "Cargando" : "Ingresar"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
