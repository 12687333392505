import React, { FC, useContext, useEffect, useState } from "react";

import { BrowserRouter, Switch, Route } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";

import { ProtectedRoute } from "./components/commons/protected-route/protected-route.component";
import { ROUTES } from "./routes";
import { IRoute } from "./models/route.model";
import { Session } from "./models/session.model";
import { retrieveSession } from "./utils/retrieve-session.util";
import { SessionContext } from "./context/session.context";
import { NavbarComponent } from "./components/commons/navbar/navbar.component";
import { getTheme } from "./theme/index";
import { BackdropComponent } from "./components/commons/backdrop/brackdrop.component";

export const App: FC = (): JSX.Element => {
  const { handleLocation, fontSize, handleSession, handleFontSize } =
    useContext(SessionContext);

  const [loading, setLoading] = useState<boolean>(true);
  const [currentFontSize, setCurrentFontSize] = useState<
    "medium" | "large" | "extraLarge"
  >(fontSize);

  useEffect(() => {
    const getSession = async () => {
      const session: Session = await retrieveSession();
      handleSession(session);
      handleFontSize(
        (session.user.textSize as "medium" | "large" | "extraLarge") || "medium"
      );
      setCurrentFontSize(
        session.user.textSize as "medium" | "large" | "extraLarge"
      );
      if (session.user.defaultLocation) {
        handleLocation(session.user.defaultLocation);
      }
      setLoading(false);
    };
    getSession();
  }, []);

  return loading ? (
    <BackdropComponent />
  ) : (
    <BrowserRouter>
      <MuiThemeProvider theme={getTheme(currentFontSize)}>
        <SnackbarProvider maxSnack={3}>
          <NavbarComponent />
          <Switch>
            {ROUTES.map((route: IRoute) =>
              route.policy ? (
                <ProtectedRoute
                  key={route.key}
                  path={route.path}
                  component={route.component}
                  policy={route.policy}
                  exact={route.exact}
                />
              ) : (
                <Route
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                  key={route.key}
                />
              )
            )}
          </Switch>
        </SnackbarProvider>
      </MuiThemeProvider>
    </BrowserRouter>
  );
};
