import React, { FC } from "react";
import { useParams } from "react-router-dom";
import { SaleEditContent } from "../content/sale-edit.context";
import { IdParams } from "../models/router/id.model";

export const SaleEditRoute: FC = (): JSX.Element => {
  const { id }: IdParams = useParams();

  return <SaleEditContent id={id} />;
};
