import { apolloClient } from "../client/index";
import { GET_SUMMARIZED_POLICIES } from "../client/policies/queries";

let policies: any;

export const fetchPolicies = async (userId: string) => {
  try {
    const summarize = await apolloClient.query({
      query: GET_SUMMARIZED_POLICIES,
      variables: {
        user: userId,
      },
    });
    policies = summarize.data.getSummarizedPolicies.summarize;
    return policies;
  } catch (err) {
    return err;
  }
};

export const has = (action: string): boolean => {
  if (policies && policies[action]) {
    return true;
  } else {
    return false;
  }
};

export const can = (action: string, resource: string): boolean => {
  const hasAction: boolean = has(action);
  const genericResource = `${resource.split("::")[0]}::*`;
  if (hasAction) {
    if (
      policies[action][resource] &&
      policies[action][genericResource] !== false
    ) {
      return true;
    } else {
      const permission: boolean = policies[action][genericResource] ?? false;
      return permission;
    }
  } else {
    return false;
  }
};

export const canMultiple = (action: string, resources: string[]): boolean => {
  const hasAction: boolean = has(action);
  if (hasAction) {
    const permissions: boolean[] = resources.map((resource: string) =>
      can(action, resource)
    );
    const permission: boolean = permissions.every(
      (permission: boolean) => permission === true
    );
    return permission;
  } else {
    return false;
  }
};

export const cleanPolicies = (): void => {
  policies = undefined;
};
