import { useMutation } from "@apollo/client";
import React, { FC } from "react";
import { useHistory } from "react-router";
import { CREATE_POLICY } from "../client/policies/mutations";
import { GET_POLICIES } from "../client/policies/queries";
import { PolicyNewComponent } from "../components/policy-new/policy-new.component";
import { Policy } from "../models/policy.model";

export const PolicyNewContent: FC = (): JSX.Element => {
  const history = useHistory();
  const [createPolicy] = useMutation(CREATE_POLICY);

  const handleCreatePolicy = (policyNew: Policy) => {
    createPolicy({
      variables: { ...policyNew },
      optimisticResponse: true,
      update: async (cache, { data }) => {
        if (data && data.createPolicy) {
          const existing: any = cache.readQuery({
            query: GET_POLICIES,
            variables: { filters: "" },
          });
          if (existing && existing.getPolicies) {
            const arrayPolicies: Policy[] = existing.getPolicies.policies;
            if (arrayPolicies) {
              await cache.writeQuery({
                query: GET_POLICIES,
                variables: { filters: "" },
                data: {
                  getPolicies: {
                    status: true,
                    error: null,
                    policies: [...arrayPolicies, data.createPolicy.policy],
                  },
                },
              });
              history.push("/policies");
            }
          } else {
            history.push("/policies");
          }
        }
      },
    });
  };

  return <PolicyNewComponent createPolicy={handleCreatePolicy} />;
};
