import { User } from "../models/user.model";
import { apolloClient } from "../client/index";
import { GET_USER } from "../client/users/queries";
import { fetchPolicies } from "../utils/authorized.util";

export const handleCurrentUser = async (userId: string): Promise<User> => {
  try {
    const response = await apolloClient.query({
      query: GET_USER,
      variables: {
        id: userId,
      },
    });
    await fetchPolicies(userId);
    return response.data.getUser.user;
  } catch (err) {
    return {} as User;
  }
};
