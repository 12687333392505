import React, { FC, useState } from "react";

import { DataGrid, GridRowId } from "@material-ui/data-grid";
import SaveIcon from "@material-ui/icons/Save";
import { Button, Grid } from "@material-ui/core";
import SearchBar from "material-ui-search-bar";

import { IMultipleSelect } from "../../../models/components/multiple-select.model";

export const MultipleSelectComponent: FC<IMultipleSelect> = ({
  columns,
  rows,
  currentSelection,
  searchBy,
  handler,
}): JSX.Element => {
  const initialSelection: string[] = currentSelection.map((row: any) => row.id);
  const [selection, setSelection] = useState<GridRowId[]>(initialSelection);
  const [searched, setSearched] = useState<string>("");
  const [display, setDisplay] = useState<any[]>(rows);

  const getSelection = (): void => {
    const initialSelectionSet: Set<string> = new Set<string>(initialSelection);
    const selectedIndexes: Set<string> = new Set<string>(selection as string[]);
    const deletedIndexes: string[] = initialSelection.filter(
      (row: string) => !selectedIndexes.has(row)
    );
    const addedIndexes: string[] = selection.filter(
      (row: GridRowId) => !initialSelectionSet.has(row as string)
    ) as string[];

    handler(deletedIndexes, addedIndexes);
  };

  const requestSearch = (searchedVal: string) => {
    const filteredRows = rows.filter((row: any) =>
      row[searchBy as keyof typeof row]
        .toLowerCase()
        .includes(searchedVal.toLowerCase())
    );
    setDisplay(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item xs={12}>
        <SearchBar
          value={searched}
          onChange={(searchVal) => requestSearch(searchVal)}
          onCancelSearch={() => cancelSearch()}
        />
      </Grid>
      <Grid item xs={12}>
        <div
          style={{
            minHeight: "200px",
            height:
              (display.length < 10 && 64 * (display.length + 1)) || 64 * 11,
            width: "100%",
          }}
        >
          <DataGrid
            rows={display}
            columns={columns}
            pageSize={10}
            selectionModel={selection}
            onSelectionModelChange={(items) => setSelection(items)}
            checkboxSelection
            disableSelectionOnClick
          />
        </div>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={getSelection}
              startIcon={<SaveIcon />}
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
