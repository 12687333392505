import React, { FC } from "react";

import { useQuery } from "@apollo/client";

import { SaleEditComponent } from "../components/sale-edit/sale-edit.component";
import { IdParams } from "../models/router/id.model";
import { GET_SALE } from "../client/sales/queries";
import { BackdropComponent } from "../components/commons/backdrop/brackdrop.component";

export const SaleEditContent: FC<IdParams> = ({ id }): JSX.Element => {
  const { data, loading } = useQuery(GET_SALE, {
    variables: {
      id,
    },
  });

  return loading ? (
    <BackdropComponent />
  ) : (
    <SaleEditComponent saleEdit={data.getSale.sale} />
  );
};
