import React, { FC } from "react";

import { useMutation } from "@apollo/client";

import { DELTE_POLICY } from "../client/policies/mutations";
import { GET_POLICIES } from "../client/policies/queries";
import { Policy } from "../models/policy.model";
import { PoliciesComponent } from "./../components/policies/policies.component";

export const PoliciesContent: FC<{ policies: Policy[] }> = ({
  policies,
}): JSX.Element => {
  const [deletePolicy] = useMutation(DELTE_POLICY);

  const handleDeletePolicy = (id: string) => {
    deletePolicy({
      variables: {
        id,
      },
      optimisticResponse: true,
      update: async (cache, { data }) => {
        if (data && data.deletePolicy) {
          const existing: any = cache.readQuery({
            query: GET_POLICIES,
            variables: { filters: "" },
          });
          if (existing && existing.getPolicies) {
            const arrayPolicies: Policy[] = existing.getPolicies.policies;
            if (arrayPolicies) {
              await cache.writeQuery({
                query: GET_POLICIES,
                variables: { filters: "" },
                data: {
                  getPolicies: {
                    status: true,
                    error: null,
                    policies: arrayPolicies.map(
                      (policy: Policy) => policy.id !== id
                    ),
                  },
                },
              });
            }
          }
        }
      },
    });
  };

  return (
    <PoliciesComponent policies={policies} deletePolicy={handleDeletePolicy} />
  );
};
