import React, { ChangeEvent, FC, useContext, useState } from "react";

import {
  Grid,
  Paper,
  TextField,
  Button,
  Typography,
  FormControl,
  InputLabel,
  InputAdornment,
  OutlinedInput,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "@apollo/client";
import { useHistory } from "react-router";
import { startOfDay, endOfDay, getUnixTime } from "date-fns";

import { Expense } from "../../models/expense.model";
import { SessionContext } from "../../context/session.context";
import { GET_LOCATIONS } from "../../client/locations/queries";
import { ILocation } from "../../models/location";
import { CREATE_EXPENSE } from "../../client/expenses/mutations";
import { GET_EXPENSES } from "../../client/expenses/queries";
import { notNumber } from "../../utils/not-number.util";
import { can } from "../../utils/authorized.util";

export const ExpenseCreateComponent: FC = (): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const { location } = useContext(SessionContext);

  const [createExpense] = useMutation(CREATE_EXPENSE);

  const locationsItems = useQuery(GET_LOCATIONS);

  const [expense, setExpense] = useState<Expense>({
    product: "",
    price: 0,
    quantity: 1,
    description: "",
  });
  const [selectedLocation, setSelectedLocation] = useState<ILocation>(
    location as ILocation
  );

  const handleLocationChange = (location: ILocation): void => {
    setSelectedLocation(location);
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const { name, value } = event.currentTarget;
    setExpense({
      ...expense,
      [name]: value,
    });
  };

  const getTotal = (): number => {
    if (notNumber(expense.price) || notNumber(expense.quantity)) {
      return 0;
    }
    return +expense.price * expense.quantity;
  };

  const save = () => {
    if (notNumber(expense.price) || notNumber(expense.quantity)) {
      enqueueSnackbar("Precio y cantidad deben ser números", {
        variant: "warning",
        resumeHideDuration: 5000,
        anchorOrigin: { horizontal: "right", vertical: "bottom" },
      });
      return;
    }
    const toSave = {
      ...expense,
      price: +expense.price,
      quantity: +expense.quantity,
      location: selectedLocation.id,
    };
    createExpense({
      variables: {
        ...toSave,
      },
      optimisticResponse: true,
      update: (cache, { data }) => {
        const existing: any = cache.readQuery({
          query: GET_EXPENSES,
          variables: {
            filters: JSON.stringify({
              location: selectedLocation?.id,
              from: getUnixTime(startOfDay(new Date())),
              to: getUnixTime(endOfDay(new Date())),
            }),
          },
        });
        if (data.createExpense && existing && existing.getExpenses) {
          const createItem: Expense = data.createExpense.expense;
          cache.writeQuery({
            query: GET_EXPENSES,
            variables: {
              filters: JSON.stringify({
                location: location?.id,
                from: getUnixTime(startOfDay(new Date())),
                to: getUnixTime(endOfDay(new Date())),
              }),
            },
            data: {
              getExpenses: {
                status: true,
                error: null,
                expenses: [createItem, ...existing.getExpenses.expenses],
              },
            },
          });
        }
      },
    });
    enqueueSnackbar("Gasto añadido", {
      variant: "success",
      resumeHideDuration: 3000,
      anchorOrigin: { horizontal: "right", vertical: "bottom" },
    });
    history.push("/expenses");
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} lg={8}>
        <Paper>
          <Grid container justifyContent="center" spacing={1}>
            <Grid item xs={10}>
              <Typography variant="h3" align="center">
                Añadir gasto
              </Typography>
            </Grid>
            <Grid item xs={11} lg={10}>
              <Autocomplete
                id="locations"
                loading={locationsItems.loading}
                disableClearable={true}
                options={
                  (locationsItems.data &&
                    locationsItems.data.getLocations.locations) ||
                  []
                }
                getOptionLabel={(option: ILocation) => option.name}
                onChange={(event, value) =>
                  handleLocationChange(value as ILocation)
                }
                defaultValue={location}
                renderInput={(params) => (
                  <TextField {...params} label="Sucursal" variant="outlined" />
                )}
                disabled={!can("EXPENSES::LIST", "LOCATIONS::*")}
              />
            </Grid>
            <Grid item xs={11} lg={7}>
              <TextField
                id="product"
                name="product"
                label="Producto"
                onChange={handleChange}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={11} lg={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="price">Precio</InputLabel>
                <OutlinedInput
                  id="price"
                  name="price"
                  onChange={handleChange}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                  labelWidth={60}
                  inputProps={{
                    inputProps: { min: 1 },
                    inputMode: "numeric",
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={11} lg={3}>
              <TextField
                id="quantity"
                name="quantity"
                label="Cantidad"
                onChange={handleChange}
                inputProps={{
                  inputProps: { min: 1 },
                  inputMode: "numeric",
                }}
                fullWidth
                defaultValue={expense.quantity}
                variant="outlined"
                helperText="Coloca la cantidad de productos adquirida"
              />
            </Grid>
            <Grid item xs={11} lg={7}>
              <TextField
                id="description"
                name="description"
                label="Descripción"
                onChange={handleChange}
                minRows={1}
                multiline
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={11}>
              <Typography variant="h6" align="right">
                Total: ${getTotal()}
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Grid container justifyContent="flex-end" spacing={1}>
                <Grid item>
                  <Button variant="contained" onClick={() => history.goBack()}>
                    Cancelar
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" color="primary" onClick={save}>
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
