import React, { FC } from "react";

import { useQuery } from "@apollo/client";

import { CategoriesContent } from "../content/categories.content";
import { GET_CATEGORIES } from "../client/categories/queries";
import { BackdropComponent } from "../components/commons/backdrop/brackdrop.component";

export const CategoriesRoute: FC = (): JSX.Element => {
  const { data, loading } = useQuery(GET_CATEGORIES, {
    variables: {
      filters: "",
    },
  });

  return loading ? (
    <BackdropComponent />
  ) : (
    <CategoriesContent categories={data.getCategories.categories} />
  );
};
