import { gql } from "@apollo/client";

export const GET_SALES = gql`
  query getSales($filters: String!) {
    getSales(filters: $filters) {
      status
      sales {
        id
        type
        orders {
          product {
            id
            name
            price
            mixture
            promoItems {
              category {
                id
                name
              }
              mixture
            }
            category {
              id
              name
            }
          }
          ingredients
          quantity
        }
        paymentMethod
        total
        date
        location {
          id
          name
          address
          phone
          enable
          color
          letter
        }
        status
        table
        isExternal
        client {
          id
          name
          phone
          address
          alternativeAddress
        }
        cancelDescription
        externalOrigen {
          id
          name
          phone
          address
          color
          letter
        }
        deliveryTime
      }
      error {
        code
        message
      }
    }
  }
`;

export const GET_SALE = gql`
  query getSale($id: String!) {
    getSale(id: $id) {
      status
      sale {
        id
        type
        orders {
          product {
            id
            name
            price
            mixture
            promoItems {
              category {
                id
                name
              }
              mixture
            }
            category {
              id
              name
            }
          }
          quantity
          ingredients
        }
        paymentMethod
        total
        date
        location {
          id
          name
          address
          phone
          color
          letter
        }
        taxes
        client {
          id
          name
          phone
          city
          address
          rfc
        }
        table
        isExternal
        cancelDescription
        externalOrigen {
          id
          name
          phone
          address
          color
          letter
        }
        deliveryTime
      }
      error {
        code
        message
      }
    }
  }
`;

export const GET_SALES_CUTOFF = gql`
  query getSalesCutOff($filters: String!) {
    getSalesCutOff(filters: $filters) {
      status
      ticket {
        location {
          id
          name
          phone
          address
        }
        date
        from
        to
        total
        orders {
          product {
            id
            name
            price
          }
          quantity
        }
        user {
          id
          name
        }
      }
      error {
        message
      }
    }
  }
`;

export const GET_TICKETS = gql`
  query getTickets($filters: String!) {
    getTickets(filters: $filters) {
      status
      tickets {
        location {
          id
          name
          phone
          address
        }
        date
        from
        to
        total
        orders {
          product {
            id
            name
            price
          }
          quantity
        }
        user {
          id
          name
        }
      }
      error {
        code
        message
      }
    }
  }
`;

export const GET_TICKET = gql`
  query getTicket($filters: String!) {
    getTicket(filters: $filters) {
      status
      ticket {
        location {
          id
          name
          phone
          address
        }
        from
        to
        date
        total
        orders {
          product {
            id
            name
            price
            category {
              id
              name
            }
          }
          quantity
        }
        user {
          id
          name
        }
      }
      error {
        code
        message
      }
    }
  }
`;
