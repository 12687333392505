import React, { FC, useMemo, useState, ChangeEvent } from "react";

import {
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  Tabs,
  Tab,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { useForm } from "react-yup";
import { useMutation, useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import { GridColDef } from "@material-ui/data-grid";

import { roleSchema } from "../../forms/role.schema";
import {
  ADD_POLICY_TO_ROLE,
  ADD_USER_TO_ROLE,
  DELETE_POLICY_FROM_ROLE,
  DELETE_USER_FROM_ROLE,
  UPDATE_ROLE,
} from "../../client/roles/mutation";
import { Role } from "../../models/role.model";
import { GET_ROLE, GET_ROLES } from "../../client/roles/queries";
import { MultipleSelectComponent } from "../commons/multiple-select/multiple-select.component";
import { GET_USERS } from "../../client/users/queries";
import { IRoleEdit } from "../../models/components/role-edit.model";
import { GET_POLICIES } from "../../client/policies/queries";
import { Policy } from "../../models/policy.model";
import { BackdropComponent } from "../commons/backdrop/brackdrop.component";
import { can } from "../../utils/authorized.util";

export const RoleEditComponent: FC<IRoleEdit> = ({ roleEdit }): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();

  const [addUserToRole] = useMutation(ADD_USER_TO_ROLE);
  const [deleteUserFromRole] = useMutation(DELETE_USER_FROM_ROLE);
  const [updateRole] = useMutation(UPDATE_ROLE);
  const [addPolicyToRole] = useMutation(ADD_POLICY_TO_ROLE);
  const [deletePolicyFromRole] = useMutation(DELETE_POLICY_FROM_ROLE);

  const [tabIndex, setTabIndex] = useState<number>(0);

  const { field, createSubmitHandler } = useForm({
    validationSchema: roleSchema,
    defaultValues: {
      name: roleEdit.name,
      description: roleEdit.description,
    },
  });

  const allUsers = useQuery(GET_USERS, {
    variables: {
      filters: "",
    },
  });

  const selectedUsers = useQuery(GET_USERS, {
    variables: {
      filters: JSON.stringify({
        role: roleEdit.id,
      }),
    },
  });

  const allPolicies = useQuery(GET_POLICIES, {
    variables: {
      filters: "",
    },
  });

  const selectedPolicies = useQuery(GET_POLICIES, {
    variables: {
      filters: JSON.stringify({
        role: roleEdit.id,
      }),
    },
  });

  //columns for users table

  const multipleTableColumns: GridColDef[] = [
    {
      field: "name",
      headerName: "Nombre",
      width: 250,
    },
    {
      field: "email",
      headerName: "Correo electrónico",
      width: 350,
    },
  ];

  // columns for policies table
  const multipleTablePoliciesColumns: GridColDef[] = [
    {
      field: "name",
      headerName: "Nombre",
      width: 350,
    },
    {
      field: "effect",
      headerName: "Tipo",
      width: 250,
    },
  ];

  const handleEdit = (role: Role): void => {
    updateRole({
      variables: {
        id: roleEdit.id,
        ...role,
      },
      optimisticResponse: true,
      update: (cache, { data }) => {
        const existing: any = cache.readQuery({
          query: GET_ROLES,
          variables: {
            filters: "",
          },
        });
        if (data.updateRole && existing && existing.getRoles) {
          const updateItem: Role = data.updateRole.role;
          cache.writeQuery({
            query: GET_ROLES,
            variables: {
              filters: "",
            },
            data: {
              getRoles: {
                status: true,
                roles: existing.getRoles.roles.map((role: Role) =>
                  role.id === updateItem.id ? updateItem : role
                ),
                error: null,
              },
            },
          });
          cache.writeQuery({
            query: GET_ROLE,
            variables: {
              id: roleEdit.id,
            },
            data: {
              getRole: {
                status: true,
                role: updateItem,
                error: null,
              },
            },
          });
        }
      },
    });
  };

  const handleSubmit = useMemo(() => {
    return createSubmitHandler(
      (values) => {
        const { name, description } = values;
        handleEdit({ name, description } as Role);
        enqueueSnackbar(`${name} ha sido editado`, {
          variant: "success",
          resumeHideDuration: 3000,
          anchorOrigin: {
            horizontal: "right",
            vertical: "bottom",
          },
        });
        history.push("/roles");
      },
      () => {
        enqueueSnackbar("Algunos campos contienen errores", {
          variant: "error",
          resumeHideDuration: 6000,
          anchorOrigin: {
            horizontal: "right",
            vertical: "bottom",
          },
        });
      }
    );
  }, []);

  const handleTabChange = (event: ChangeEvent<any>, value: any): void => {
    setTabIndex(value);
  };

  const handleDeleteUserRole = (user: string, role: string): Promise<any> => {
    return deleteUserFromRole({
      variables: {
        user,
        role,
      },
      optimisticResponse: true,
      update: (cache, { data }) => {
        const existing: any = cache.readQuery({
          query: GET_USERS,
          variables: {
            filters: JSON.stringify({
              role,
            }),
          },
        });
        if (data.deleteUserFromRole && existing && existing.getUsers) {
          const deletedItem: string = data.deleteUserFromRole.message;
          cache.writeQuery({
            query: GET_USERS,
            variables: {
              filters: JSON.stringify({
                role,
              }),
            },
            data: {
              getUsers: {
                status: true,
                error: null,
                users: existing.getUsers.users.filter(
                  (user: any) => user.id !== deletedItem
                ),
              },
            },
          });
        }
      },
    });
  };

  const handlerAddUserRole = (user: string, role: string): Promise<any> => {
    return addUserToRole({
      variables: {
        user,
        role,
      },
      optimisticResponse: true,
      update: (cache, { data }) => {
        const existing: any = cache.readQuery({
          query: GET_USERS,
          variables: {
            filters: JSON.stringify({
              role,
            }),
          },
        });
        if (existing && existing.getUsers && data.addUserToRole) {
          const addedItem: any = data.addUserToRole.user;
          cache.writeQuery({
            query: GET_USERS,
            variables: {
              filters: JSON.stringify({
                role,
              }),
            },
            data: {
              getUsers: {
                status: true,
                error: null,
                users: [...existing.getUsers.users, addedItem],
              },
            },
          });
        }
      },
    });
  };

  const resolvePromises = (promises: Promise<any>[]): void => {
    Promise.all(promises)
      .then(() => {
        enqueueSnackbar("Relaciones editadas correctamente", {
          variant: "success",
          resumeHideDuration: 3000,
          anchorOrigin: {
            horizontal: "right",
            vertical: "bottom",
          },
        });
      })
      .catch((err) => {
        enqueueSnackbar("Error, intente de nuevo", {
          variant: "error",
          resumeHideDuration: 4000,
          anchorOrigin: {
            horizontal: "right",
            vertical: "bottom",
          },
        });
      });
  };

  const handleUpdateUserRole = (
    deleteItems: string[],
    addedItems: string[]
  ): void => {
    const deletedPromises: Promise<any>[] = deleteItems.map((deleted: string) =>
      handleDeleteUserRole(deleted, roleEdit.id as string)
    );

    const addedPromises: Promise<any>[] = addedItems.map((added: string) =>
      handlerAddUserRole(added, roleEdit.id as string)
    );

    resolvePromises([...deletedPromises, ...addedPromises]);
  };

  const handleAddPolicyRole = async (
    policy: string,
    role: string
  ): Promise<any> => {
    return addPolicyToRole({
      variables: {
        policy,
        role,
      },
      optimisticResponse: true,
      update: (cache, { data }) => {
        const existing: any = cache.readQuery({
          query: GET_POLICIES,
          variables: {
            filters: JSON.stringify({
              role: roleEdit.id,
            }),
          },
        });
        if (existing && existing.getPolicies && data.addPolicyToRole) {
          const addedItem: Policy = data.addPolicyToRole.policyRole.policy;
          cache.writeQuery({
            query: GET_POLICIES,
            variables: {
              filters: JSON.stringify({
                role: roleEdit.id,
              }),
            },
            data: {
              getPolicies: {
                status: true,
                error: null,
                policies: [...existing.getPolicies.policies, addedItem],
              },
            },
          });
        }
      },
    });
  };

  const handleDeletePolicyRole = async (
    policy: string,
    role: string
  ): Promise<any> => {
    return deletePolicyFromRole({
      variables: {
        policy,
        role,
      },
      optimisticResponse: true,
      update: (cache, { data }) => {
        const existing: any = cache.readQuery({
          query: GET_POLICIES,
          variables: {
            filters: JSON.stringify({
              role: roleEdit.id,
            }),
          },
        });
        if (existing && existing.getPolicies && data.deletePolicyFromRole) {
          const deletedItem: string = data.deletePolicyFromRole.message;
          cache.writeQuery({
            query: GET_POLICIES,
            variables: {
              filters: JSON.stringify({
                role: roleEdit.id,
              }),
            },
            data: {
              getPolicies: {
                status: true,
                error: null,
                policies: existing.getPolicies.policies.filter(
                  (policy: Policy) => policy.id !== deletedItem
                ),
              },
            },
          });
        }
      },
    });
  };

  const handleUpdatePolicyRole = (deleted: any[], added: any[]): void => {
    const addedPromises: Promise<any>[] = added.map((add: string) =>
      handleAddPolicyRole(add, roleEdit.id as string)
    );

    const deletedPromises: Promise<any>[] = deleted.map((deleted: string) =>
      handleDeletePolicyRole(deleted, roleEdit.id as string)
    );

    resolvePromises([...addedPromises, ...deletedPromises]);
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={10} lg={8}>
        <Paper elevation={2}>
          <Tabs value={tabIndex} onChange={handleTabChange}>
            <Tab label="Rol" />
            <Tab
              label="Asignar usuario"
              disabled={!can("USERS::LIST", "USERS::*")}
            />
            <Tab
              label="Asignar política"
              disabled={!can("POLICIES::LIST", "POLICIES::*")}
            />
          </Tabs>
          {tabIndex === 0 && (
            <form onSubmit={handleSubmit}>
              <Grid container justifyContent="center" spacing={2}>
                <Grid item>
                  <Typography variant="h3">
                    Editar Rol {roleEdit.name}
                  </Typography>
                  <Typography variant="caption">
                    Introduce los datos del rol
                  </Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                  <TextField
                    type="text"
                    id="name"
                    name="name"
                    label="Nombre"
                    variant="outlined"
                    helperText="Obligatorio"
                    defaultValue={roleEdit.name}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={10}>
                  <TextField
                    type="text"
                    id="description"
                    name="description"
                    label="Nombre"
                    variant="outlined"
                    defaultValue={roleEdit.description}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={10}>
                  <Grid container justifyContent="flex-end" spacing={2}>
                    <Grid item>
                      <Button
                        variant="outlined"
                        type="button"
                        onClick={() => history.goBack()}
                      >
                        Cancelar
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        startIcon={<SaveIcon />}
                        disabled={
                          !can("ROLES::UPDATE", `ROLES::${roleEdit.id}`)
                        }
                      >
                        Editar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
          {tabIndex === 1 && (
            <div>
              {allUsers.loading || selectedUsers.loading ? (
                <BackdropComponent />
              ) : (
                <MultipleSelectComponent
                  rows={allUsers.data && allUsers.data.getUsers.users}
                  columns={multipleTableColumns}
                  searchBy="name"
                  currentSelection={
                    selectedUsers.data && selectedUsers.data.getUsers.users
                  }
                  handler={(deleted, added) =>
                    handleUpdateUserRole(deleted, added)
                  }
                />
              )}
            </div>
          )}
          {tabIndex === 2 && (
            <div>
              {allPolicies.loading || selectedPolicies.loading ? (
                <BackdropComponent />
              ) : (
                <MultipleSelectComponent
                  rows={
                    allPolicies.data && allPolicies.data.getPolicies.policies
                  }
                  columns={multipleTablePoliciesColumns}
                  currentSelection={
                    selectedPolicies.data &&
                    selectedPolicies.data.getPolicies.policies
                  }
                  searchBy="name"
                  handler={(deleted, added) =>
                    handleUpdatePolicyRole(deleted, added)
                  }
                />
              )}
            </div>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};
