import { useQuery } from "@apollo/client";
import React, { FC } from "react";
import { GET_LOCATIONS } from "../client/locations/queries";
import { LocationsContext } from "../content/locations.context";
import { ILocation } from "../models/location";

export const LocationsRoute: FC = (): JSX.Element => {
  const locations = useQuery(GET_LOCATIONS);
  return (
    <LocationsContext
      locations={(locations.data?.getLocations?.locations as ILocation[]) || []}
    />
  );
};
