import { gql } from "@apollo/client";

export const CREATE_USER = gql`
  mutation createUser(
    $username: String!
    $email: String!
    $name: String!
    $phone: String!
    $defaultLocation: String
    $textSize: String
  ) {
    createUser(
      username: $username
      email: $email
      name: $name
      defaultLocation: $defaultLocation
      textSize: $textSize
    ) {
      status
      user {
        id
        name
        username
        email
        phone
        defaultLocation {
          id
          name
          phone
          address
          enable
        }
        textSize
      }
      error {
        code
        message
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser(
    $id: String!
    $name: String!
    $defaultLocation: String!
    $phone: String
    $textSize: String
  ) {
    updateUser(
      id: $id
      name: $name
      phone: $phone
      defaultLocation: $defaultLocation
      textSize: $textSize
    ) {
      status
      user {
        id
        name
        username
        email
        phone
        defaultLocation {
          id
          name
          phone
          address
          enable
        }
        textSize
      }
      error {
        code
        message
      }
    }
  }
`;
