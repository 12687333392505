import { useQuery } from "@apollo/client";
import React, { FC } from "react";
import { useParams } from "react-router";
import { GET_LOCATION } from "../client/locations/queries";
import { BackdropComponent } from "../components/commons/backdrop/brackdrop.component";
import { LocationEditContent } from "../content/location-edit.content";

export const LocationEditRoute: FC = (): JSX.Element => {
  const params = useParams();
  const { id } = params as any;

  const location = useQuery(GET_LOCATION, {
    variables: {
      id,
    },
  });

  return location.loading ? (
    <BackdropComponent />
  ) : (
    <LocationEditContent
      location={
        location.data && (location.data.getLocation?.location as Location)
      }
    />
  );
};
