import { useQuery } from "@apollo/client";
import React, { FC } from "react";
import { useParams } from "react-router";
import { GET_LOCATION } from "../client/locations/queries";
import { BackdropComponent } from "../components/commons/backdrop/brackdrop.component";
import { LocationWatchComponent } from "../components/location-watch/location-watch.component";

export const LocationWatchRoute: FC = (): JSX.Element => {
  const params = useParams();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { id } = params as any;
  const location = useQuery(GET_LOCATION, {
    variables: {
      id,
    },
  });
  return location.loading ? (
    <BackdropComponent />
  ) : (
    <LocationWatchComponent location={location.data.getLocation?.location} />
  );
};
