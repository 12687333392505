import React, { FC } from "react";

import { useLocation } from "react-router-dom";
import { parse } from "qs";
import { TicketContent } from "../content/ticket.content";

export const TicketRoute: FC = (): JSX.Element => {
  const locationParams = useLocation();

  const { sale, location, date, cash, change } = parse(
    locationParams.search.split("?")[1]
  );

  return (
    <TicketContent
      type={sale ? "sale" : "cutoff"}
      id={sale || location}
      date={date}
      cash={cash}
      change={change}
    />
  );
};
