import { gql } from "@apollo/client";

export const GET_ROLES = gql`
  query getRoles($filters: String!) {
    getRoles(filters: $filters) {
      status
      roles {
        id
        name
        description
      }
      error {
        code
        message
      }
    }
  }
`;

export const GET_ROLE = gql`
  query getRole($id: String!) {
    getRole(id: $id) {
      status
      role {
        id
        name
        description
      }
      error {
        code
        message
      }
    }
  }
`;
