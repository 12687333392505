import React, { FC, useState } from "react"; //Se importo useState

//Se importa la parte de la documentación
import { Auth } from "aws-amplify";

import Grid from "@material-ui/core/Grid";
import { Paper, Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

//Importa el modelo
import { ResetPassword } from "../../models/reset-password.model";

import { useStyles } from "./reset-password.styles";
import { ChangeEvent } from "react";

//Barra de notificaciones
import { useSnackbar } from "notistack";

//useHistory
import { useHistory } from "react-router-dom";
import { IError } from "../../models/components/error-reset-password.model";

export const ResetPasswordComponent: FC = (): JSX.Element => {
  // Variable for show alerts
  const { enqueueSnackbar } = useSnackbar();

  //Función del Hook useHistory
  const history = useHistory();

  //Definir variables <STATE>
  //El state es un objeto, lo que yo hago es obtener unicamente una parte, por eso tiene la
  //constante de objeto, obtengo propiedad del objeto padre
  const [resetPassword, setResetPassword] = useState<ResetPassword>({
    username: "",
    code: "",
    newPassword: "",
  });

  //Creacion de State del Error
  const [error, setError] = useState<IError>({
    disabledField: true, //Cambiar nombre para habilitar textfield
    validationError: false,
  });

  const handleClick = () => {
    history.push("/");
  };

  //Función onClick
  //Si este existe el usuario, desbloquea lo de abajo. En caso contrario mando un alert.
  //try: desbloquear y implemento el codigo de AWS
  const handleClickSendCode = () => {
    const { username } = resetPassword;
    // Send confirmation code to user's email
    Auth.forgotPassword(username)
      .then((data) => {
        setError({ ...error, disabledField: false });
        enqueueSnackbar("Código enviado!", {
          //¿Por qué marca un error?
          variant: "success",
          resumeHideDuration: 3000,
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
        });
      })
      .catch((err) => {
        //Cuando el nombre de usuario no exista
        setError({ ...error, disabledField: true });
        enqueueSnackbar("No válido!", {
          //¿Por qué marca un error?
          variant: "error",
          resumeHideDuration: 3000,
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
        });
      });
  };
  const handleClickPassword = () => {
    const { username } = resetPassword;
    const { code } = resetPassword;
    const { newPassword } = resetPassword;
    // Send confirmation code to user's email
    Auth.forgotPasswordSubmit(username, code, newPassword)
      .then((data) => {
        enqueueSnackbar("Contraseña restablecida!", {
          //¿Por qué marca un error?
          variant: "success",
          resumeHideDuration: 3000,
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
        });
      })
      .catch((err) => {
        enqueueSnackbar("¡No se pudo establecer contraseña!", {
          //¿Por qué marca un error?
          variant: "error",
          resumeHideDuration: 3000,
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
        });
      });
  };

  //Funcion onChange
  //Implementación

  //handleChange
  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const { name, value } = event.currentTarget;
    setResetPassword({
      ...resetPassword,
      [name]: value,
    });
  };

  const classes = useStyles();

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12} md={8} lg={6}>
        <Paper elevation={2}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid className={classes.textCenter} item xs={12}>
              <Typography variant="h3">Recuperar Contraseña</Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="h6">Nombre de usuario:</Typography>
              <TextField
                fullWidth
                type="text"
                id="username"
                name="username"
                label="Usuario"
                variant="outlined"
                onChange={handleChange}
              />

              <Grid item xs={10}>
                <Grid container justifyContent="center">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleClickSendCode}
                  >
                    Enviar
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={10}>
              <Typography variant="h6">Código enviado al correo:</Typography>
              <TextField
                fullWidth
                type="text"
                id="code"
                name="code"
                label="Código"
                variant="outlined"
                disabled={error.disabledField}
                onChange={handleChange}
              />
              <Typography variant="h6">Nueva contraseña:</Typography>
              <Typography variant="body1">
                La nueva contraseña deberá de contener:<br></br>- 1 carácter
                especial ($,%,?).<br></br>- 1 número.<br></br>- 1 mayúscula.
                <br></br>- Al menos 8 carácteres de longitud.<br></br>
              </Typography>
              <TextField
                fullWidth
                type="password"
                id="password"
                name="newPassword"
                label="Contraseña"
                variant="outlined"
                disabled={error.disabledField}
                onChange={handleChange}
              />
              <Grid item xs={10}>
                <Grid container justifyContent="center">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleClickPassword}
                  >
                    Enviar
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    type="button"
                    onClick={handleClick}
                  >
                    Regresar a Login
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
