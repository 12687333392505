import React, { FC, useContext, useState, useEffect } from "react";

import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Typography,
  TextField,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { Autocomplete } from "@material-ui/lab";
import DateFnsUtils from "@date-io/date-fns";
import EditIcon from "@material-ui/icons/Edit";
import BlockIcon from "@material-ui/icons/Block";
import { useHistory } from "react-router";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { getUnixTime, startOfDay, endOfDay, fromUnixTime } from "date-fns";

import { Expense } from "../../models/expense.model";
import { GET_LOCATIONS } from "../../client/locations/queries";
import { ILocation } from "../../models/location";
import { SessionContext } from "../../context/session.context";
import { GET_EXPENSES } from "../../client/expenses/queries";
import { useStyles } from "./expenses.styles";
import { CANCEL_EXPENSE } from "../../client/expenses/mutations";
import { can } from "../../utils/authorized.util";

export const ExpensesComponent: FC = (): JSX.Element => {
  const history = useHistory();
  const classes = useStyles();

  const { location } = useContext(SessionContext);
  const locationsItems = useQuery(GET_LOCATIONS);
  const [expenseCaller, expensesItems] = useLazyQuery(GET_EXPENSES);
  const [cancelExpense] = useMutation(CANCEL_EXPENSE);

  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [loading, setLoading] = useState<boolean>(true);
  const [modal, setModal] = useState<boolean>(false);
  const [selectedLocation, setSelectedLocation] = useState<ILocation>(
    location as ILocation
  );
  const [selectedExpense, setSelectedExpense] = useState<Expense>();
  const [cancelReason, setCancelReason] = useState<string>("");

  const fetchExpenses = (location: ILocation, date: Date) => {
    setLoading(true);
    expenseCaller({
      variables: {
        filters: JSON.stringify({
          location: location?.id,
          from: getUnixTime(startOfDay(date)),
          to: getUnixTime(endOfDay(date)),
        }),
      },
    });
    setLoading(false);
  };

  useEffect(() => {
    fetchExpenses(selectedLocation, new Date());
  }, []);

  const handleChangeDate = (date: Date): void => {
    setSelectedDate(date);
    fetchExpenses(selectedLocation, date);
  };

  const handleLocationChange = (location: ILocation): void => {
    setSelectedLocation(location);
    fetchExpenses(location, selectedDate);
  };

  const modalCanceledExpense = (expense: Expense) => {
    setSelectedExpense(expense);
    setModal(true);
  };

  const canceledExpense = (location: string, date: number) => {
    cancelExpense({
      variables: {
        location,
        date,
        cancelReason,
      },
      optimisticResponse: true,
      update: (cache, { data }) => {
        const existing: any = cache.readQuery({
          query: GET_EXPENSES,
          variables: {
            filters: JSON.stringify({
              location,
              from: getUnixTime(startOfDay(fromUnixTime(date))),
              to: getUnixTime(endOfDay(fromUnixTime(date))),
            }),
          },
        });
        if (data.cancelExpense && existing && existing.getExpenses) {
          const canceledDate: number =
            +data.cancelExpense.message.split("@")[1];
          cache.writeQuery({
            query: GET_EXPENSES,
            variables: {
              filters: JSON.stringify({
                location,
                from: getUnixTime(startOfDay(fromUnixTime(date))),
                to: getUnixTime(endOfDay(fromUnixTime(date))),
              }),
            },
            data: {
              getExpenses: {
                status: true,
                error: null,
                expenses: existing.getExpenses.expenses.map(
                  (expense: Expense) =>
                    expense.date === canceledDate
                      ? {
                          ...expense,
                          cancelReason,
                          canceled: true,
                        }
                      : expense
                ),
              },
            },
          });
          setModal(false);
        }
      },
    });
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} lg={8}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => history.push("/expenses/new")}
        >
          Añadir gasto
        </Button>
        <Grid container justifyContent="center" spacing={1}>
          <Grid item xs={12} lg={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                fullWidth
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="date"
                label="Fecha"
                value={selectedDate}
                onChange={(date) => handleChangeDate(date as Date)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Autocomplete
              id="locations"
              loading={locationsItems.loading}
              disableClearable={true}
              options={
                (locationsItems.data &&
                  locationsItems.data.getLocations.locations) ||
                []
              }
              getOptionLabel={(option: ILocation) => option.name}
              onChange={(event, value) =>
                handleLocationChange(value as ILocation)
              }
              defaultValue={location}
              renderInput={(params) => (
                <TextField {...params} label="Sucursal" variant="outlined" />
              )}
              disabled={!can("EXPENSES::LIST", "LOCATIONS::*")}
            />
          </Grid>
        </Grid>
        <Grid xs={12}>
          <Table aria-label="order" component={Paper}>
            <TableHead>
              <TableRow>
                <TableCell>Producto</TableCell>
                <TableCell align="right">Cantidad</TableCell>
                <TableCell align="right">Unitario</TableCell>
                <TableCell align="right">Total</TableCell>
                <TableCell align="right">Acción</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && <Typography variant="body1">Cargando...</Typography>}
              {!expensesItems.loading &&
                expensesItems.data &&
                expensesItems.data.getExpenses.expenses.map(
                  (expense: Expense, index: number) => (
                    <TableRow
                      key={index}
                      className={expense.canceled ? classes.canceled : ""}
                    >
                      <TableCell component="th" scope="row">
                        {expense.product}
                      </TableCell>
                      <TableCell align="right">{expense.quantity}</TableCell>
                      <TableCell align="right">${expense.price}</TableCell>
                      <TableCell align="right">
                        ${expense.price * expense.quantity}
                      </TableCell>
                      <TableCell align="right">
                        <Grid container justifyContent="flex-end" spacing={1}>
                          <Grid item>
                            <IconButton
                              aria-label="edit"
                              onClick={() =>
                                history.push(
                                  `/expenses/${expense.date}/edit?location=${expense.location?.id}`
                                )
                              }
                              disabled={expense.canceled}
                            >
                              <EditIcon />
                            </IconButton>
                          </Grid>
                          <Grid item>
                            <IconButton
                              aria-label="cancel"
                              onClick={() => modalCanceledExpense(expense)}
                              disabled={expense.canceled}
                              color="secondary"
                            >
                              <BlockIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  )
                )}
              {!expensesItems.loading &&
                expensesItems.data &&
                expensesItems.data.getExpenses.expenses.length === 0 && (
                  <Typography variant="body1" align="center" paragraph>
                    No hay elementos
                  </Typography>
                )}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      <Dialog
        open={modal}
        onClose={() => setModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Cancelar gasto</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Realmente deseas cancelar este gasto?
          </DialogContentText>
          <TextField
            multiline
            minRows="3"
            label="Razón de la cancelación"
            placeholder="Describe la razón de la cancelación"
            onChange={(event) => setCancelReason(event.currentTarget.value)}
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModal(false)}>Cancelar</Button>
          <Button
            onClick={() =>
              canceledExpense(
                selectedExpense?.location?.id as string,
                selectedExpense?.date as number
              )
            }
            color="secondary"
            autoFocus
            disabled={!cancelReason}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
