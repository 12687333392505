import React, { FC, useMemo } from "react";

import { Grid, Paper, Typography, TextField, Button } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { useForm } from "react-yup";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";

import { roleSchema } from "../../forms/role.schema";
import { CREATE_ROLE } from "../../client/roles/mutation";
import { Role } from "../../models/role.model";
import { GET_ROLES } from "../../client/roles/queries";
import { can } from "../../utils/authorized.util";

export const RoleCreateComponent: FC = (): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { field, createSubmitHandler } = useForm({
    validationSchema: roleSchema,
  });

  const [createRole] = useMutation(CREATE_ROLE);

  const handleCreate = (role: Role): void => {
    createRole({
      variables: role,
      optimisticResponse: true,
      update: (cache, { data }) => {
        const existing: any = cache.readQuery({
          query: GET_ROLES,
          variables: {
            filters: "",
          },
        });
        if (data.createRole && existing && existing.getRoles) {
          const createItem: Role = data.createRole.role;
          cache.writeQuery({
            query: GET_ROLES,
            variables: {
              filters: "",
            },
            data: {
              getRoles: {
                roles: [...existing.getRoles.roles, createItem],
              },
            },
          });
        }
      },
    });
  };

  const handleSubmit = useMemo(() => {
    return createSubmitHandler(
      (values) => {
        const { name, description } = values;
        handleCreate({ name, description } as Role);
        enqueueSnackbar(`${name} ha sido creado`, {
          variant: "success",
          resumeHideDuration: 3000,
          anchorOrigin: {
            horizontal: "right",
            vertical: "bottom",
          },
        });
        history.push("/roles");
      },
      () => {
        enqueueSnackbar("Algunos campos contienen errores", {
          variant: "error",
          resumeHideDuration: 6000,
          anchorOrigin: {
            horizontal: "right",
            vertical: "bottom",
          },
        });
      }
    );
  }, []);

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={10} lg={8}>
        <Paper elevation={2}>
          <form onSubmit={handleSubmit}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item>
                <Typography variant="h3">Crear Rol</Typography>
                <Typography variant="caption" display="block">
                  Introduce los datos del rol
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <TextField
                  type="text"
                  id="name"
                  name="name"
                  label="Nombre"
                  variant="outlined"
                  helperText="Obligatorio"
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={10}>
                <TextField
                  type="text"
                  id="description"
                  name="description"
                  label="Descripción"
                  variant="outlined"
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  fullWidth
                />
              </Grid>
              <Grid item xs={10}>
                <Grid container justifyContent="flex-end" spacing={2}>
                  <Grid item>
                    <Button
                      variant="outlined"
                      type="button"
                      onClick={() => history.goBack()}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      startIcon={<SaveIcon />}
                      disabled={!can("ROLES::CREATE", "ROLES::*")}
                    >
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};
