import { gql } from "@apollo/client";

export const CREATE_CLIENT = gql`
  mutation createClient(
    $name: String!
    $rfc: String
    $address: String!
    $city: String!
    $phone: String!
    $commission: Int!
    $commissionType: String
    $alternativeAddress: String
  ) {
    createClient(
      name: $name
      rfc: $rfc
      address: $address
      city: $city
      phone: $phone
      commission: $commission
      commissionType: $commissionType
      alternativeAddress: $alternativeAddress
    ) {
      status
      client {
        id
        name
        rfc
        address
        city
        phone
        registerDate
        commission
        commissionType
        alternativeAddress
      }
      error {
        message
        code
      }
    }
  }
`;

export const UPDATE_CLIENT = gql`
  mutation updateClient(
    $id: String!
    $name: String!
    $rfc: String
    $address: String!
    $city: String!
    $phone: String!
    $commission: Int
    $commissionType: String
    $alternativeAddress: String
  ) {
    updateClient(
      id: $id
      name: $name
      rfc: $rfc
      address: $address
      city: $city
      phone: $phone
      commission: $commission
      commissionType: $commissionType
      alternativeAddress: $alternativeAddress
    ) {
      status
      client {
        id
        name
        rfc
        address
        city
        phone
        registerDate
        commission
        commissionType
        alternativeAddress
      }
      error {
        message
        code
      }
    }
  }
`;

export const DELETE_CLIENT = gql`
  mutation deleteClient($id: String!) {
    deleteClient(id: $id) {
      status
      message
      error {
        code
        message
      }
    }
  }
`;
