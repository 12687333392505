export const env = {
  AWS: {
    cognito: {
      aws_user_pools_web_client_id: "66jkbrknjkagbihr7oli7t1vbk",
      aws_project_region: "us-east-1",
      aws_cognito_region: "us-east-1",
      aws_user_pools_id: "us-east-1_1wIfegvRS",
      aws_cognito_identity_pool_id:
        "us-east-1:7486a02d-2ef6-4d0b-97e7-fad4a1a8301a",
    },
  },
  ENDPOINT: "https://api.quesadillaselguero.com/DEV/graphql",
  DELIVERY_COST: 25,
};
