import { gql } from "@apollo/client";

export const GET_POLICIES = gql`
  query getPolicies($filters: String!) {
    getPolicies(filters: $filters) {
      status
      policies {
        id
        name
        effect
        resources
        actions
      }
      error {
        message
      }
    }
  }
`;

export const GET_POLICY = gql`
  query getPolicy($id: String!) {
    getPolicy(id: $id) {
      status
      policy {
        id
        name
        effect
        resources
        actions
      }
      error {
        message
      }
    }
  }
`;

export const GET_SUMMARIZED_POLICIES = gql`
  query getSummarizedPolicies($user: String!) {
    getSummarizedPolicies(user: $user) {
      status
      summarize
      error {
        message
      }
    }
  }
`;

export const GET_ROLES_BY_POLICY = gql`
  query getRoles($filters: String!) {
    getRoles(filters: $filters) {
      status
      roles {
        id
        name
        description
      }
      error {
        message
      }
    }
  }
`;
