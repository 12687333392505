import { makeStyles } from "@material-ui/core";
import {
  red,
  pink,
  purple,
  blue,
  green,
  lime,
  yellow,
  amber,
  orange,
} from "@material-ui/core/colors";

export const useStyles = makeStyles({
  redColor: {
    color: red[500],
  },
  pinkColor: {
    color: pink[500],
  },
  purpleColor: {
    color: purple[500],
  },
  blueColor: {
    color: blue[500],
  },
  greenColor: {
    color: green[500],
  },
  limeColor: {
    color: lime[500],
  },
  yellowColor: {
    color: yellow[500],
  },
  amberColor: {
    color: amber[500],
  },
  orangeColor: {
    color: orange[500],
  },
});
