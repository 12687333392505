import React, { FC } from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router";
import { GET_CATEGORY } from "../client/categories/queries";
import { BackdropComponent } from "../components/commons/backdrop/brackdrop.component";
import { CategoriesEditContent } from "../content/categories-edit.content";
import { Category } from "../models/category.model";
import { IRouteId } from "../models/categories-id.model";

export const CategoriesEditRoute: FC = (): JSX.Element => {
  const params = useParams();
  const { id } = params as IRouteId;

  const category = useQuery(GET_CATEGORY, {
    variables: {
      id,
    },
  });

  return category.loading ? (
    <BackdropComponent />
  ) : (
    <CategoriesEditContent
      category={
        category.data && (category.data.getCategory?.category as Category)
      }
    />
  );
};
