import React, { FC, useMemo, useState } from "react";

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { useHistory } from "react-router-dom";
import { v4 as uuid4 } from "uuid";
import { getUnixTime } from "date-fns";
import { useForm } from "react-yup";
import { useSnackbar } from "notistack";

import { Client } from "../../models/client.model";
import { clientSchema } from "../../forms/client.form";
import { IClientCreate } from "../../models/components/client-create.model";
import { can } from "../../utils/authorized.util";

export const ClientCreateComponent: FC<IClientCreate> = ({
  handleCreate,
}): JSX.Element => {
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const [havecommission, setHavecommission] = useState<boolean>(false);

  const { touched, errors, field, createSubmitHandler } = useForm({
    validationSchema: clientSchema,
  });

  const handleSubmit = useMemo(() => {
    return createSubmitHandler(
      (values) => {
        const id = uuid4();
        const registerDate = getUnixTime(new Date());
        const {
          name,
          rfc,
          address,
          city,
          phone,
          commission,
          commissionType,
          alternativeAddress,
        } = values;
        handleCreate({
          id,
          name,
          rfc,
          address,
          city,
          phone,
          registerDate,
          commission: commission && +commission,
          commissionType,
          alternativeAddress,
        } as Client);
        enqueueSnackbar(`${name} ha sido añadido`, {
          variant: "success",
          resumeHideDuration: 3000,
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
        });
        history.push("/clients");
      },
      () => {
        enqueueSnackbar("Algunos campos contienen errores", {
          variant: "error",
          resumeHideDuration: 6000,
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
        });
      }
    );
  }, [createSubmitHandler, enqueueSnackbar, history, handleCreate]);

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} md={10} lg={8}>
        <Paper elevation={2}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Typography variant="h4">Añadir Cliente</Typography>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  name="name"
                  label="Nombre"
                  variant="outlined"
                  required
                  {...field}
                  helperText={
                    (errors.name && touched.name && "Obligatorio") || ""
                  }
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  type="tel"
                  name="phone"
                  label="Teléfono"
                  variant="outlined"
                  required
                  {...field}
                  helperText={
                    (errors.phone &&
                      touched.phone &&
                      "Obligatorio, debe contener de 7 a 10 dígitos") ||
                    ""
                  }
                  inputProps={{ maxLength: 10 }}
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  name="rfc"
                  label="RFC"
                  variant="outlined"
                  {...field}
                  helperText={
                    (errors.rfc &&
                      touched.rfc &&
                      "RFC debe contener al menos 12 letras") ||
                    ""
                  }
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  name="address"
                  label="Dirección"
                  variant="outlined"
                  required
                  {...field}
                  helperText={
                    (errors.address && touched.address && "Obligatorio") || ""
                  }
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  name="alternativeAddress"
                  label="Dirección alternativa"
                  variant="outlined"
                  {...field}
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  name="city"
                  label="Ciudad"
                  variant="outlined"
                  required
                  {...field}
                  helperText={
                    (errors.city && touched.city && "Obligatorio") || ""
                  }
                />
              </Grid>
              <Grid item xs={10}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item xs={12} md={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={havecommission}
                          onChange={() => setHavecommission(!havecommission)}
                          name="iscommission"
                          color="primary"
                        />
                      }
                      label="Aplicar comisión"
                    />
                  </Grid>
                  {havecommission && (
                    <Grid item xs={12} md={6}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="demo-simple-select-outlined-label">
                          Tipo de comisión
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          name="commissionType"
                          label="Tipo de comisión"
                          onChange={field.onChange as any}
                        >
                          <MenuItem value="percentage">Porcentaje (%)</MenuItem>
                          <MenuItem value="money">Dinero ($)</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  {havecommission && (
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        name="commission"
                        label="Comisión"
                        variant="outlined"
                        defaultValue={0}
                        {...field}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={10}>
                <Grid container justifyContent="flex-end" spacing={2}>
                  <Grid item>
                    <Button
                      variant="contained"
                      size="small"
                      startIcon={<ArrowBackIcon />}
                      type="button"
                      onClick={() => history.push("/clients")}
                    >
                      Regresar
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      startIcon={<CheckCircleIcon />}
                      type="submit"
                      disabled={!can("CLIENTS::CREATE", "CLIENTS::*")}
                    >
                      Añadir cliente
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};
