import React, { FC, useContext, useEffect, useState } from "react";

import { Grid, IconButton } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import { useLazyQuery } from "@apollo/client";
import { fromUnixTime, getUnixTime, endOfDay, startOfDay } from "date-fns";

import { GET_SALE, GET_TICKET } from "../client/sales/queries";
import { TicketComponent } from "../components/commons/ticket/ticket.component";
import { BackdropComponent } from "../components/commons/backdrop/brackdrop.component";
import { SessionContext } from "../context/session.context";
import { GET_EXPENSES } from "../client/expenses/queries";

export const TicketContent: FC<{
  type: string;
  id: string;
  date?: number;
  change?: number;
  cash?: number;
}> = ({ type, id, date, change, cash }): JSX.Element => {
  const session = useContext(SessionContext);
  const [getSale, saleItem] = useLazyQuery(GET_SALE);
  const [getCutOff, cutOffItem] = useLazyQuery(GET_TICKET);
  const [getExpenses, expensesItems] = useLazyQuery(GET_EXPENSES);
  const [printing, setPrinting] = useState<boolean>(false);

  useEffect(() => {
    const navbar = document.getElementById("navbar") as HTMLDivElement;
    navbar.parentElement?.removeChild(navbar);
    if (type === "sale") {
      getSale({
        variables: {
          id,
        },
      });
    } else {
      getCutOff({
        variables: {
          filters: JSON.stringify({
            location: id,
            date: date && (+date as number),
          }),
        },
      });
      getExpenses({
        variables: {
          filters: JSON.stringify({
            location: id,
            from: getUnixTime(
              startOfDay(fromUnixTime((date && (+date as number)) || 0))
            ),
            to: getUnixTime(
              endOfDay(fromUnixTime((date && (+date as number)) || 0))
            ),
          }),
        },
      });
    }
  }, [type]);

  window.onafterprint = () => {
    setPrinting(false);
  };

  return saleItem.loading || cutOffItem.loading || expensesItems.loading ? (
    <BackdropComponent />
  ) : type === "sale" ? (
    <TicketComponent
      date={
        (saleItem.data && fromUnixTime(saleItem.data.getSale.sale.date)) ||
        new Date()
      }
      table={saleItem.data && saleItem.data.getSale.sale.table}
      startDate={
        (saleItem.data && fromUnixTime(saleItem.data.getSale.sale.date)) ||
        new Date()
      }
      endDate={new Date()}
      cashier={session.user.name}
      order={(saleItem.data && saleItem.data.getSale.sale.orders) || []}
      total={(saleItem.data && saleItem.data.getSale.sale.total) || 0}
      payment={cash}
      cambio={change || 0}
      paymentMethod={
        (saleItem.data && saleItem.data.getSale.sale.paymentMethod) || ""
      }
      saleId={saleItem.data && saleItem.data.getSale.sale.id}
      deliveryTime={
        (saleItem.data && saleItem.data.getSale.sale.deliveryTime) || ""
      }
    >
      {!printing && (
        <Grid container>
          <Grid item>
            <IconButton
              aria-label="print"
              color="primary"
              onClick={() => {
                setPrinting(true);
                setTimeout(() => {
                  window.print();
                }, 500);
              }}
            >
              <PrintIcon />
            </IconButton>
          </Grid>
        </Grid>
      )}
    </TicketComponent>
  ) : (
    <TicketComponent
      id="ticket"
      date={
        (cutOffItem.data &&
          fromUnixTime(cutOffItem.data.getTicket.ticket.date)) ||
        new Date()
      }
      startDate={
        (cutOffItem.data &&
          fromUnixTime(cutOffItem.data.getTicket.ticket.from)) ||
        new Date()
      }
      endDate={
        (cutOffItem.data &&
          fromUnixTime(cutOffItem.data.getTicket.ticket.to)) ||
        new Date()
      }
      order={(cutOffItem.data && cutOffItem.data.getTicket.ticket.orders) || []}
      total={(cutOffItem.data && cutOffItem.data.getTicket.ticket.total) || 0}
      cashier={cutOffItem.data && cutOffItem.data.getTicket.ticket.user.name}
      type="corte"
    >
      {!printing && (
        <Grid container>
          <Grid item>
            <IconButton
              aria-label="print"
              color="primary"
              onClick={() => {
                setPrinting(true);
                setTimeout(() => {
                  window.print();
                }, 500);
              }}
            >
              <PrintIcon />
            </IconButton>
          </Grid>
        </Grid>
      )}
    </TicketComponent>
  );
};
